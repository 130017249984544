
export const Reports = {
    of(ledgerId: string) {
        return `/ledgers/${ledgerId}/reports`;
    },
    one(ledgerId: string, reportId: string) {
        return `/ledgers/${ledgerId}/reports/${reportId}`
    },

    fake(m: number) {
        const values = [];

        // Initialize with uniform random values in [0.1, 0.2).
        for (let i = 0; i < m; ++i) {
            values[i] = 0.1 + 0.1 * Math.random();
        }

        // Add five random bumps.
        for (let j = 0; j < 5; ++j) {
            const x = 1 / (0.1 + Math.random());
            const y = 2 * Math.random() - 0.5;
            const z = 5 / (0.1 + Math.random());
            for (let i = 0; i < m; i++) {
                const w = (i / m - y) * z;
                values[i] += x * Math.exp(-w * w);
            }
        }

        // Ensure all values are positive.
        for (let i = 0; i < m; ++i) {
            values[i] = Math.max(0, values[i]);
        }

        return values;
    }
}