import React, { useCallback, useEffect, useState } from "react";
import { AccountNode, accountsStore } from "service/Accounts.store";
import { AppHistory } from "service/Page.store";
import './AccountName.co.css';
import AccountNav from "./AccountNav.co";

export const AccountNameBrowser = React.memo((props: any) => {
  const { name, accounts, ledger } = props;

  const [parts, setParts] = useState<{ name: string, fullName: string }[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [menuAccounts, setMenuAccounts] = useState<AccountNode[]>([]);
  const [linkList] = useState<any[]>([]);
  const [lastMenuAccounts, setLastMenuAccounts] = useState<AccountNode[]>([]);

  const [hasSubAccounts, setHasSubAccounts] = useState(false as boolean);

  useEffect(() => {
    if (!name || !ledger || !accounts) {
      return;
    }

    const parts = (name || '').split('/').slice(1).reduce(
      (acc: { name: string, fullName: string }[], value: string) => {

        let previous = acc.length === 0 ? null : acc[acc.length - 1];

        let current = {
          name: value,
          fullName: (previous?.fullName || '') + "/" + value
        }
        acc.push(current);
        return acc
      }, []);

    setParts(parts);

    setLastMenuAccounts(accounts?.indexByName?.[name]?.children || [])

    setHasSubAccounts(accounts.indexByName[name]?.children?.length > 0)
  }, [name, ledger, accounts])

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>, part: { name: string, fullName: string }) => {

    setAnchorEl(event.currentTarget);

    if (part.fullName === '::last') {
      setMenuAccounts(lastMenuAccounts);
    } else {
      let menuAccounts = accountsStore.accountSiblings(accounts, part.fullName);
      setMenuAccounts(menuAccounts);
    }
  }, [lastMenuAccounts, accounts]);

  const handleClose = useCallback((account?: AccountNode) => {
    setAnchorEl(null);

    if (ledger?.id && account?.id) {
      AppHistory.push(`/ledgers/${ledger.id}/accounts/${account.id}/transactions`);
    }
  }, [ledger]);

  const handleLink = useCallback((link: any) => {
    setAnchorEl(null);
    // console.log("Selected link", link);
    AppHistory.push(link.uri);
  }, [])

  return (

    < span className="AccountName-root" >
      {
        parts.map((part) =>
          <span key={part.fullName} aria-controls="fade-menu" aria-haspopup="true" className="AccountName-part"
            onClick={(event) => handleClick(event, part)}>
            {part.name}
          </span>
        )
      }
      {
        hasSubAccounts &&
        <span aria-controls="fade-menu" aria-haspopup="true"
          onClick={(event) => handleClick(event, { name: '', fullName: '::last' })}>
          &nbsp;&nbsp;&#8680;&nbsp;&nbsp;
        </span>
      }
      {
        anchorEl && <AccountNav
          accountList={menuAccounts}
          linkList={linkList}
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          handleLink={handleLink}
        />
      }
    </span >
  );
});

AccountNameBrowser.whyDidYouRender = true;