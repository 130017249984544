import { Avatar, Button, LinearProgress, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HomeIcon from '@material-ui/icons/Home';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppHistory, eventMap, useNavbar, useNavbarCallback } from 'service/Page.store';
import { PaymentsService, StripePromise } from 'service/payments/Payments';
import { Service } from 'service/Service.store';

type Nav = { home: null; }

type PayState = 'not-started' | 'progress' | 'success' | 'error';

const map = {
    success: <CheckCircleOutlineIcon />,
    failure: <ErrorOutlineIcon />,
    new: <QueryBuilderIcon />
};


export default function Payments() {
    const { t } = useTranslation();

    const [stripeAvailable, setStripeAvailable] = useState(undefined as boolean | undefined);
    const [payStatus, setPayStatus] = useState({ state: 'not-started' } as { state: PayState, value?: any });

    const { data: sessions } = Service.useSessions();

    const [, setNavbar] = useNavbar<Nav>();
    useEffect(() => {
        setNavbar([
            { key: 'home', icon: <HomeIcon /> },
        ]);
    }, [setNavbar]);


    useEffect(() => {
        StripePromise
            .then(() => setStripeAvailable(true))
            .catch(() => setStripeAvailable(false))
    }, []);

    useNavbarCallback(useMemo(() => eventMap<Nav>({
        home: () => AppHistory.push(`/`)
    }), []))


    const buy = useCallback(() => {
        setPayStatus({ state: 'progress' })
        PaymentsService.pay()
            .then((res) => {
                if (!res.id) {
                    throw new Error('No session id')
                }
                PaymentsService.redirectToCheckout(res).then(res => {
                    setPayStatus({ state: 'success', value: res })
                })
            })
            .catch(err => {
                setPayStatus({ state: 'error', value: err })
            })
    }, []);

    if (stripeAvailable === undefined) {
        return (
            <LinearProgress />
        )
    }

    if (stripeAvailable === false) {
        return (
            <div className='content_margin'>{t('payments_processor_not_available')}</div>
        )
    }

    // console.log('sessions', sessions);
    return (
        <div className='content_margin'>
            <h1>Payments</h1>
            <div>
                {payStatus.state === 'progress' && <LinearProgress />}
                {payStatus.state === 'success' && <Alert severity="success">{JSON.stringify(payStatus.value)}</Alert>}
                {payStatus.state === 'error' && <Alert severity="error">{JSON.stringify(payStatus.value)}</Alert>}
                <Button
                    onClick={buy}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={payStatus.state !== 'not-started'}
                >Buy</Button>

                <hr />

                <List>
                    {sessions?.data?.map((session: any) =>
                        <ListItem key={session.id} button dense={true}>
                            <ListItemAvatar><Avatar>{(map as any)[session.state] ?? <></>}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={session?.data?.product?.description}
                                secondary={
                                    <>
                                        <span>{moment(session?.ts).format('YYYY-MM-DD HH:mm')}</span>
                                        <span>{session?.data?.product?.metadata?.span}</span>
                                        <span>{t('session_state_' + session?.state)}</span>
                                    </>} />
                        </ListItem>)
                    }
                </List>
            </div>
        </div >
    );
}
