import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'service/Page.store';
import { PaymentsService } from 'service/payments/Payments';

export type PaymentProps = { id: string };

export default function Payment(props: PaymentProps) {
    //const { t } = useTranslation();
    const { state } = useSearchParams();

    const { id } = props;

    const [session, setSession] = useState(undefined as any);
    useEffect(() => {
        id && PaymentsService.get(id).then(setSession);
    }, [id]);

    return (
        <div className='content_margin'>
            <h1>Payment</h1>
            <h2>{id}</h2>
            <div>{state}</div>
            <div>{JSON.stringify(session)}</div>
        </div>
    );
}
