import { FormControl, FormControlLabel, FormLabel, makeStyles, Paper, Radio, RadioGroup } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ActionButton from 'component/ActionButton.co';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { ledgerStore } from 'service/Ledger.store';
import { AppHistory, eventMap, useNavbar, useNavbarCallback } from 'service/Page.store';
import { Service } from 'service/Service.store';

const useStyles = makeStyles((theme) => ({
  selector: {
    padding: theme.spacing(1),
  }
}));

type Nav = { ledgers: null; }

export default function LedgerNew() {
  const { data: commodities } = Service.useCommodities();
  const classes = useStyles();

  const [form, setForm] = useState({
    name: 'My ledger ' + moment().format('YYYY-MM-DD'),
    description: '',
    defaultCurrency: 'USD',
    mode: 'basic-accounts'
  });
  const [formDisabled, setFormDisabled] = useState(false);

  const [notification, setNotification] = useState<string | null>(null);

  const [, setNavbar] = useNavbar<Nav>();
  useEffect(() => {
    setNavbar([
      { key: 'ledgers', text: 'Ledgers', icon: <MenuBookIcon /> }
    ]);
  }, [setNavbar]);
  useNavbarCallback(useMemo(() => eventMap<Nav>({
    ledgers: () => AppHistory.push(`/ledgers/`)
  }), []));

  function handleChange(fieldName: string) {
    return (event: any) => {
      setForm({ ...form, [fieldName]: event?.target.value });
    }
  }

  function Button_onSubmit() {
    //console.log('On submit', form)
    setFormDisabled(true);
    setNotification('In progress');

    ledgerStore.create(form).then(
      ret => { AppHistory.push(`/ledgers/${ret.data.id}/accounts`); },
      err => { /*console.log('err=', err);*/ setNotification('Ledger could not be created'); }
    )
  }

  return (
    <div>
      <form className="myform" noValidate autoComplete="off">
        <fieldset disabled={formDisabled}>
          <div>
            <TextField className="dsc" label="New ledger name" value={form.name} onChange={handleChange('name')} />
          </div>
          <div>
            <TextField className="dsc" label="Ledger description" value={form.description} onChange={handleChange('description')} />
          </div>
          <Paper className={classes.selector}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Default accounts</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" value={form.mode} onChange={handleChange('mode')}>
                <FormControlLabel value="basic-accounts" control={<Radio />} label="Salary, Cash, Groceries & more..." />
                <FormControlLabel value="no-accounts" control={<Radio />} label="No default accounts" />
              </RadioGroup>
            </FormControl>
          </Paper>
          <div>
            <Autocomplete
              value={form.defaultCurrency}
              options={Object.keys(commodities?.data || {})}
              getOptionLabel={(option: any) => { /*console.log('ac #1', option);*/ return option; }}
              className="acc"
              autoSelect
              onChange={(e: any, v: any) => { /*console.log('oc #1', e, v);*/ setForm({ ...form, 'defaultCurrency': v }) }}
              onInputChange={(e: any, v: any, r) => { /*console.log('oic #1', e, v, r);*/ }}
              renderInput={params =>
                <TextField {...params} className="acc" label="Default currency" />}
            />
          </div>

          <div>
            <ActionButton disabled={formDisabled} onClick={Button_onSubmit}>Submit</ActionButton>
            <span className="notification">{notification}</span>
          </div>
        </fieldset>
      </form>
    </div>
  );
}
