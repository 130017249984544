import { AccountAll } from "./Accounts.store";
import { Commodities, ICommodity } from "./Data.store";

const Money = {
    unitsToNumberOfDecimals(units: number) {
        let numberOfDecimals = (units - 1).toString().length;
        if (units === 0) {
            numberOfDecimals = 0;
        }
        return numberOfDecimals;
    },

    stepForNumberOfDecimals(numberOfDecimals: number) {
        let stepAsString = '1';
        if (numberOfDecimals > 0) {
            stepAsString = '.' + '0'.repeat(numberOfDecimals - 1) + '1'
        }
        return stepAsString;
    },

    stringWithMaxNumberOfDecimals(input: string, numberOfDecimals: number) {
        let numberOfDecimalsAfterComma = input.split('.')?.[1]?.length || 0;

        return parseFloat(input).toFixed(Math.min(numberOfDecimals, numberOfDecimalsAfterComma));
    },

    unitsToMainFloat(input: number, commodity: string, commodities: Commodities) {
        let spec = ICommodity.byId(commodities, commodity);
        if (!spec) {
            return NaN;
        }
        let numberOfDecimals = Money.unitsToNumberOfDecimals(spec.units);

        return input / Math.pow(10, numberOfDecimals)
    },

    mainToUnitsFloat(input: number, commodity: string, commodities: Commodities) {
        let spec = ICommodity.byId(commodities, commodity);
        if (!spec) {
            return NaN;
        }
        let numberOfDecimals = Money.unitsToNumberOfDecimals(spec.units);

        return input * Math.pow(10, numberOfDecimals)
    },

    accountValueToStorableInteger(commodities: Commodities, accounts: AccountAll, accountName: string, value: string) {
        let units = ICommodity.byAccountName(commodities, accounts, accountName)?.units
        if (units === undefined) {
            return undefined;
        }
        return Money.stringToStorableInteger(value.toString(), Money.unitsToNumberOfDecimals(units));
    },

    stringToStorableInteger(input: string, numberOfDecimals: number) {
        input = input.toString();

        //console.log('stringToStorableInteger', input, numberOfDecimals);
        let numberOfDecimalsAfterComma = input.split('.')?.[1]?.length || 0;

        let ret = input;
        if (input.indexOf('.') === -1) {
            ret += '';
        }
        ret += '0'.repeat(numberOfDecimals - numberOfDecimalsAfterComma);

        let ret2 = parseInt(ret.split('.').join(''));

        //console.log('stringToStorableInteger ret', ret, ret2)
        return ret2;
    },

    storableIntegerOfAccountToString(commodities: Commodities, accounts: AccountAll, accountName: string, value: number) {
        let units = ICommodity.byAccountName(commodities, accounts, accountName)?.units
        //console.log('storableIntegerOfAccountToString', value, account, units);
        if (units === undefined) {
            return undefined;
        }
        let ss = this.storableIntegerToString(value, Money.unitsToNumberOfDecimals(units))
        return `${ss.integer}.${ss.decimals}`
    },

    storableIntegerToString(value: number, numberOfDecimals: number) {
        let valueAsString = value.toString();

        if (valueAsString.length <= numberOfDecimals + 1) {
            valueAsString = '0'.repeat(numberOfDecimals + 1 - valueAsString.length) + valueAsString;
        }

        let ret = {
            integer: valueAsString.substring(0, valueAsString.length - numberOfDecimals) || '0',
            decimals: valueAsString.substring(valueAsString.length - numberOfDecimals)
        }

        return ret;
    },

    toDisplayA(value: any, units: number, style?: 'normal' | 'reversed') {
        let displayValue = (style === 'normal') ? value : -value;
        let numberOfDecimals = Money.unitsToNumberOfDecimals(units);
        let displayString = Money.storableIntegerToString(displayValue, numberOfDecimals);

        return displayString;
    },

    toDisplay(value: any, commodity: any, style?: 'normal' | 'reversed') {
        let displayString = this.toDisplayA(value, commodity.units, style);
        let displayClass = '';
        switch (style) {
            case 'normal':
                displayClass = `value mv-${style}-${value >= 0 ? 'favorable' : 'unfavorable'}`
                break;
            case 'reversed':
                displayClass = `value mv-${style}-${value <= 0 ? 'favorable' : 'unfavorable'}`
                break;
        }

        return {
            displayString: displayString,
            displayClass: displayClass
        }
    }
}

export { Money };

