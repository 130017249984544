import { DependencyList, useCallback, useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
class FilteredBehaviorSubject<T> extends BehaviorSubject<T> {
  constructor(
    private opts: {
      filterOut: (current: T, updated: T) => boolean,
      name: string
    },
    _value: T
  ) {
    super(_value);
  }

  next(newValue: T): void {
    if (this.opts.filterOut(this.value, newValue)) {
    } else {
      super.next(newValue);
    }
  }
}

function useBehaviorSubject<T>(behaviorSubject: BehaviorSubject<T>): [T, (t: T) => void] {
  const [value, setValue] = useState<T>(behaviorSubject.value);

  useEffect(() => {
    const subscription = behaviorSubject.subscribe((data: T) => {
      //console.log('useBehaviorSubject.setValue', data);
      setValue(data);
    });

    return () => { subscription.unsubscribe() }
  }, [behaviorSubject]);

  let setter = useCallback((newValue: T) => {
    //console.log('behaviorSubject', newValue);
    if (newValue !== behaviorSubject.value) behaviorSubject.next(newValue)
  }, [behaviorSubject]);

  return [value, setter];
}

export function useBehaviorSubjectAs<T>(behaviorSubject: BehaviorSubject<T>, updater?: () => T, deps?: DependencyList) {
  const [v, s] = useBehaviorSubject(behaviorSubject);

  useEffect(() => {
    updater && s(updater());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, updater, s])

  return [v, s];
}

export { FilteredBehaviorSubject, useBehaviorSubject };

