import LinearProgress from '@material-ui/core/LinearProgress';
import About from 'page/About.page';
import Account from 'page/Account.page';
import AccountEdit from 'page/AccountEdit.page';
import Accounts from 'page/Accounts.page';
import LedgerEdit from 'page/LedgerEdit.page';
import LedgerNew from 'page/LedgerNew.page';
import Ledgers from 'page/Ledgers.page';
import LedgerUsers from 'page/LedgerUsers.page';
import Payment from 'page/pay/Payment.page';
import Payments from 'page/pay/Payments.page';
import ReportsEdit from 'page/reports/ReportsEdit.page';
import ReportsList from 'page/reports/ReportsList.page';
import TxEdit from 'page/tx/TxEdit.page';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Globals } from 'service/Globals.store';
import { Service } from 'service/Service.store';

export default function Content(props: any) {
  const { data: auth } = Service.useAuth();

  return (
    <>
      {!auth && <main className='content'><LinearProgress /></main>}
      {auth?.data && <main className='content'>
        <Switch>
          <Route path="/about"><About {...props} /></Route>
          <Route exact path="/payments"><Payments {...props} /></Route>
          <Route exact path="/payments/:id" render={props => {
            return <Payment id={props.match.params.id} />;
          }} />
          <Route exact path="/ledgers"><Ledgers {...props} /></Route>
          <Route exact path="/ledgers/new"><LedgerNew {...props} /></Route>
          <Route path="/ledgers/:ledgerId" render={props => <LedgerRoutes ledgerId={props.match.params.ledgerId} />} />
          <Route render={props => <Redirect to='/ledgers' />}></Route>
        </Switch>
      </main>
      }
      {auth && !auth?.data && <main className='content'>
        <Switch>
          <Route path="/about"><About {...props} /></Route>
          <Route render={props => <Redirect to='/about' />}></Route>
        </Switch>
      </main>
      }
    </>
  );
}

function LedgerRoutes(props: any) {
  let { url } = useRouteMatch();

  const { data: ledger } = Service.useLedger(props.ledgerId);
  const { data: accounts } = Service.useAccounts(props.ledgerId);
  const { data: commodities } = Service.useCommodities();

  useEffect(() => { Globals.updateLedger(ledger); return () => Globals.updateLedger(undefined) }, [ledger]);
  useEffect(() => { Globals.updateAccounts(accounts); return () => Globals.updateAccounts(undefined) }, [accounts]);
  useEffect(() => { Globals.updateCommodities(commodities); return () => Globals.updateCommodities(undefined) }, [commodities]);

  const passProps = { ledgerId: props.ledgerId, ledger, accounts, commodities };

  return (
    <>
      {ledger && accounts && commodities && <>
        <Route exact path={`${url}/edit`}><LedgerEdit {...props} /></Route>
        <Route exact path={`${url}/reports`}><ReportsList {...props} /></Route>

        <Route exact path={`${url}/reports/:reportId`}
          render={props => <ReportsEdit {...passProps}
            reportId={props.match.params.reportId === 'new' ? undefined : props.match.params.reportId} />} />

        <Route exact path={`${url}/accounts`} render={props => <Accounts {...passProps} />} />

        <Route exact path={`${url}/accounts/edit/:accountId`} render={props =>
          <AccountEdit {...passProps}
            accountId={props.match.params.accountId === 'new' ? undefined : props.match.params.accountId} />}>
        </Route>

        <Route exact path={`${url}/accounts/:accountId/transactions`}
          render={props => <Account {...passProps} accountId={props.match.params.accountId} />} />

        <Route exact path={`${url}/transactions/edit/:transactionId`} render={props =>
          <TxEdit {...passProps}
            transactionId={props.match.params.transactionId === 'new' ? undefined : props.match.params.transactionId} />}>
        </Route>

        <Route exact path={`${url}/users`}><LedgerUsers {...props} /></Route>
      </>
      }
      {(!accounts || !commodities) && <span>Loading accounts and commodities...</span>}
    </>
  )
}