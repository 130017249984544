import moment from "moment";

export function txDescription(tx: any) {
    return tx.splits?.[0].description || tx.description || (() => {
        return tx.splits.find((split: any) => split.description)?.description
    })()
}

export function txDate(tx: any) {
    return moment(tx.splits?.[0].ts).format("YYYY-MM-DD");
}

export function txTarget(accounts: any, tx: any) {
    return accounts.indexById[tx.splits[1].account_id].name
}
