import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Alert } from '@material-ui/lab';
import produce from 'immer';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ledgerStore } from 'service/Ledger.store';
import { AppHistory, eventMap, SearchParams, useNavbar, useNavbarCallback, useSearchParams } from 'service/Page.store';
import { Service } from 'service/Service.store';
import { CrudButtonKind, CrudUtil, navPatcher } from 'service/ux/CrudButtons';
import { TxEditTransformer, TxForm, txFormReducer, TxFormSplit, TxUtil } from './TxEdit.form';
import "./TxEdit.page.css";
import { TxEditForm } from './TxEditForm.co';
import { TxEditFormSimplified } from './TxEditFormSimplified.co';

const DEBUG_DELAY = 0;

//type Nav = CrudButtonKind & { accounts: null };

type FormMode = "undetermined" | "simplified" | "detailed";

type Nav = CrudButtonKind & { accounts: null; }
const NavbarUtil = {
    forForm: (txForm: TxForm) => {
        return navPatcher<Nav>({
            accounts: { mode: txForm.dirty ? 'disabled' : 'enabled' }
        });
    }
}

type FormAction = {
    type: string,
    success?: boolean
};

export default function TxEdit(props: { ledgerId: string, transactionId: string }) {
    let { ledgerId, transactionId } = props;
    let query = useSearchParams() as { mode?: string, account?: string };

    const { t } = useTranslation();

    const { accounts, commodities } = Service.useLedgerContext(props);

    const { data: currentTransaction, mutate: currentTransactionMutate } = Service.useTransaction(ledgerId, transactionId);

    const [formMode, setFormMode] = useState("undetermined" as FormMode);
    const [formModeDesired, setFormModeDesired] = useState(((query.mode === 'detailed') ? 'detailed' : 'simplified') as FormMode);

    const [accountNames, setAccountNames] = useState([] as string[]);

    // ogh: Typescript bug - had to add return type explicitely
    const [txForm, txFormDispatch] = useReducer(txFormReducer, undefined as TxForm | undefined);

    const [formDisabled, setFormDisabled] = useState(false as boolean);
    const [awaitEdit, setAwaitEdit] = useState(false);
    const [lastFormAction, setLastFormAction] = useState({ type: 'init' } as FormAction);

    const [moneyFlowStartIndex, setMoneyFlowStartIndex] = useState(undefined as number | undefined);

    const [lastFirstAccount, setLastFirstAccount] = useState(accounts.indexById?.[query?.account ?? '']?.name as string | null | undefined);

    //console.log('query', query, accounts, lastFirstAccount, 'acc', query?.account, accounts.indexById?.[query?.account ?? '']);

    const [, setNavbar] = useNavbar<Nav>();
    useEffect(() => {
        setNavbar([
            { key: 'accounts', icon: <AccountTreeIcon />, mode: 'disabled' },
            ...(CrudUtil.New() ?? [])
        ]);
    }, [setNavbar]);

    const onSplitAdd = useCallback(() => {
        txFormDispatch({ splitNew: { accounts, commodities } })
    }, [accounts, commodities]);

    const onSplitDelete = useCallback((index: number) => txFormDispatch({ splitDelete: { index } }), [])
    const onSplitChange = useCallback((index: number, diff: Partial<TxFormSplit>) => {
        txFormDispatch({ split: { accounts, commodities, index, diff } })
    }, [accounts, commodities]);

    const onTxChange = useCallback((diff: Partial<TxForm>) => txFormDispatch({ tx: { diff } }), [])

    const crudSave = useCallback(() => {
        setFormDisabled(true)
        setNavbar(CrudUtil.saveProgress)
        setLastFormAction({ type: 'saving' })

        setTimeout(() =>
            accounts && commodities && txForm &&
            ledgerStore.createOrUpdateTransaction(transactionId, ledgerId, TxEditTransformer.toData(commodities, accounts, txForm))
                .then((res) => {
                    setAwaitEdit(true);
                    setNavbar(CrudUtil.saveSuccessful)
                    setLastFirstAccount(txForm?.splits[0]?.account);
                    setLastFormAction({ type: 'saved', success: true })

                    AppHistory.push({
                        pathname: `/ledgers/${ledgerId}/transactions/edit/${res.data.id}`,
                        search: SearchParams(query)
                    });
                })
                .catch((e) => {
                    console.log('error', e);
                    setAwaitEdit(false);
                    setFormDisabled(false);
                    setNavbar(CrudUtil.saveFailed)
                    setLastFormAction({ type: 'saved', success: false })
                    console.log('Error processing done');
                })
                .finally(() => {
                    setNavbar((old) => {
                        return produce(old, draft => {
                            if (!draft) {
                                return draft
                            }
                            const index = draft?.findIndex(item => item.key === 'accounts')
                            if (index !== -1) draft[index].mode = 'enabled'
                        })
                    })
                }), DEBUG_DELAY);
    }, [setNavbar, txForm, accounts, commodities, ledgerId, transactionId, query])

    const crudDelete = useCallback(() => setOpenDeleteDialog(true), [])
    const crudDeleteActual = useCallback(() => {
        setFormDisabled(true);
        setNavbar(CrudUtil.deleteProgress);
        setLastFormAction({ type: 'deleting' });

        setOpenDeleteDialog(false);
        setTimeout(() =>
            ledgerStore.deleteTransaction(ledgerId, transactionId)
                .then(res => {
                    setLastFormAction({ type: 'deleted', success: true });
                    setNavbar(CrudUtil.deleteSuccessful)
                })
                .catch(err => {
                    setLastFormAction({ type: 'deleted', success: false });
                    setNavbar(CrudUtil.deleteFailed)
                }), DEBUG_DELAY);
    }, [ledgerId, transactionId, setNavbar])

    const crudEdit = useCallback(() => {
        setFormDisabled(false);
        setAwaitEdit(false);
        setNavbar(CrudUtil.editRequested)
    }, [setNavbar]);

    const crudNew = useCallback(() => {
        setFormDisabled(false);
        setAwaitEdit(false);
        setNavbar(CrudUtil.newRequested)
        //console.log('lastFirstAccount', lastFirstAccount)
        txFormDispatch({ txReset: { accounts: accounts, commodities: commodities, lastFirstAccount, date: txForm?.splits?.[0]?.date } })
        //console.log('query', query);
        AppHistory.push({
            pathname: `/ledgers/${ledgerId}/transactions/edit/new`,
            //search: SearchParams(query)
        });
    }, [ledgerId, setNavbar, accounts, commodities, lastFirstAccount, txForm]);

    const crudCancel = useCallback(() => {
        if (!accounts || !commodities) return;

        setNavbar(CrudUtil.cancelProgress);

        setFormDisabled(true);
        setAwaitEdit(s => false);
        if (txForm?.id) {
            txFormDispatch({ txUndefine: true })
            setNavbar(CrudUtil.cancelSuccessful)
            currentTransactionMutate({ ...currentTransaction, hashCode: Math.random() }, true);
        } else {
            crudNew();
        }
    }, [accounts, commodities, currentTransaction, currentTransactionMutate, txForm, crudNew, setNavbar]);

    const onMoneyFlowStartIndexChange = useCallback((moneyFlowStartIndex: number) => {
        setMoneyFlowStartIndex(moneyFlowStartIndex);
    }, []);

    useEffect(() => {
        if (!accounts || !commodities) return;
        if (txForm) {
            return;
        }
        if (!transactionId) {
            //console.log('transactionId', [transactionId, currentTransaction, accounts, commodities, lastFirstAccount]);
            txFormDispatch({ txReset: { accounts: accounts, commodities: commodities, lastFirstAccount } })
        } else if (currentTransaction?.data) {
            txFormDispatch({ txFromData: { data: currentTransaction.data, lastFirstAccount, accounts: accounts, commodities: commodities } });
        } else {
            txFormDispatch({ txUndefine: true })
        }
    }, [transactionId, currentTransaction, accounts, commodities, lastFirstAccount, txForm]);

    useEffect(() => {
        //console.log('useEffect #1', [txForm, awaitEdit, query.mode, formModeDesired, setNavbar]);
        if (!txForm) {
            setNavbar(CrudUtil.defaultNew)
            setFormMode('undetermined')
            return;
        }
        setNavbar(NavbarUtil.forForm(txForm));

        if (!TxUtil.canBeSimplified(txForm.splits)) {
            setFormMode('detailed');
        } else {
            setFormMode(formModeDesired)
        }
        if (awaitEdit) {
            setFormDisabled(true);
            setNavbar(CrudUtil.editRequired)
            return;
        }

        setFormDisabled(false);
        setNavbar(CrudUtil.editing({ dirty: txForm.dirty, valid: TxUtil.validate(txForm).valid, hasId: txForm.id }))
        setLastFormAction(s => produce(s, (d) => { d.type = 'editing' }));
    }, [txForm, awaitEdit, query.mode, formModeDesired, setNavbar]);

    useEffect(() => {
        setAccountNames(accounts?.data?.map(item => item.name) ?? [])
    }, [accounts])

    useNavbarCallback(useMemo(() => eventMap<Nav>({
        accounts: () => {
            if (lastFirstAccount) {
                let gotoAccount = accounts.indexByName?.[lastFirstAccount]?.id;
                AppHistory.push(`/ledgers/${ledgerId}/accounts/${gotoAccount}/transactions`)
            }
            else
                AppHistory.push(`/ledgers/${ledgerId}/accounts`)
        },
        crudNew: crudNew,
        crudSave: crudSave,
        crudEdit: crudEdit,
        crudCancel: crudCancel,
        crudDelete: crudDelete
    }), [ledgerId, crudNew, crudSave, crudEdit, crudCancel, crudDelete, lastFirstAccount, accounts.indexByName]))

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

    const onAskFormModeSwitch = useCallback(() => {
        let newDesiredMode = 'simplified' as FormMode;
        if (formMode === 'simplified') {
            newDesiredMode = 'detailed';
        } else {
            newDesiredMode = (txForm && TxUtil.canBeSimplified(txForm?.splits)) ? 'simplified' : 'detailed';
        }
        if (newDesiredMode === 'simplified') {
            setMoneyFlowStartIndex(undefined);
        }
        setFormModeDesired(newDesiredMode);
    }, [formMode, txForm]);

    return (
        <div className="TxEdit-page">
            {currentTransaction?.success === false && <span>Transaction not found.</span>}

            {accounts && commodities && txForm && formMode === 'detailed' &&
                <TxEditForm displayDisabled={formDisabled} accountNames={accountNames} {...txForm}
                    onTxChange={onTxChange}
                    onSplitAdd={onSplitAdd}
                    onSplitDelete={onSplitDelete}
                    onSplitChange={onSplitChange}
                    onAskFormModeSwitch={onAskFormModeSwitch} />
            }
            {accounts && commodities && txForm && formMode === 'simplified' &&
                <TxEditFormSimplified displayDisabled={formDisabled} accountNames={accountNames} {...txForm}
                    moneyFlowStartIndex={moneyFlowStartIndex}
                    onMoneyFlowStartIndexChange={onMoneyFlowStartIndexChange}
                    onTxChange={onTxChange}
                    onSplitAdd={onSplitAdd}
                    onSplitDelete={onSplitDelete}
                    onSplitChange={onSplitChange}
                    onAskFormModeSwitch={onAskFormModeSwitch} />
            }
            {['saved', 'deleted'].includes(lastFormAction.type) &&
                <Alert severity={lastFormAction.success ? 'success' : 'error'}>
                    {t(`last_form_action_${lastFormAction.type}_${lastFormAction.success}`)}
                </Alert>}
            <Dialog
                fullScreen={fullScreen}
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Delete confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this transaction, with all its splits?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={crudDeleteActual} color="primary">Delete</Button>
                    <Button onClick={() => setOpenDeleteDialog(false)} color="primary" autoFocus>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// TxEdit.whyDidYouRender = true;
