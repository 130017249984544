import { MonetaryValue } from 'component/MonetaryValue.co';
import moment from 'moment';
import React, { useCallback } from 'react';
import { AppHistory } from 'service/Page.store';
import { Service } from 'service/Service.store';
import { txDescription, txTarget } from './TxTable';
import './TxTableLarge.co.css';

export const TxTableLarge = React.memo((props: any) => {
    let { accounts, commodity, txList, ledgerId, style } = props;

    const handleTxItemClick = useCallback((event: any, ledgerId: string, tx: any) => {
        Service.mutateTransaction(ledgerId, tx.id, { data: tx });
        let editUri = `/ledgers/${ledgerId}/transactions/edit/${tx.id}?account=${tx.splits?.[0].account_id}`;
        //if (tx.splits.length > 2) {
        //    editUri = editUri + '/detailed';
        //}
        console.log('editUri', editUri);
        ledgerId && AppHistory.push(editUri)
    }, []);

    return (
        <table className="TxTableLarge">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Num</th>
                    <th>Description</th>
                    <th>Transfer</th>
                    <th>Value</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>
                {txList.map((tx: any) =>
                    <tr key={tx.id} onClick={event => handleTxItemClick(event, ledgerId, tx)}>
                        <td className="mono txtl-date">{moment(tx.splits?.[0].ts).format("YYYY-MM-DD")}</td>
                        <td className="mono">{tx.splits?.[0].num}</td>
                        <td>{txDescription(tx)}</td>
                        <td>{txTarget(accounts, tx)}</td>
                        <td className='txtl-value'><MonetaryValue value={tx.splits?.[0].value} commodity={commodity} style={style}></MonetaryValue></td>
                        <td className='txtl-value'><MonetaryValue value={tx.splits?.[0].balance} commodity={commodity} style={style}></MonetaryValue></td>
                    </tr>
                )}
            </tbody>
        </table>
    )
});
