import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export enum TxType {
    incomplete = 'incomplete',
    complex = 'complex',
    unknown = 'unknown',
    expense = 'expense',
    income = 'income',
    loan = 'loan',
    transfer = 'transfer'
}

export const resources = {
    en: {
        translation: {
            button_update: 'Update now',
            alert_update_text: 'An application update is available.',

            drawer_action_reports: 'Reports',
            drawer_action_accounts: 'Accounts',
            drawer_action_users: 'Users',

            txSplitLabel_incomplete: 'Amount',
            txSplitLabel_incomplete_reversed: 'Amount',
            txSplitLabel_complex: 'Amount',
            txSplitLabel_complex_reversed: 'Amount',
            txSplitLabel_unknown: 'Amount',
            txSplitLabel_unknown_reversed: 'Amount',

            txSplitLabel_expense_less_assets: 'Expense',
            txSplitLabel_expense_more_assets: 'Refund',
            txSplitLabel_income_more_assets: 'Income',
            txSplitLabel_income_less_assets: 'Income loss',
            txSplitLabel_loan_more_assets: 'Loan',
            txSplitLabel_loan_less_assets: 'Repaid',
            txSplitLabel_transfer_more_assets: 'Amount',
            txSplitLabel_transfer_less_assets: 'Amount',

            nav_accounts: 'Accounts',
            nav_home: 'Home',

            nav_crudNew: 'New',
            nav_crudSave: 'Save',
            nav_crudDelete: 'Delete',
            nav_crudEdit: 'Edit',
            nav_crudCancel: 'Cancel',

            nav_navAccountNew: 'Account',
            nav_navAccountEdit: 'Edit',
            nav_navAccountNewTx: 'Transaction',

            nav_accTimePrev: 'Prev',
            nav_accTimeNext: 'Next',
            nav_accMenuExtra: 'More',
            nav_accMenuDownload: 'Download QIF',
            nav_accMenuEdit: 'Edit',
            nav_accMenuSub: 'Sub-account',

            nav_navTxList: 'Account',

            nav_navNewLedger: 'Ledger',

            nav_navNewReport: 'Report',
            nav_navReportsList: 'Reports',
            nav_navReportsEdit: 'Edit',

            nav_ledgers: 'Ledgers',
            nav_signIn: 'Sign In',
            nav_terms: 'Terms',
            nav_about: 'About',

            account_edit_snackbar_crudDelete_success: 'Account deleted successfully',
            account_edit_snackbar_crudDelete_failed: 'Account was not deleted',
            account_edit_snackbar_crudSave_success: 'Account saved successfully',
            account_edit_snackbar_crudSave_failed: 'Account was not saved',

            payments_processor_not_available: 'Payments processor temporarily unavailable.',

            session_state_failure: 'Failed',
            session_state_new: 'In progress',
            session_state_success: 'Success',
            session_state_undefined: 'Unknown',

            last_form_action_saved_true: 'Saved successfully',
            last_form_action_saved_false: 'Save failed',
            last_form_action_deleted_true: 'Deleted successfully',
            last_form_action_deleted_false: 'Delete failed',
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;