import { mutate } from 'swr';
import { fetcher, Service } from './Service.store';

const ledgerStore = {


    create: (ledger: any) => {
        return fetcher(`/ledgers/`, { method: 'POST', body: ledger })
            .then(res => {
                mutate(`/ledgers`);
                return res;
            })
    },

    update: (ledgerId: string, body: any) => {
        return fetcher(`/ledgers/${ledgerId}`, { method: 'PUT', body: body })
            .then(res => {
                mutate(`/ledgers`);
                mutate(`/ledgers/${ledgerId}`)
                return res;
            });
    },

    createOrUpdateTransaction: (
        id: string | undefined,
        ledgerId: any,
        data: {
            description: string,
            splits: {
                tx_id?: string,
                id?: string,
                account_id: string,
                date: string,
                num: string,
                description?: string,
                value: number
            }[]
        }) => {

        if (id) {
            return fetcher(`/ledgers/${ledgerId}/transactions/${id}`, { method: 'PUT', body: data as any }).then(res => {
                let specData = { ...data, id: res.data.id };
                //console.log('Mutating tx', specData);
                Service.mutateTransaction(ledgerId, res.data.id, { data: specData });
                return res;
            })
        } else {
            return fetcher(`/ledgers/${ledgerId}/transactions/`, { method: 'POST', body: data as any }).then(res => {
                let specData = { ...data, id: res.data.id }
                //console.log('Mutating tx', specData);
                data.splits.forEach((split, index) => split.id = `local-${index}`);
                Service.mutateTransaction(ledgerId, res.data.id, { data: specData, shouldRevalidate: true });
                return res;
            })
        }
    },

    deleteTransaction(ledgerId: string, transactionId: string) {
        return fetcher(`/ledgers/${ledgerId}/transactions/${transactionId}`, { method: 'DELETE' }).then(res => {
            Service.mutateTransaction(ledgerId, res.data.id, null);
            return res;
        })
    },

    addUser: (ledgerId: string, body: { email: string }) => {
        return fetcher(`/ledgers/${ledgerId}/users`, { method: 'POST', body: body as any })
            .then(res => {
                mutate(`/ledgers/${ledgerId}/users`);
                return res;
            });
    },

    deleteUser: (ledgerId: string, actorId: string) => {
        return fetcher(`/ledgers/${ledgerId}/users/${actorId}`, { method: 'DELETE' })
            .then(res => {
                mutate(`/ledgers/${ledgerId}/users`);
                return res;
            });
    }
}

export { ledgerStore };

