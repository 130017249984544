import { mutate } from 'swr';
import { fetcher } from './Service.store';

export interface AccountNode {
    id: string,
    commodity: string,
    name: string,
    description: string,
    children?: AccountNode[],
    parent?: AccountNode,
    color?: { h: number, l: number, s: number }
}

export interface AccountAll {
    hashCode?: string,
    data?: AccountNode[],
    error?: any,
    indexByName?: { [key: string]: AccountNode },
    indexById?: { [key: string]: AccountNode },
    root?: AccountNode,
    date?: Date
}

export interface AccountCurrent {
    data?: AccountNode,
    error?: any
}

export function indexAccounts(accounts: AccountAll) {
    (accounts?.data || []).forEach((account: AccountNode) => {
        accounts.indexById = accounts.indexById || {};
        accounts.indexByName = accounts.indexByName || {};

        account.children = account.children || [];

        accounts.indexByName[account.name] = account;
        accounts.indexById[account.id] = account;

        if (account.name === '/') {
            accounts.root = account;
            delete account.parent;
        } else {
            let parentName = account.name.substring(0, account.name.lastIndexOf('/')) || '/';
            let parent = accounts.indexByName[parentName];

            if (parent) {
                account.parent = parent;
                parent.children = parent.children || [];
                parent.children.push(account);
            }
        }
    });

    return accounts;
}

const store = {

    accountBasename: (account: AccountNode) => {
        let accountName = account?.name || '';
        return store.accountBasenameString(accountName);
    },

    accountBasenameString: (accountName: string) => {

        if (accountName === '/') { return accountName; }
        return accountName.substr(accountName.lastIndexOf('/') + 1);
    },

    accountParentString: (accountName: string) => {
        if (accountName === '/') { return accountName; }
        return accountName.substr(0, accountName.lastIndexOf('/'));
    },

    accountSiblings: (accounts: any, accountName: string): AccountNode[] => {
        return accounts?.indexByName?.[accountName]?.parent?.children || []
    },

    createOrUpdate(ledgerId: string, accountId: string | undefined, body: any) {
        if (accountId) {
            return this.updateAccount(ledgerId, accountId, body)
        } else {
            return this.create(ledgerId, body);
        }
    },

    create: (ledgerId: string, account: any) => {
        // console.log('Create account', account);
        return fetcher(`/ledgers/${ledgerId}/accounts`, { method: 'POST', body: account })
            .then(res => {
                mutate(`/ledgers/${ledgerId}/accounts`);
                return res;
            })
    },

    updateAccount: (ledgerId: string, accountId: string, body: any) => {
        return mutate(`/ledgers/${ledgerId}/accounts/${accountId}`,
            fetcher(`/ledgers/${ledgerId}/accounts/${accountId}`, { method: 'PUT', body: body })
                .then(newAccountResponse => {
                    mutate(`/ledgers/${ledgerId}/accounts`, (accounts: any) => {
                        let account = accounts?.indexById?.[accountId];
                        if (account) {
                            account.description = newAccountResponse?.data?.description?.description;
                        }
                        for (let [key, value] of Object.entries(newAccountResponse?.data?.names ?? {})) {
                            let account = accounts?.indexById?.[key];
                            if (account) {
                                account.name = (value as any).name;
                                if (accounts?.indexByName) {
                                    accounts.indexByName[account.name] = account;
                                }
                            }
                        };
                        let newAccounts = { ...accounts, hashCode: Math.random(), date: new Date() };
                        return newAccounts;
                    }, false);
                    let newAccount = {
                        ...newAccountResponse?.data?.description,
                        ...newAccountResponse?.data?.names?.[accountId],
                        date: new Date()
                    };
                    return { data: newAccount };
                })
        );
    },

    delete: (ledgerId: string, accountId: string) => {
        return fetcher(`/ledgers/${ledgerId}/accounts/${accountId}`, { method: 'DELETE' })
            .then(res => {
                mutate(`/ledgers/${ledgerId}/accounts`);
                mutate(`/ledgers/${ledgerId}/accounts/${accountId}`)
                return res;
            })
    }
}

export const AccountsService = {
    byName: (accounts: AccountAll, name: string | undefined) => {
        return name ? accounts?.indexByName?.[name] : undefined;
    },
    byId: (accounts: AccountAll, id: string | undefined) => {
        return id ? accounts?.indexById?.[id] : undefined;
    },
    names: (accounts: AccountAll) => {
        return accounts?.data?.map((item: any) => item.name) || []
    }
};

export { store as accountsStore };

