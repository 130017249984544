import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { actionsSubject, NavbarEvent } from 'service/Page.store';
import { useBehaviorSubject } from 'service/RxEffects';
import './Navbar.co.css';

export default function NavbarBottom<T = any>(props: { eventPublisher: (event: NavbarEvent<T>) => void }) {
  const { eventPublisher } = props;

  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const [actions] = useBehaviorSubject(actionsSubject);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => { setMenuAnchorEl(null); }, []);

  const handleSelect = useCallback((event: any) => {
    setMenuAnchorEl(null);
    let element = event.target.closest('button') ?? event.target.closest('li');
    let key = element.getAttribute('data-key');

    // console.log('handleSelect', event.target, key, element);
    eventPublisher({ key, element });
  }, [eventPublisher]);

  return (
    <>
      {(actions !== undefined) && <BottomNavigation showLabels value={false} className="BottomNav">
        {actions?.map(action => {
          if (action.items) {
            return (
              <BottomNavigationAction
                key={action.key.toString()}
                label={action.text ?? t('nav_' + action.key.toString())}
                icon={action.icon}
                onClick={handleClick} />
            )
          } else {
            return (
              <BottomNavigationAction
                disabled={action.mode === 'disabled' || action.mode === 'progress'}
                className={`BottomNavigationAction-common BottomNavigationAction-${action?.mode ?? 'enabled'}`}
                key={action.key.toString()}
                data-key={action.key.toString()}
                label={action.text ?? t('nav_' + action.key.toString())}
                icon={action.icon}
                onClick={handleSelect} />
            );
          }
        })}
      </BottomNavigation>
      }
      {
        actions?.filter(action => action.items).map(action => {
          return <Menu
            key={`NavbarBottomMenu-${String(action.key)}`}
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleClose}
          >
            {
              (action?.items ?? []).map((item) => {
                return <MenuItem onClick={handleSelect} key={item.key.toString()} data-key={item.key.toString()}>
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text ?? t('nav_' + item.key.toString())} />
                </MenuItem>
              })
            }
          </Menu>
        })
      }
    </>
  );
}
