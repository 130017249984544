import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from "react";
import { AccountNode, accountsStore } from "service/Accounts.store";
import './AccountName.co.css';

export default function AccountNav(props: any) {
  const { anchorEl, accountList, linkList, open, handleClose, handleLink } = props;

  return (
    <Menu
      id="fade-menu"
      getContentAnchorEl={null}
      keepMounted

      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}

      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {
        accountList.map((account: AccountNode) =>
          <MenuItem key={account.name} onClick={() => handleClose(account)}>{accountsStore.accountBasename(account)}</MenuItem>
        )
      }
      {false && accountList?.length > 0 && <Divider />}
      {
        linkList.map((link: any) =>
          <MenuItem key={link.name} onClick={() => handleLink(link)}>{link.name}</MenuItem>
        )
      }
    </Menu>
  );
}
