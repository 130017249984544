import * as d3 from "d3";
import React, { useEffect, useRef } from "react";
import { D3StackedBarplotSimple } from "service/reports/D3StackedBarplotSimple";

export default function ChartBars(props: any) {
    const { accounts, reportTx, reportVersion, commodities } = props;

    const ref = useRef<SVGSVGElement>(null);

    useEffect(() => {
        const current = ref?.current;
        if (!current) {
            return;
        }
        if (!commodities) {
            return;
        }
        if (reportTx?.success === false) {
            d3.selectAll('.report-canvas > .d3_message > text').text('Invalid report')
            return;
        }

        if (reportTx?.data?.length === 0) {
            d3.selectAll('.report-canvas > .d3_message > text').text('No entries')
            return;
        }

        if (reportTx?.data && commodities && accounts) {
            D3StackedBarplotSimple.build(current,
                {
                    accounts: accounts,
                    commodities: commodities,
                    report: reportTx?.data,
                    ordinalDirection: 'horizontal'
                });
        } else {
            d3.selectAll('.report-canvas > .d3_message > text').text('')
        }

    }, [reportTx, reportVersion, commodities, accounts])

    return (
        <svg className="report-canvas" ref={ref}>
            <g className="d3_message">
                <text x="20" y="35"></text>
            </g>
            <defs>
                <filter id="d3_brightness">
                    <feComponentTransfer>
                        <feFuncR type="linear" slope="2"></feFuncR>
                        <feFuncG type="linear" slope="2"></feFuncG>
                        <feFuncB type="linear" slope="2"></feFuncB>
                    </feComponentTransfer>
                </filter>
                <filter id="drop-shadow" x="-100%" y="-100%" width="200%" height="200%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
                    <feOffset dx="0" dy="0" result="offsetblur" />
                    <feFlood floodColor="#FFFFFF" />
                    <feComposite in2="offsetblur" operator="in" />
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
            </defs>
        </svg >
    );
}
