import * as d3 from "d3";
import { TxReport } from "service/types/Types";
import { AccountsColorizer } from "service/ux/AccountsColorizer";
import { isAccountPositiveByName } from "../Account.store";
import { Money } from "../Money.util";
import { PlotMargin, PlotRect } from './Plot';
import { TxForex } from "./TxForex";

function floatValue(commodities: any, commodity: string, account: string, value: number) {
    const style = isAccountPositiveByName(account) ? 'normal' : 'reversed';

    const intValue = Math.round(value);
    const [, , units] = commodities[commodity];
    const td = Money.toDisplayA(intValue, units, style);
    return parseFloat(`${td.integer}.${td.decimals}`);
}

const renderGlobalLegend = (svg: any, { outer, margin, inner }: any, report: TxReport) => {
    //console.log('renderGlobalLegend', outer, margin, inner);

    let g = svg.append('g').attr('class', 'global-legend')

    //g.append('rect').attr("x", 5).attr("y", 5)
    //    .attr("width", 5).attr("height", 5)

    g.append('text').attr("x", 5).attr("y", 12).text(report.properties.name + " - " + report.properties.account)


}

const render = (ref: SVGSVGElement,
    { accounts, data, groups, ordinalValue, ordinalDirection, report }: {
        accounts: any,
        data: any[], groups: string[], ordinalValue: (x: any) => string, ordinalDirection: 'horizontal' | 'vertical',
        report: TxReport
    }) => {

    //console.log('Render data', data);

    const svg = d3.select(ref);

    const outer = ref.getBoundingClientRect()
    const margin: PlotMargin = { top: 20, right: 20, bottom: 20, left: 60 }
    const inner: PlotRect = {
        width: outer.width - margin.left - margin.right,
        height: outer.height - margin.top - margin.bottom
    }

    const propertiesSpec = {
        vertical: {
            ordinal: {
                coordinate: { name: "y", span: "height" },
                range: [0, inner.height] as [number, number],
                axis: {
                    fn: d3.axisLeft, transform: () => `translate(${scaleQuantitative(0)}, 0)`,
                    tickSizeInner: () => scaleQuantitative(0) + 5
                }
            },
            quantitative: {
                coordinate: { name: "x", span: "width", fn: (d: any) => d[1] > 0 ? scaleQuantitative(d[0]) : scaleQuantitative(d[1]) },
                range: [0, inner.width] as [number, number],
                axis: { fn: d3.axisBottom, transform: () => `translate(0, ${inner.height})` }
            }
        },
        horizontal: {
            ordinal: {
                coordinate: { name: "x", span: "width" },
                range: [0, inner.width] as [number, number],
                axis: {
                    fn: d3.axisBottom, transform: () => `translate(0, ${scaleQuantitative(0)})`,
                    tickSizeInner: () => scaleQuantitative(domainQuantitative[0]) - scaleQuantitative(0) + 5
                }
            },
            quantitative: {
                coordinate: { name: "y", span: "height", fn: (d: any) => d[1] >= 0 ? scaleQuantitative(d[1]) : scaleQuantitative(d[0]) },
                range: [inner.height, 0] as [number, number],
                axis: { fn: d3.axisLeft, transform: () => `translate(0, 0)` }
            }
        }
    }

    const properties = propertiesSpec[ordinalDirection];

    const labelOffset = report.properties.account.length;
    renderGlobalLegend(svg, { outer, inner, margin }, report);
    ////console.log(svg, outer);
    //var color = d3.scaleOrdinal().domain(groups).range(d3.schemeTableau10);
    var color2 = (accountFqName: string) => AccountsColorizer.colorOf(accounts, accountFqName);
    let dataNegative = JSON.parse(JSON.stringify(data)) as typeof data;
    let dataPositive = JSON.parse(JSON.stringify(data)) as typeof data;

    dataNegative.forEach(entry =>
        groups.forEach(key => { entry[key] = entry[key] > 0 ? 0 : entry[key] })
    )

    dataPositive.forEach(entry =>
        groups.forEach(key => { entry[key] = entry[key] < 0 ? 0 : entry[key] })
    )

    var stackedDataPositive = d3.stack().keys(groups)(dataPositive)
    var stackedDataNegative = d3.stack().keys(groups)(dataNegative)

    const min_ = Math.min(...stackedDataNegative.map(v =>
        Math.min(...v.map(item => item[1]))))
    const max_ = Math.max(...stackedDataPositive.map(v =>
        Math.max(...v.map(item => item[1]))))

    const domainQuantitative = [min_, max_];

    const scaleQuantitative = d3.scaleLinear()
        .domain(domainQuantitative)
        .range(properties.quantitative.range);

    const scaleOrdinal = d3.scaleBand()
        .domain(data.map(ordinalValue))
        .range(properties.ordinal.range)
        .padding(0.2)

    const g = svg.append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`)

    g.append('g').call(
        properties.ordinal.axis.fn(scaleOrdinal)
            .tickPadding(5)
            .tickSizeInner(properties.ordinal.axis.tickSizeInner())
    )
        .attr('transform', properties.ordinal.axis.transform())

    g.append('g').call(properties.quantitative.axis.fn(scaleQuantitative))
        .attr('transform', properties.quantitative.axis.transform())

    type rootData = { v: typeof stackedDataNegative, t: string }[];
    let plot_setup = (className: string) => g.append('g').attr('class', className)
        .selectAll('g.category')
        .data([
            { v: stackedDataPositive, t: 'positive' },
            { v: stackedDataNegative, t: 'negative' }
        ] as rootData)
        .join('g').attr('class', (d) => `category ${d.t}`)
        .selectAll('g')
        .data((d) => d.v)
        .join('g')

    plot_setup('bars')
        .attr('class', 'd3_bars_group')
        .attr("x-account", (d) => { return d.key as string })
        .attr("fill", (d) => { return color2(d.key) as string })
        .selectAll('rect')
        .data((d) => d.filter((v) => v[0] !== v[1])) // no zero-sized bars
        .join('rect')
        .attr("x-ordinal", (d) => ordinalValue(d.data))
        .attr(properties.ordinal.coordinate.name, (d) => { return scaleOrdinal(ordinalValue(d.data)) as number })
        .attr(properties.ordinal.coordinate.span, scaleOrdinal.bandwidth())
        .attr(properties.quantitative.coordinate.name, (d) => properties.quantitative.coordinate.fn(d))
        .attr(properties.quantitative.coordinate.span, (d) => Math.abs(scaleQuantitative(d[1]) - scaleQuantitative(d[0])))
        .on('mouseover', (d: any, i, nodes) => {
            const el1 = nodes[i] as SVGRectElement;
            var el = d3.select(el1);
            var p = d3.select((el1 as any).parentNode as SVGGElement);
            const ordinal = el.attr('x-ordinal');

            const myData: any = data.find(item => item.date === ordinal)
            let parentMax = 0;
            Object.keys(myData).filter(key => key.startsWith('/')).forEach(key => parentMax += myData[key])

            //console.log('max', ordinal, myData, parentMax);
            const account = p.attr('x-account');

            svg.selectAll(`g.bars g[x-account="${account}"]`).nodes().forEach(
                node => (d3.select(node).node() as any).classList.add('d3_bars_group_highlighted'));
            svg.selectAll(`g.texts g[x-account="${account}"]`).nodes().forEach(
                node => (d3.select(node).node() as any).classList.add('d3_bars_group_highlighted'));

            tooltip_text
                .attr(properties.ordinal.coordinate.name,
                    scaleOrdinal(ordinalValue(d.data)) as number + scaleOrdinal.bandwidth() / 2 +
                    (properties.ordinal.coordinate.name === 'y' ? 20 : 0))
                .attr(properties.quantitative.coordinate.name,
                    properties.quantitative.coordinate.fn(d) + Math.abs(scaleQuantitative(d[1]) - scaleQuantitative(d[0])) / 2 +
                    (properties.quantitative.coordinate.name === 'y' ? 20 : 0))
                .text(account.substring(labelOffset + 1) || '...')

            let bbox = tooltip_text.node()?.getBBox();

            tooltip.node()?.classList.remove('d3_hidden');

            const toolip_rect_margin = 2;
            tooltip_rect
                .attr('x', (bbox?.x ?? 0) - toolip_rect_margin)
                .attr('y', (bbox?.y ?? 0) - toolip_rect_margin)
                .attr('width', (bbox?.width ?? 0) + 2 * toolip_rect_margin)
                .attr('height', (bbox?.height ?? 0) + 2 * toolip_rect_margin)

            topMarker
                .attr(`transform`, `translate(0, ${scaleQuantitative(parentMax)})`)

            topMarker.node()?.classList.remove('d3_hidden');
        })
        .on('mouseout', (d, i, nodes) => {
            const el1 = nodes[i] as SVGRectElement;
            //var el = d3.select(el1);
            var p = d3.select((el1 as any).parentNode as SVGGElement);

            const account = p.attr('x-account');
            //console.log("mouseover element", p.attr('x-account'), el.attr('x-ordinal'));

            svg.selectAll(`g.bars g[x-account="${account}"]`).nodes().forEach(
                node => (d3.select(node).node() as any).classList.remove('d3_bars_group_highlighted'));
            svg.selectAll(`g.texts g[x-account="${account}"]`).nodes().forEach(
                node => (d3.select(node).node() as any).classList.remove('d3_bars_group_highlighted'));

            tooltip.node()?.classList.add('d3_hidden');
        })

    plot_setup('texts')
        .attr('class', 'd3_bars_group')
        .attr("x-account", (d) => { return d.key as string })
        .selectAll('text')
        .data((d) => d.filter((v) => v[0] !== v[1])) // no zero-sized bars
        .join('text')
        .text((d) => { /*console.log('text d=', d);*/ return (d[1] - d[0]).toFixed(0) })
        .attr("filter", "url(#drop-shadow)")
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .attr('pointer-events', 'none')
        .attr("x-ordinal", (d) => ordinalValue(d.data))
        .attr(properties.ordinal.coordinate.name, (d) => { return scaleOrdinal(ordinalValue(d.data)) as number + scaleOrdinal.bandwidth() / 2 })
        .attr(properties.quantitative.coordinate.name, (d) => properties.quantitative.coordinate.fn(d) + Math.abs(scaleQuantitative(d[1]) - scaleQuantitative(d[0])) / 2)

    const tooltip = g.append('g').attr('class', 'd3_tooltip d3_hidden').attr('pointer-events', 'none');
    const tooltip_rect = tooltip.append('rect').attr('rx', 4)
    const tooltip_text = tooltip.append('text').attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central').text('')

    const topMarker = g.append('line')
        .attr('class', 'd3_top_marker d3_hidden')
        .attr('x-role', 'top-marker')
        .attr(`${properties.ordinal.coordinate.name}1`, properties.ordinal.range[0])
        .attr(`${properties.ordinal.coordinate.name}1`, properties.ordinal.range[1])
        .attr(`${properties.quantitative.coordinate.name}1`, 0)
        .attr(`${properties.quantitative.coordinate.name}2`, 0)
};

export const D3StackedBarplotSimple = {

    reportToGraphData: (commodities: any, report: TxReport) => {
        const commodity = TxForex.getDefaultPrincipalCommodity(report);
        const commodityReport = report.map[commodity];

        //console.log('D3StackedBarplotSimple.reportToGraphData', commodity, commodityReport);
        let accountArray = [] as string[];
        let groupArray = [] as string[];
        for (let [account, accountReport] of Object.entries(commodityReport)) {
            accountArray.push(account);
            for (let [group, /*value*/] of Object.entries(accountReport)) {
                groupArray.push(group)
            }
        }
        accountArray = accountArray.sort().filter((el, i, a) => (i === a.indexOf(el)));
        groupArray = groupArray.sort().filter((el, i, a) => (i === a.indexOf(el)));

        let csv = [] as any[]
        for (let group of groupArray) {
            let obj = { date: group } as any;
            for (let account of accountArray) {
                obj[account] = floatValue(commodities, commodity, account, commodityReport[account][group] ?? 0)
            }
            csv.push(obj)
        }
        return {
            ordinalValue: (d: any) => d['date'],
            groups: accountArray,
            data: csv,
            report: report
        };
    },

    build: (ref: SVGSVGElement, arg: { accounts: any, commodities: any, report: any, ordinalDirection: 'horizontal' | 'vertical' }) => {
        //console.log('D3StackedBarplotSimple.build', ref, arg);

        let gd = D3StackedBarplotSimple.reportToGraphData(arg.commodities, arg.report);

        render(ref, { ...gd, ...{ ordinalDirection: arg.ordinalDirection }, accounts: arg.accounts });
    }
}