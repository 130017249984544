import { Button } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GavelIcon from '@material-ui/icons/Gavel';
import InfoIcon from '@material-ui/icons/Info';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { AppStepper } from 'component/ui/AppStepper';
import { Config } from 'Config';
import React, { useEffect, useMemo } from 'react';
import { AppHistory, eventMap, useNavbar, useNavbarCallback, useSearchParams } from 'service/Page.store';
import { Service } from 'service/Service.store';

const tutorialSteps = [
  {
    label: 'Keep your finances balanced',
    imgPath: '/img/1-accounts.png',
  },
  {
    label: 'Insightful & interactive reports',
    imgPath: '/img/2-reports.png',
  },
  {
    label: 'Share ledgers with family & friends',
    imgPath: '/img/3-shared.png',
  },
  {
    label: 'Data kept private & stored in EU 🇪🇺',
    imgPath: '/img/frankfurt.jpg',
  },
];

type Nav = { signIn: null, ledgers: null, terms: null, about: null }

export default function About() {
  let { mode } = useSearchParams();

  const [, setNavbar] = useNavbar<Nav>();

  const { data: auth } = Service.useAuth();

  useEffect(() => {
    setNavbar([
      { key: 'signIn', icon: <AccountBoxIcon />, mode: auth?.data ? "hidden" : "enabled" },
      { key: 'ledgers', icon: <MenuBookIcon />, mode: auth?.data ? "enabled" : "hidden" },
      { key: 'about', icon: <InfoIcon /> },
      { key: 'terms', icon: <GavelIcon /> },
    ]);
  }, [setNavbar, auth]);


  useNavbarCallback(useMemo(() => eventMap<Nav>({
    signIn: () => { window.location.href = `${Config.api.url}/auth/google` },
    ledgers: () => AppHistory.push(`/ledgers`),
    terms: () => { AppHistory.push(`/about?mode=terms`) },
    about: () => { AppHistory.push(`/about?mode=info`) }
  }), []))

  return (
    <>
      {
        (mode === 'info' || mode === undefined) && <div className='content_margin'>
          <h1>UtterCash</h1>
          <h4>Multi-User Double-Entry Accounting Could App</h4>

          <div>
            <AppStepper tutorialSteps={tutorialSteps} />
          </div>

          {auth?.data && <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<MenuBookIcon />}
              onClick={() => AppHistory.push(`/ledgers`)}
            >
              My ledgers
      </Button>
          </div>}

          {!auth?.data && <div>
            <Button
              variant="contained"
              color="default"
              component='a' href={`${Config.api.url}/auth/google`}
              startIcon={<AccountBoxIcon />}
            >Sign in with Google</Button>
          </div>
          }
          <div className='grow'></div>
          <div className='right'>© 2020 Denksoft SRL</div>
        </div>
      }
      { mode === 'terms' && <div className='content_margin'>
        <h1>UtterCash</h1>
        <h2>Terms of Service</h2>
        <div>
          <p>The application UtterCash is owned and provided as a service by DENKSOFT SRL, a company registered in Romania, EU.
The application offers a multi-user double-ledger accounting system, accessible over the internet.</p>
          <p>The service is offered as is, with no warranties. Each imaginable risk is your own, as permitted by law.
          Omissions, unclarities and mistakes are to be construed in provider's favor.</p>
          <p>Data is stored on EU servers for the sole purpose of offering this service.</p>
          <p>Governing law is that of Romania. Disputes are to be handled in the local juristiction of the provider.</p>
        </div>
      </div>
      }
    </>
  );
}
