import { useEffect, useState } from "react";

export type LiveObjectIngorer<T> = (current: T, previous: T) => boolean;
export type LiveObjectOpts<T> = {
    ignorer?: LiveObjectIngorer<T>
    logger?: (...args: any) => unknown,
    name?: string
}

export class LiveObject<T> {
    static MARKER_KEY = 'LiveObjectFn';

    static instanceId: number = 0;

    id: number = 0;
    updaters: { [key: string]: (v: T) => void } = {};
    name: string;

    constructor(public value: T, private opts?: LiveObjectOpts<T>) {
        LiveObject.instanceId++;

        this.opts = opts ?? {}
        this.name = `${LiveObject.name}::${LiveObject.instanceId}${opts?.name ? '/' + opts.name : ''}`;

        this.opts?.logger?.(this.name, 'constructor', value, opts);
    }

    subscribe(fn: (v: T) => void, name?: string) {
        this.id++;

        let nameMarker = name ? `/${name}` : '';

        let key = `${LiveObject.MARKER_KEY}::${this.id}${nameMarker}`;
        (fn as any)[LiveObject.MARKER_KEY] = key;
        this.updaters[key] = fn;

        this.opts?.logger?.(this.name, this.subscribe.name, '[dispatch]',
            `(source last value)`,
            this.value,
            key
        );
        fn(this.value);
    }

    unsubscribe(fn: (v: T) => void) {
        let key = (fn as any)[LiveObject.MARKER_KEY];

        this.opts?.logger?.(this.name, this.unsubscribe.name, key, { fn: fn });

        delete (fn as any)[key];
        delete this.updaters[key];
    }

    update(newValue: T, publisherName?: string) {
        if (this.opts?.ignorer?.(newValue, this.value)) {
            this.opts?.logger?.(this.name, this.update.name, '[ignore]',
                `(source '${publisherName}')`,
                newValue
            );
            return;
        }

        this.value = newValue;

        this.opts?.logger?.(this.name, this.update.name, '[dispatch]',
            `(source '${publisherName}')`,
            newValue,
            Object.values(this.updaters).map(fn => (fn as any)[LiveObject.MARKER_KEY]),
        );
        queueMicrotask(() => {
            Object.values(this.updaters).forEach(fn => fn(this.value));
        })
    }

    use(userName?: string) {
        return useLiveObject(this, userName)[0];
    }
}

export function useLiveObject<T>(o: LiveObject<T>, userName?: string) {
    let [state, setState] = useState(() => o.value);

    useEffect(() => {
        o.subscribe(setState, userName);
        return () => {
            o.unsubscribe(setState);
        }
    }, [o, setState, userName])

    return [state, setState];
}

export function useLiveObjectPublisherEffect<T>(o: LiveObject<T>, value: T, publisherName?: string) {
    useEffect(() => o.update(value, publisherName), [o, publisherName, value])
}