import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { MonetaryValue } from 'component/MonetaryValue.co';
import React from 'react';
import { AppHistory } from 'service/Page.store';
import { Service } from 'service/Service.store';
import AccountName from '../AccountName.co';
import { txDate, txTarget } from './TxTable';
import './TxTableSmall.co.css';

export const TxTableSmall = React.memo((props: any) => {
    let { txList, accounts, commodity, ledgerId, style } = props;

    function handleTxItemClick(event: any, ledgerId: string | undefined, tx: any) {
        Service.mutateTransaction(ledgerId, tx.id, { data: tx });

        let editUri = `/ledgers/${ledgerId}/transactions/edit/${tx.id}?account=${tx.splits?.[0].account_id}`;
        //let editUri = `/ledgers/${ledgerId}/transactions/edit/${tx.id}`;
        //if (tx.splits.length > 2) {
        //    editUri = editUri + '/detailed';
        //}
        ledgerId && AppHistory.push(editUri)
    }

    return (
        <div>
            {txList?.length === 0 && <div className='no-transactions'>No transactions.</div>}

            {(txList?.length > 0) && <List>
                {txList.map((tx: any) =>
                    <ListItem key={tx.id} button dense={true} className={"txs-ListItem"}
                        onClick={event => handleTxItemClick(event, ledgerId, tx)}
                    >
                        <ListItemText
                            primary={
                                <React.Fragment>
                                    <span className={"mono txs-date"}>{txDate(tx)}</span>
                                    <span className={"txs-desc"}>{tx.description}</span>
                                </React.Fragment>
                            }
                            secondary={<AccountName name={txTarget(accounts, tx)} />}
                        />

                        <ListItemSecondaryAction className="accountRight">
                            <div><MonetaryValue value={tx.splits?.[0].value} commodity={commodity} style={style}></MonetaryValue></div>
                            <div>{tx.splits?.[0]?.balance &&
                                <MonetaryValue value={tx.splits?.[0]?.balance} commodity={commodity} style={style}></MonetaryValue>
                            }</div>
                        </ListItemSecondaryAction>

                    </ListItem>)
                }
            </List>
            }
        </div>
    )
})

TxTableSmall.whyDidYouRender = true;