import React from 'react';
import { Commodity } from 'service/Data.store';
import { Money } from 'service/Money.util';
import { CommoditySymbol } from './CommoditySymbol.co';
import './MonetaryValue.co.css';

export const MonetaryValue = React.memo((props: {
  value: number,
  commodity: Commodity
  style: 'normal' | 'reversed'
}) => {
  const { value, commodity } = props;

  let { style } = props;
  style = style ?? 'normal';

  if (!commodity?.symbol) {
    throw new Error("No commodity symbol");
  }
  if (!commodity?.units) {
    throw new Error("No commodity units");
  }

  let display = Money.toDisplay(Math.round(value), commodity, style);

  return (
    <span className="monetaryValue">
      <span className="commodity">
        <CommoditySymbol symbol={commodity.symbol} />
      </span>
      <span className={display.displayClass}>
        {display.displayString.integer}<span>.{display.displayString.decimals}</span>
      </span>
    </span>
  );
});
