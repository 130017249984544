import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  button: {
    margin: '2px',
  },
}));

export default function ActionButton(props: any) {
  const { children } = props;

  const classes = useStyles();

  return <Button
    variant="contained"
    color="primary"
    size="small"
    className={classes.button}
    {...props}
  >{children}</Button>
}