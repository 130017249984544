import React from 'react';
import ReactDOM from 'react-dom';
import { appUpdateSubject } from 'service/Page.store';
import '_why';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate(registration: ServiceWorkerRegistration) {
    // console.log('>>> onUpdate', JSON.stringify(registration), registration);
    appUpdateSubject.next({ update: true });
  }
});
