import TextField from '@material-ui/core/TextField';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ActionButton from 'component/ActionButton.co';
import React, { useEffect, useMemo, useState } from 'react';
import { ledgerStore } from 'service/Ledger.store';
import { actionsSubject, AppHistory, eventMap, useNavbar, useNavbarCallback } from 'service/Page.store';
import { Service } from 'service/Service.store';

type Nav = { accounts: null; }

export default function LedgerEdit(props: any) {
  let { ledgerId } = props;

  const [form, setForm] = useState({ name: '', description: '' });
  const [formDisabled, setFormDisabled] = useState(false);

  const [notification, setNotification] = useState<string | null>(null);

  const { data: ledger } = Service.useLedger(ledgerId);//useBehaviorSubject(ledgerStore.current, `${LedgerEdit.name}>ledger`);

  const [, setNavbar] = useNavbar<Nav>();
  useEffect(() => {
    setNavbar([
      { key: 'accounts', icon: <AccountTreeIcon />, mode: 'disabled' }
    ]);
  }, [setNavbar]);
  useNavbarCallback(useMemo(() => eventMap<Nav>({
    accounts: () => AppHistory.push(`/ledgers/${ledgerId}/accounts`)
  }), [ledgerId]));

  useEffect(() => {
    ledgerId && actionsSubject.next([
      { key: 'accounts', text: 'Accounts', icon: <AccountTreeIcon />, link: `/ledgers/${ledgerId}/accounts` }
    ]);
  }, [ledgerId]);

  useEffect(() => {
    if (!ledger || !ledgerId) {
      return;
    }

    setForm(myForm => {
      return {
        ...myForm,
        name: ledger?.data.name || '',
        description: ledger?.data.description || ''
      }
    });
  }, [ledger, ledgerId]);

  function handleChange(fieldName: string) {
    return (event: any) => {
      setForm({ ...form, [fieldName]: event?.target.value });
    }
  }

  function Button_onSubmit() {
    if (!ledgerId || !form.name) {
      setNotification('Cannot update now');
      return;
    }
    setFormDisabled(true);
    setNotification('In progress');

    ledgerStore.update(ledgerId, form).then(
      ret => { console.log('ret=', ret); setNotification('Ledger updated'); },
      err => { console.log('err=', err); setNotification('Ledger update failed'); }
    )
  }

  return (
    <div>
      {ledger &&
        <form className="myform" noValidate autoComplete="off">
          <fieldset disabled={formDisabled}>
            <div>
              <TextField className="dsc" label="Ledger name"
                value={form.name} onChange={handleChange('name')} />
            </div>
            <div>
              <TextField className="dsc" label="Ledger description"
                value={form.description} onChange={handleChange('description')} />
            </div>

            <br />

            <ActionButton disabled={formDisabled} onClick={Button_onSubmit}>Submit</ActionButton>
            <span className="notification">{notification}</span>
          </fieldset>
        </form>
      }
    </div>
  );
}
