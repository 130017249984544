import MomentUtils from '@date-io/moment';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import './AccountName.co.css';

interface DateNavPickerVal {
  date: Date;
  granularity: string;
};

export default function DateNavPicker(props: {
  value: DateNavPickerVal,
  onChange: (val: DateNavPickerVal) => void
}) {
  const { value, onChange } = props;

  const onChangeDateRangeType = (event: React.MouseEvent<HTMLElement>, newType: string) => {
    value.granularity = newType;
    onChange({ ...value, granularity: newType })
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          variant="static"
          value={value.date}
          onChange={(state) => {
            // console.log('DatePicker onChange', state?.toDate());
            value.date = state?.toDate() || new Date();
            onChange({ ...value, date: value.date });
          }}
        />
      </MuiPickersUtilsProvider>
      <ToggleButtonGroup
        value={value.granularity}
        onChange={onChangeDateRangeType}
        exclusive
        aria-label="granularity"
      >
        <ToggleButton value="year" aria-label="year">
          Year
        </ToggleButton>
        <ToggleButton value="month" aria-label="month">
          Month
        </ToggleButton>
        <ToggleButton value="week" aria-label="week">
          Week
        </ToggleButton>
        <ToggleButton value="all" aria-label="all">
          All
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}
