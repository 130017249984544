import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core';
//import './TransactionsNew.css';
import ListIcon from '@material-ui/icons/List';
import { SelectAccount } from 'component/SelectAccount';
import { SelectCommodity } from 'component/SelectCommodity';
import { AppSnackbar, AppSnackbarPropsState } from 'component/ui/AppSnackbar';
import { MTextField } from 'component/ui/AppUi';
import { produce } from 'immer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountAll, accountsStore } from 'service/Accounts.store';
import { AppHistory, eventMap, useNavbar, useNavbarCallback, useSearchParams } from 'service/Page.store';
import { Api, Service } from 'service/Service.store';
import { CrudButtonKind, CrudUtil } from 'service/ux/CrudButtons';
import { GenericForm, GenericFormActions } from 'service/ux/Form';

// const DEBUG_DELAY = 0;

export type AccountFormData = {
  id?: string
  name: string,
  description: string,
  parentAccountName: string,
  commodity: string
};

export type AccountForm = GenericForm<AccountFormData>;

const Form = {
  ...GenericFormActions,
  new: () => {
    return { state: {}, data: { name: '', description: '', parentAccountName: '', commodity: '' } } as AccountForm;
  },
  reset: (accounts: AccountAll, parentAccountId?: string) => {
    return (form: AccountForm) => produce(form, (draft) => {
      //console.log('Reset form', JSON.stringify(form));

      let parentAccountName = parentAccountId && accounts?.indexById?.[parentAccountId]?.name;
      let newParentAccountName = (form?.data.parentAccountName ? form?.data.parentAccountName : parentAccountName) ?? '';
      let newCommodity = form?.data.commodity ? form.data.commodity : accounts.indexByName?.[newParentAccountName]?.commodity ?? '';

      draft.data.parentAccountName = newParentAccountName;
      draft.data.commodity = newCommodity;

      //console.log('newParentAccountName', newParentAccountName, 'newCommodity', newCommodity);
      draft.data = { ...draft.data, name: '', description: '' }
      Object.assign(draft.state, {
        dirty: false, valid: false, error: false, awaitEdit: false, confirmDelete: false, disabled: false
      } as Partial<FormData>)
    })
  },
  validate: (old: AccountForm) => {
    return produce(old, (draft) => {
      draft.state.valid = old.data.name && old.data.parentAccountName && old.data.commodity ? true : false;
    })
  },
  fromData: (newData: any) => (old: AccountForm) => produce(old, (draft) => {
    draft.state.dirty = false; draft.state.error = false;
    Object.assign(draft.data, {
      name: accountsStore.accountBasenameString(newData.name),
      id: newData.id,
      description: newData.description,
      commodity: newData.commodity,
      parentAccountName: accountsStore.accountParentString(newData.name)
    })
  }),
  toData: (form: AccountForm) => {
    return {
      parent: form.data.parentAccountName,
      name: form.data.name,
      description: form.data.description,
      commodity: form.data.commodity
    }
  },
}

//const Snackbar_duration = 4000;
export default function AccountEdit(props: any) {
  let { ledgerId, accountId } = props;
  const { t } = useTranslation();

  let query = useSearchParams() as { parentAccount?: string };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { accounts, commodities } = Service.useLedgerContext(props);
  const { data: account } = Service.useAccountById(accounts, accountId);

  const [form, setForm] = useState(() => Form.reset(accounts, query.parentAccount)(Form.new()));

  const [snackbar, setSnackbar] = useState({
    message: undefined,
    severity: undefined
  } as AppSnackbarPropsState);

  const onSnackbarClose = useCallback(() => setSnackbar(s => ({ ...s, message: undefined })), []);

  type Nav = CrudButtonKind & { navTxList: null };

  const [, setNavbar] = useNavbar<Nav>();

  useEffect(() => setNavbar([
    { key: 'navTxList', icon: <ListIcon /> }, ...CrudUtil.New()
  ]), [setNavbar]);

  const handleChangeByName = useCallback((name: string, value: string) => {
    setForm(Form.field(name, value));
  }, []);
  const handleChangeByEvent = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeByName(event.target.getAttribute('name') ?? '', event.target.value);
  }, [handleChangeByName]);
  const handleChangeAccount = useCallback((name: string, value: string) => {
    setForm(form => produce(form, (draft) => {
      let accountName = value;
      let account = accounts.indexByName?.[accountName];

      if (account && !form.data.commodity) {
        draft.data.commodity = account?.commodity ?? '';
      }
      draft.data.parentAccountName = accountName;
    }));
  }, [accounts]);

  const crudCancel = useCallback(() => setForm(account ? Form.fromData(account) : Form.reset(accounts, query.parentAccount)),
    [account, accounts, query.parentAccount]);

  const crudNew = useCallback(() => {
    setForm(Form.edit)
    setForm(Form.reset(accounts))
    setNavbar(CrudUtil.Default)
    AppHistory.push(`/ledgers/${ledgerId}/accounts/edit/new`);
  }, [ledgerId, accounts, setNavbar]);

  const asyncOp = useCallback((action: keyof CrudButtonKind, ledgerId: string) => {
    return {
      before: () => {
        setForm(Form.progress);
        setNavbar(CrudUtil.progress[action]);
      },
      then: (res: any) => {
        setNavbar(CrudUtil.success[action])
        setForm(Form.success[action])
        setSnackbar({ severity: 'info', message: t(`account_edit_snackbar_${action}_success`) });
        AppHistory.push(`/ledgers/${ledgerId}/accounts/edit/${res.data.id}`);
      },
      error: () => {
        setNavbar(CrudUtil.failed[action])
        setSnackbar({ severity: 'error', message: t(`account_edit_snackbar_${action}_failed`) });
      },
      finally: () => setForm(Form.progressDone)
    }
  }, [setNavbar, t]);

  const crudSave = useCallback(() => {
    Api(accountsStore.createOrUpdate(ledgerId, accountId, Form.toData(form)), asyncOp('crudSave', ledgerId));
  }, [ledgerId, accountId, asyncOp, form]);

  const crudDelete = useCallback(() => {
    Api(accountsStore.delete(ledgerId, accountId), asyncOp('crudDelete', ledgerId));
  }, [ledgerId, accountId, asyncOp]);

  useNavbarCallback(useMemo(() => eventMap<Nav>({
    navTxList: () => {
      if (accountId && !form.state.deleted)
        AppHistory.push(`/ledgers/${ledgerId}/accounts/${accountId}/transactions`)
      else if (query.parentAccount)
        AppHistory.push(`/ledgers/${ledgerId}/accounts/${query.parentAccount}/transactions`)
      else
        AppHistory.push(`/ledgers/${ledgerId}/accounts`)
    },
    crudNew: crudNew,
    crudSave: crudSave,
    crudCancel: crudCancel,
    crudEdit: () => { setForm(Form.edit); setNavbar(CrudUtil.Default); },
    crudDelete: () => { setForm(Form.confirmDeleteOpen) }
  }), [ledgerId, accountId, crudNew, crudSave, crudCancel, setNavbar, query.parentAccount, form.state.deleted]));

  useEffect(() => {
    setForm(Form.validate);
  }, [form.data, setForm]);

  useEffect(() => {
    if (!form.state.awaitEdit && !form.state.progress && !form.state.deleted) {
      setNavbar(CrudUtil.editing({ valid: form.state.valid, dirty: form.state.dirty, hasId: form.data.id }))
    }
  }, [form.state, form.data, setNavbar])

  useEffect(() => {
    if (account) {
      setForm(Form.fromData(account));
    } else {
      if (accountId) {
        setNavbar(CrudUtil.allowNew);
      }
    }
  }, [account, accountId, setNavbar])

  if (!accounts?.data || !commodities?.data) return <></>;

  return (
    <>
      {(form.state.deleted || (accountId && !account)) && <div>No such account.</div>}
      {!form.state.deleted && (!(accountId && !account)) && <div>
        <form className="myform" noValidate autoComplete="off">
          <fieldset disabled={form.state.disabled}>
            <div>
              <MTextField className="dsc" label="Account name" name="name" value={form.data.name} onChange={handleChangeByEvent} />
            </div>
            <div>
              <MTextField className="dsc" label="Account description" name="description"
                value={form.data.description} onChange={handleChangeByEvent} />
            </div>
            <div>
              <SelectAccount
                disabled={form.state.disabled}
                accounts={accounts.data} value={form.data.parentAccountName} name="parentAccountName" label="Parent account" onChange={handleChangeAccount} />
            </div>
            <div>
              <SelectCommodity
                disabled={form.state.disabled}
                commodities={commodities.data} value={form.data.commodity} name="commodity" onChange={handleChangeByName} />
            </div>
          </fieldset>
        </form>

        <Dialog
          fullScreen={fullScreen}
          open={form.state.confirmDelete ? true : false}
          onClose={Form.confirmDeleteClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Delete confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this account, with all its transactions?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={crudDelete} color="primary">Delete</Button>
            <Button onClick={() => setForm(Form.confirmDeleteClose)} color="primary" autoFocus>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
      }
      <AppSnackbar onClose={onSnackbarClose} {...snackbar} />
    </>
  );
}