import { Button, IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import { MAutocomplete, MKeyboardDatePicker, MMonetaryValueField, MTextField } from "component/ui/AppUi";
import React, { useCallback, useEffect, useState } from "react";
import { TxForm, TxFormSplit, TxUtil } from "./TxEdit.form";
import "./TxEdit.page.css";


export function TxEditForm(props:
    TxForm &
    { accountNames: string[] } &
    { displayDisabled: boolean } &
    {
        onTxChange?: (update: Partial<TxForm>) => void,
        onSplitDelete?: (index: number) => void,
        onSplitChange?: (index: number, split: Partial<TxFormSplit>) => void,
        onSplitAdd?: () => void,
        onAskFormModeSwitch: () => void
    }
) {
    let txChange = useCallback((e => props.onTxChange?.({ description: e?.target.value })), [props.onTxChange]);

    let [mayAsForSimplifiedMode, setMayAskForSimplifiedMode] = useState(TxUtil.canBeSimplified(props.splits));

    useEffect(() => {
        setMayAskForSimplifiedMode(TxUtil.canBeSimplified(props.splits));
    }, [props.splits]);

    return (
        <div>
            <div>
                <MTextField className="Transaction-Description" fullWidth={true} disabled={props.displayDisabled}
                    size={"small"} variant={"outlined"}
                    label="Transaction description" value={props.description}
                    onChange={txChange} />
            </div>

            {props.splits.map((split, index) => <SplitEdit index={index} {...split} errorAccount={false}
                key={`split-${split.key}-${split.id}`}
                displayDisabled={props.displayDisabled}
                accountNames={props.accountNames}
                onChange={props.onSplitChange ?? (() => { })}
                onDelete={props.onSplitDelete ?? (() => { })} />)}

            <div className="Transaction-Actions">
                {mayAsForSimplifiedMode && <Button
                    disabled={props.displayDisabled}
                    variant="contained"
                    color="default"
                    startIcon={<UnfoldLessIcon />}
                    onClick={props.onAskFormModeSwitch}
                >Simplified</Button>}
                <Button
                    disabled={props.displayDisabled}
                    variant="contained"
                    color="default"
                    startIcon={<AddIcon />}
                    onClick={props.onSplitAdd ?? (() => { })}
                >Split</Button>
            </div>
        </div>
    );
}

const SplitEdit = React.memo((props:
    TxFormSplit &
    { errorAccount: boolean } &
    { index: number } &
    { accountNames: string[] } &
    { displayDisabled: boolean } &
    { onChange?: (index: number, update: Partial<TxFormSplit>) => void, onDelete?: (index: number) => void, onFocus?: (arg: boolean) => void }
) => {
    const [displayFocus, setDisplayFocus] = useState(false as boolean)

    const focus = useCallback(() => {
        setDisplayFocus(true);
    }, []);
    const blur = useCallback(() => {
        setDisplayFocus(false);
    }, []);

    let chDesc = useCallback(e => props.onChange?.(props.index, { description: e?.target.value }), [props.onChange, props.index]);

    let doc = useCallback((date) => props.onChange?.(props.index, { date: (date?.format('YYYY-MM-DD') || '') }), [props.onChange, props.index]);
    let act = useCallback((params) => <MTextField {...params}
        disabled={props.displayDisabled} required={true} error={props.errorAccount}
        label={`Account ${props.index + 1}`} size="small" variant="outlined" margin="dense" />, [props.displayDisabled, props.errorAccount, props.index]);

    let acc = useCallback((event, value) => props.onChange?.(props.index, { account: value ?? '' }), [props.index, props.onChange]);

    let mvfc = useCallback((e: any) => props.onChange?.(props.index, { value: e.target.value }), [props.index, props.onChange]);

    return (
        <div className={`Split-Form ${displayFocus ? 'Split-Form-Focused' : ''} `}>
            <div className="Split-Description">
                <MTextField fullWidth={true} size={"small"} variant={"outlined"}
                    disabled={props.displayDisabled}
                    onFocus={focus}
                    onBlur={blur}
                    label={`Split ${props.index + 1} description`}
                    value={props.description}
                    onChange={chDesc}
                />
            </div>
            <div className="Split-DateValue">
                <MKeyboardDatePicker size={"small"} inputVariant="outlined" margin="dense" className="Split-Date" disableToolbar variant="inline"
                    disabled={props.displayDisabled}
                    onFocus={focus}
                    onBlur={blur}
                    label="Date"
                    format="YYYY-MM-DD"
                    value={props.date}
                    onChange={doc}
                />

                {props.commodityObj && <MMonetaryValueField
                    disabled={props.displayDisabled}
                    onFocus={focus}
                    onBlur={blur}
                    label={'Value'}
                    value={props.value}
                    commodity={props.commodityObj}
                    onChange={mvfc}
                />}

                <IconButton aria-label="delete" onClick={() => props.onDelete?.(props.index)} disabled={props.displayDisabled}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </div>

            <div>
                <MAutocomplete className="Split-Account"
                    disabled={props.displayDisabled}
                    onFocus={focus}
                    onBlur={blur}
                    value={props.account}
                    options={props.accountNames}
                    onChange={acc}
                    renderInput={act}
                />
            </div>
        </div >
    )
})
