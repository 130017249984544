import { AccountAll, AccountNode } from "service/Accounts.store";

function rootname(v: string) {
    return v.split('/')[1]
}

let rootNameHue = {
    'assets': (v: number) => 60 + (170 - 60) * v,
    'liabilities': (v: number) => 180 + (330 - 180) * v,
    'equity': (v: number) => 170 + (200 - 170) * v, // 170...200

    'income': (v: number) => 70 + (170 - 70) * v, // 70...170
    'expenses': (v: number) => v < 0.5 ? (0.5 - v) * 100 : 360 + (0.5 - v) * 200, // 0...50 + 350..300
}

function colorize(node: AccountNode, parent: AccountNode | null, nodeIndexInParent: number, level: number) {
    const parentChildren = parent?.children ?? [];
    if (level === 0) {
    }
    if (level === 1) {
        let myrootname = rootname(node.name);
        let fn = (rootNameHue as any)[myrootname] ?? ((v: number) => 0);
        node.color = { h: fn(0.5), l: 40, s: 60 }
    }
    if (level === 2) {
        let myrootname = rootname(node.name);
        let knob = parentChildren.length === 1 ? 0 : nodeIndexInParent / (parentChildren.length - 1)
        let fn = (rootNameHue as any)[myrootname] ?? ((v: number) => 0);
        node.color = { h: fn(knob), l: 50, s: 50 }
    }
    if (level >= 3) {
        let color = { h: parent?.color?.h ?? 0, s: parent?.color?.s ?? 0, l: parent?.color?.l ?? 0 };
        color.l = color.l + (level - 2) * 5;

        let knob = (parent?.children ?? []).length === 1 ? 0 : nodeIndexInParent / ((parent?.children ?? []).length - 1)
        color.s = color.s - knob * 20;

        node.color = color;
    }
    (node?.children ?? []).forEach((child: any, index: number) => colorize(child, node, index, level + 1));
    return node;
}

export const AccountsColorizer = {
    colorize: (accounts: AccountAll) => { accounts?.root && colorize(accounts.root, null, -1, 0); return accounts; },
    colorOf: (accounts: any, accountName: string) => {
        let color = accounts?.indexByName[accountName]?.color ?? { h: 0, s: 0, l: 0 };
        return `hsl(${color.h}, ${color.s}%, ${color.l}%)`
    }
}