import { Commodities } from 'service/Data.store';
import { useBehaviorSubject } from 'service/RxEffects';
import { BehaviorSubject } from 'rxjs';
import { AccountAll, AccountNode } from "./Accounts.store";
import { Ledger } from './Data.store';

const ledgerSubject: BehaviorSubject<Ledger | undefined> = new BehaviorSubject<Ledger | undefined>(undefined as Ledger | undefined);
const accountSubject = new BehaviorSubject<{ data?: AccountNode } | undefined>(undefined as { data?: AccountNode } | undefined);
const accountsSubject = new BehaviorSubject<AccountAll | undefined>(undefined as AccountAll | undefined);
const commoditiesSubject = new BehaviorSubject<Commodities | undefined>(undefined as Commodities | undefined);

export const Globals = {
    useLedger: () => { return useBehaviorSubject<Ledger | undefined>(ledgerSubject) },
    useAccount: () => useBehaviorSubject(accountSubject),
    useAccounts: () => useBehaviorSubject(accountsSubject),
    useCommodities: () => useBehaviorSubject(commoditiesSubject),

    updateLedger: (ledger: Ledger | undefined) => ledgerSubject.next(ledger),
    updateAccount: (account: { data?: AccountNode } | undefined) => accountSubject.next(account),
    updateAccounts: (accounts: AccountAll | undefined) => accountsSubject.next(accounts),
    updateCommodities: (commodities: Commodities | undefined) => commoditiesSubject.next(commodities)
}