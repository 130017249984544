import { loadStripe } from "@stripe/stripe-js";
import i18next from "i18next";
import { fetcher_base } from "service/Service.store";

export const StripePromise = loadStripe('pk_test_51HhsZ3CXwqm9h4B37dakC7T5T3sGfHofuIInpyIo1EXXcU9GxkvyjAZjUkX0zDYowqVAFsahkfnHaKp3hAaR7Wk700fJh9K91q');

export const PaymentsService = {
    pay: () => {
        return fetcher_base('/pay/stripe/sessions', {
            method: 'POST',
            body: {
                price: 'price_1HhsrZCXwqm9h4B3ajETRpqZ',
                locale: i18next.language.toLowerCase().split('-')[0],
            } as any
        }).then(async (result) => {
            return await result?.json();
        });
    },

    redirectToCheckout(res: { id: string }) {
        // console.log('About to redirectToCheckout', JSON.stringify(res));
        return StripePromise.then(Stripe => Stripe?.redirectToCheckout({ sessionId: res.id }))
    },

    get: (id: string) => {
        return fetcher_base(`/pay/stripe/sessions/${id}`).then(async (result) => {
            return await result?.json();
        });
    }
}