import moment from "moment";
import { AccountNode } from "service/Accounts.store";
import { Commodities } from 'service/Data.store';
import { Money } from "service/Money.util";
import { CTxReportMap, TxReport, TxReportMap } from "service/types/Types";
import { AccountAll } from './../Accounts.store';
import { Forex } from "./Forex";

export const TxForex = {
    getDefaultPrincipalCommodity(report: TxReport) {
        const rootAccountName = report.properties.account;
        return Object.keys(report.map).find(commodity => report.map[commodity][rootAccountName]) ?? 'USD'
    },

    makeAdditiveHierarchical: async (account: AccountNode, report: TxReport, out: TxReportMap) => {

        let input = new CTxReportMap(report.map);
        let output = new CTxReportMap(out);

        output.cloneAdditive(input, account);
    },

    additiveMergeByAccountCurrency: async (accounts: AccountAll, report: CTxReportMap, commodities: Commodities) => {
        //console.log('additiveMergeByAccountCurrency', JSON.stringify(report));

        let defaultGroup = moment().format('YYYY-MM-DD');

        let currencies = report.currencies();
        let groups = report.groups();

        let defaultGroupIndex = groups.findIndex(item => item === 'v');
        if (defaultGroupIndex >= 0) {
            groups[defaultGroupIndex] = defaultGroup;
        }

        //console.log('requesting conversion for', currencies, groups)
        let conversion = await Forex.getAll(groups, currencies);
        //console.log('conversion', conversion);

        for (let account of accounts.data ?? []) {
            let currency = account.commodity;
            for (let otherCurrency of currencies.filter(item => item !== currency)) {
                let groupObject = report.data?.[otherCurrency]?.[account.name] ?? {}
                Object.keys(groupObject).forEach((group: string) => {
                    let sourceUnits = groupObject[group];

                    let sourceMainFloat = Money.unitsToMainFloat(sourceUnits, otherCurrency, commodities)

                    let forexGroup = group === 'v' ? defaultGroup : group;
                    let rate = Forex.rate(conversion, forexGroup, otherCurrency, currency);
                    if (rate === undefined) {
                        throw new Error(`Conversion not found: ${group}, ${otherCurrency}, ${currency}.`);
                    }

                    let targetMainFloat = sourceMainFloat * rate;
                    let targetUnitsFloat = Money.mainToUnitsFloat(targetMainFloat, currency, commodities);

                    let path = { currency: currency, account: account.name, group: group };

                    //console.log('inc', path, targetUnitsFloat);
                    report.inc(path, targetUnitsFloat);
                })
            }
        }
    },

    mergeByConversion: async (report: TxReport, principalCommodity: string | null) => {
        //console.log('TxForex.mergeByConversion', report, principalCommodity);
        principalCommodity = principalCommodity ?? TxForex.getDefaultPrincipalCommodity(report)

        let { map } = report;

        const defaultDate = moment().format('YYYY-MM-DD');

        type conversion = {
            date: string,
            sourceCurrency: string,
            destinationCurrency: string,
            rate: number | undefined
        };

        let targetCommodities = Object.keys(report.map).filter(commodity => commodity !== principalCommodity);

        let targetDates = targetCommodities.reduce((acc, targetCommodity) => {
            Object.values(map[targetCommodity]).forEach(dateValue => Object.keys(dateValue).forEach(key => acc[key] = key))
            return acc;
        }, {} as any);
        targetDates = Object.keys(targetDates);
        for (const [i, value] of targetDates.entries()) {
            if (value === 'v') {
                targetDates[i] = defaultDate;
            }
        }

        //console.log('todos', targetCommodities, targetDates);

        let conv = await Forex.get({ base: principalCommodity, dates: targetDates, targets: targetCommodities })

        let principalCommodityMap = map[principalCommodity];

        targetCommodities.forEach(commodity => {
            let commodityMap = map[commodity];
            for (let [account, groupsByDate] of Object.entries(commodityMap)) {
                principalCommodityMap[account] ?? (principalCommodityMap[account] = {});
                for (let [group, value] of Object.entries(groupsByDate)) {
                    if (!principalCommodityMap[account][group]) {
                        principalCommodityMap[account][group] = 0;
                    }
                    let groupIndex = group === 'v' ? defaultDate : group;
                    let rate = conv[groupIndex].rates[commodity];
                    let convertedValue = (value / rate)

                    principalCommodityMap[account][group] += convertedValue;
                }
            }
            return report;
        });

        //console.log('report', report);

        return report;
    }
}