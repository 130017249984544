import { Config } from "Config"

type ForexRequest = {
    base: string,
    dates: string[],
    targets: string[]
}

type ForexResponse = {
    [date: string]: {
        base: string, date: string, rates: { [currency: string]: number }
    }
}

export const Forex = {
    rate(forexResponse: ForexResponse, date: string, currency1: string, currency2: string) {
        if (currency1 === currency2) {
            return 1;
        }
        let exchangeRates = forexResponse[date];
        if (!exchangeRates) {
            return undefined;
        }
        if (currency1 === exchangeRates.base) {
            return exchangeRates.rates[currency2];
        }
        if (currency2 === exchangeRates.base) {
            let exchangeRate = exchangeRates.rates[currency1];
            return exchangeRate === undefined ? undefined : 1 / exchangeRate;
        }

        let rateToBase1 = forexResponse[date].rates[currency1];
        let rateToBase2 = forexResponse[date].rates[currency2];

        if (rateToBase1 === undefined || rateToBase2 === undefined) {
            return undefined;
        }

        return rateToBase2 / rateToBase1;
    },

    async get(req: ForexRequest) {
        let targetsCsv = req.targets.join(',');
        let resArray = await Promise.all(
            req.dates.map(date => fetch(`${Config.api.url}/fx/${date}?base=${req.base}&symbols=${targetsCsv}`, {
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json()))
        )
        let ret = resArray.reduce((acc, current, index) => {
            acc[req.dates[index]] = current;
            return acc;
        }, {} as ForexResponse)
        console.log("forex ret", ret);
        return ret;
    },

    async getAll(dates: string[], currencies: string[]) {
        if (currencies.length < 2) {
            return {} as ForexResponse;
        }
        if (dates.length < 1) {
            return {} as ForexResponse;
        }
        return Forex.get({ base: currencies[0], dates: dates, targets: currencies })
    }
}