import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popover from '@material-ui/core/Popover';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { BalanceEntry } from 'component/BalanceEntry.co';
import DateNavPicker from 'component/DateNavPicker.co';
import { TxTableLarge } from 'component/tx/TxTableLarge.co';
import { TxTableSmall } from 'component/tx/TxTableSmall.co';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isAccountPositive } from 'service/Account.store';
import { AccountAll, AccountNode, AccountsService } from 'service/Accounts.store';
import { Commodities, ICommodity } from 'service/Data.store';
import { DataExport } from 'service/DataExport';
import { Globals } from 'service/Globals.store';
import { AppHistory, eventMap, useNavbar, useNavbarCallback, useSearchParams } from 'service/Page.store';
import { Service } from 'service/Service.store';
import { TxPagination } from 'service/TxPagination';
import './Account.style.css';

type AccountButtons = {
  accTimePrev: any;
  accTimeRange: any;
  accTimeNext: any;
  accMenuExtra: any;
  accMenuDownload: any;
  accMenuEdit: any;
  accMenuSub: any;
}

function getNavName(txPagination: TxPagination) {
  let m = txPagination?.begin();
  if (!m || txPagination?.granularity === 'all') {
    return 'All';
  }

  switch (txPagination?.granularity) {
    case 'year':
      return m.format('YYYY');
    case 'month':
      return m.format('YYYY-MM');
    case 'week':
      return m.format('YYYY-MM-DD');
    default:
      return 'All'
  }
}

function dataExportQif(commodities: Commodities, accounts: AccountAll, account: { data?: AccountNode } | undefined, txList: any, milestones: any) {
  if (!account?.data) return;
  let commodity = ICommodity.byAccountName(commodities, accounts, account.data.name);
  if (!commodity) return;

  let f = {
    acc: account.data.name.replace(/\//g, '‣').substring(1),
    com: account.data.commodity,
    span: milestones.begin.date + '…' + milestones.end.date
  }
  return {
    data: DataExport.qif(account, commodity, accounts, txList, milestones),
    filename: `${f.acc}〈${f.com}〉${f.span}.qif`
  }
}

export default function Account(props: any) {
  let { ledgerId, accountId } = props;

  let { span } = useSearchParams();

  let redirectRequired = false;
  if (!span) {
    span = moment(new Date()).startOf('month').format('YYYY-MM-DD') + "_month";
    redirectRequired = true;
  }

  useEffect(() => {
    if (redirectRequired) {
      AppHistory.push({ search: `?span=${span}` })
    }
  }, [span, redirectRequired]);

  const isBigScreen = useMediaQuery('(min-width:800px)')

  const { accounts, commodities } = Service.useLedgerContext(props);

  const account = AccountsService.byId(accounts, accountId);

  const commodity = account?.name ? ICommodity.byAccountName(commodities, accounts, account?.name) : undefined;
  const monetaryValueStyle = account ? (isAccountPositive(account) ? 'normal' : 'reversed') : undefined;

  useEffect(() => { Globals.updateAccount({ data: account }); return () => Globals.updateAccount(undefined) }, [account]);

  const [txSpanSelectAnchorEl, setTxSpanSelectAnchorEl] = useState<any>(null);
  const [txSpanOpen, setTxSpanOpen] = useState(false as boolean);

  const [milestones, setMilestones] = useState(null as any);

  const [txPagination, setTxPagination] = useState<TxPagination>(() => TxPagination.of(span));
  useEffect(() => {
    setTxPagination(TxPagination.of(span));
  }, [ledgerId, accountId, span]);

  const { data: transactions } = Service.useTransactions(ledgerId, accountId, span);

  const refDownloadQif = useRef(null as null | HTMLAnchorElement);

  const spanOpen = useCallback((element: any) => {
    setTxSpanOpen(true);
    let anchor = element.closest('div');
    setTxSpanSelectAnchorEl(anchor);
  }, []);

  const spanClose = useCallback(() => {
    setTxSpanOpen(false);
    (txPagination?.span() !== span) && AppHistory.push({ search: `?span=${txPagination?.span()}` })
  }, [txPagination, span]);

  const spanNext = useCallback((delta: number) => AppHistory.push({ search: `?span=${txPagination?.delta(delta)?.span()}` }), [txPagination])
  const spanIncrease = useCallback(() => spanNext(1), [spanNext])
  const spanDecrease = useCallback(() => spanNext(-1), [spanNext])

  const newTx = useCallback(() => AppHistory.push(`/ledgers/${ledgerId}/transactions/edit/new?account=${accountId}`), [ledgerId, accountId]);

  const updatePagination = useCallback((newValue: any) => {
    let newPagination = new TxPagination(newValue.date, newValue.granularity);
    //console.log('updatePagination', newPagination, txPagination)
    setTxPagination(newPagination);
  }, []);

  let onDownload = useCallback(() => {
    let dxq = dataExportQif(commodities, accounts, { data: account }, transactions?.data?.list, milestones);
    let current = refDownloadQif?.current;

    if (!dxq || !current) return;

    current.href = URL.createObjectURL(new Blob(dxq.data, { type: 'text/plain;charset=utf-8' }));
    current.download = dxq.filename;
    current.click();
  }, [refDownloadQif, commodities, accounts, account, transactions, milestones])

  let [, setNavbar] = useNavbar<AccountButtons>();
  useEffect(() => {
    const timeNavMode = (!txPagination.granularity || txPagination.granularity === 'all') ? 'disabled' : 'enabled';
    const navName = getNavName(txPagination);

    setNavbar([
      { key: 'accTimePrev', icon: <NavigateBeforeIcon />, mode: timeNavMode },
      { key: 'accTimeRange', text: navName, icon: <DateRangeIcon /> },
      { key: 'accTimeNext', icon: <NavigateNextIcon />, mode: timeNavMode },
      {
        key: 'accMenuExtra', icon: <MoreVertIcon />,
        items: [
          { key: 'accMenuDownload', icon: <CloudDownloadIcon /> },
          { key: 'accMenuEdit', icon: <EditIcon /> },
          { key: 'accMenuSub', icon: <AddIcon /> }
        ]
      }])
  }, [ledgerId, accountId, setNavbar, txPagination]);

  let navbarCallback = useMemo(() => eventMap<AccountButtons>({
    accMenuExtra: () => { },
    accTimePrev: spanDecrease,
    accTimeNext: spanIncrease,
    accTimeRange: spanOpen,
    accMenuDownload: onDownload,
    accMenuEdit: () => AppHistory.push(`/ledgers/${ledgerId}/accounts/edit/${accountId}`),
    accMenuSub: () => AppHistory.push(`/ledgers/${ledgerId}/accounts/edit/new?parentAccount=${accountId}`)
  }), [ledgerId, accountId, spanDecrease, spanIncrease, spanOpen, onDownload]);

  useNavbarCallback(navbarCallback);

  useEffect(() => {
    if (!accountId || !transactions?.data) return;

    let firstTransactionDate = transactions?.data?.list?.[0]?.splits?.[0].ts;
    let lastTransactionDate = transactions?.data?.list?.slice(-1)?.[0]?.splits?.[0].ts;

    let milestones = {
      hasContent: true,
      begin: {
        date:
          transactions?.data?.span?.begin ??
          (firstTransactionDate ? moment(firstTransactionDate).format('YYYY-MM-DD') : null),
        value: transactions?.data?.balance?.begin
      },
      end: {
        date:
          transactions?.data?.span?.end ??
          (lastTransactionDate ? moment(lastTransactionDate).format('YYYY-MM-DD') : null),
        value: transactions?.data?.balance?.end
      }
    }

    setMilestones(milestones);
    //setBalance(transactions.data.balance.end);
  }, [accountId, transactions]);

  const tableParams = {
    ledgerId: ledgerId,
    accounts: accounts,
    commodities: commodities,
    commodity: commodity,
    account: account,
    style: monetaryValueStyle,
    txList: transactions?.data?.list
  }
  const txListDisplayable = monetaryValueStyle && account && accounts && commodities?.data && commodity?.symbol && commodity?.units && tableParams.txList ? true : false;

  // console.log('About to render', tableParams, txPagination, txListDisplayable);
  return (
    <div className="Account-root">
      <div className="Account-downloadQIF">
        <a ref={refDownloadQif} href='/'>.</a>
      </div>
      {!txListDisplayable
        ? <LinearProgress className="app-fadein" /> :
        <div className="transactionsContainer">
          <BalanceEntry {...milestones?.begin}
            desc={"Initial balance"}
            commodity={commodity}
            style={monetaryValueStyle} />

          <div>
            {isBigScreen && <TxTableLarge {...tableParams} />}
            {!isBigScreen && <TxTableSmall {...tableParams} />}
          </div>

          <div className="transactionsListSeparator"></div>

          <BalanceEntry {...milestones?.begin}
            desc={"Initial balance"}
            commodity={commodity}
            style={monetaryValueStyle} />

          <BalanceEntry {...milestones?.end}
            desc={"Final balance"}
            commodity={commodity}
            style={monetaryValueStyle} />
          {<Popover
            open={txSpanOpen}
            anchorEl={txSpanSelectAnchorEl}
            onClose={spanClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <DateNavPicker value={txPagination} onChange={updatePagination} />
          </Popover>
          }
        </div >
      }
      <Fab className='Account-fab' color="primary" aria-label="add" onClick={newTx}>
        <AddIcon />
      </Fab>
    </div>
  );
}

//Account.whyDidYouRender = true;