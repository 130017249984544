
export function preprocessTransactions(res: any) {
    let balance = res.data.balance.begin;
    res.data.list.forEach((tx: any) => {
        balance += tx.splits[0].value;
        tx.splits[0].balance = balance;
    });
    res.data.balance.end = balance;
    return res;
}

export function isAccountPositiveByName(accountName: string) {
    const positiveAccounts = ['/assets', '/expenses'];
    return positiveAccounts.findIndex(item => item === accountName || accountName.startsWith(`${item}/`)) >= 0
}

export function isAccountPositive(account: any) {
    const positiveAccounts = ['/assets', '/expenses'];

    if (account.name === '/') {
        return true;
    }

    for (let i = 0; i < positiveAccounts.length; i++) {
        let positiveAccount = positiveAccounts[i];

        if (account.name === positiveAccount) {
            return true;
        }
        if (account.name?.startsWith(`${positiveAccount}/`)) {
            return true;
        }
    }

    return false;
}
