import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import React, { useEffect, useMemo } from 'react';
import { AppHistory, eventMap, globalParams, useNavbar, useNavbarCallback } from 'service/Page.store';
import { Service } from 'service/Service.store';

export default function Ledgers() {
  const { data: ledgers } = Service.useLedgers();

  useEffect(() => {
    globalParams.update({});
  }, []);

  type Nav = { navNewLedger: null };

  const [, setNavbar] = useNavbar<Nav>();

  useEffect(() => setNavbar([
    { key: 'navNewLedger', icon: <AddIcon /> }
  ]), [setNavbar]);

  useNavbarCallback(useMemo(() => eventMap<Nav>({
    navNewLedger: () => AppHistory.push(`/ledgers/new`)
  }), []))

  return (
    <div>
      {ledgers?.data && <List>
        {ledgers?.data.map((ledger: any) =>
          <ListItem button key={ledger.id} onClick={() => AppHistory.push(`/ledgers/${ledger.id}/accounts`)}>
            <ListItemAvatar>
              <Avatar> <MenuBookIcon /> </Avatar>
            </ListItemAvatar>
            <ListItemText primary={ledger.name} secondary={ledger.description} />
          </ListItem>
        )}
      </List>
      }
    </div>
  );
}
