import moment from "moment";
import { Money } from "./Money.util";

function displayValue(value: number, commodity: any) {
    let numberOfDecimals = Money.unitsToNumberOfDecimals(commodity.units);
    let displayString = Money.storableIntegerToString(value, numberOfDecimals);
    return `${displayString.integer}.${displayString.decimals}`
}
function DataExportQif(account: any, commodity: any, accounts: any, txList: any[], milestones: any) {
    // console.log('DataExportQif', arguments);
    let lines = [] as string[];

    lines.push(`!Clear:AutoSwitch
!Account
N${account.data.name} ${account.data.commodity}
^`);

    let firstDate = null as null | string;
    let lastDate = null as null | string;

    txList.forEach((tx, index) => {
        var splitMe = tx.splits.find((split: any) => split.account_id === account.data.id)
        var splitOther = tx.splits.find((split: any) => split.account_id !== account.data.id)

        var otherAccountName = accounts.indexById[splitOther.account_id].name;

        lastDate = splitMe.ts;
        firstDate = firstDate ?? splitMe.ts;

        let padToFour = (number: number) => number <= 9999 ? `000${number}`.slice(-4) : number;

        lines.push(`
!Type:Bank
D${moment(splitMe.ts).format('YYYY-MM-DD')}
P${otherAccountName}
M${tx.description}
T${displayValue(splitMe.value, commodity)}
N${padToFour(index + 1)}
^`)
    });

    firstDate && lines.push(`
!Type:Bank
D${moment(firstDate).format('YYYY-MM-DD')}
P --- Balance begin: ${displayValue(milestones.begin.value, commodity)} 
T0
N0000
^`);
    lastDate && lines.push(`
!Type:Bank
D${moment(lastDate).format('YYYY-MM-DD')}
P --- Balance end: ${displayValue(milestones.end.value, commodity)} 
T0
N9999
^`);

    return lines;
}
export const DataExport = {
    qif: DataExportQif
}