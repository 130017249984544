import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useState } from "react";
import { CommoditiesMap } from "service/Data.store";
import { MTextField } from "./ui/AppUi";

export const SelectCommodity = React.memo((props:
    {
        name: string,
        value: string,
        commodities: CommoditiesMap,
        disabled?: boolean,
        onChange: (name: string, value: string) => void
    }) => {
    const { value, commodities, onChange, name, disabled } = props;

    const renderInput = useCallback((params) => {
        return (<MTextField {...params}
            disabled={disabled}
            name={name} className="acc" label="Currency" />)
    }, [name, disabled]);

    const [commoditiesOptions] = useState(() => Object.keys(commodities))
    const getOptionLabel = useCallback(option => option, []);
    const internalOnChange = useCallback((e: any, v: any) => onChange(name, v), [onChange, name]);

    return (<Autocomplete
        disabled={disabled}
        value={value}
        options={commoditiesOptions}
        getOptionLabel={getOptionLabel}
        className="acc"
        autoSelect
        onChange={internalOnChange}
        renderInput={renderInput}
    />);
})