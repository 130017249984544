import { makeStyles } from '@material-ui/core/styles';
import deepEqual from 'deep-equal';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from "react-router-dom";
import { BehaviorSubject, Subject } from 'rxjs';
import { NavbarButtonType } from 'service/ux/ActionBar';
import { LiveObject } from './LiveObject';
import { FilteredBehaviorSubject } from './RxEffects';

const AppHistory = require("history").createBrowserHistory()

const appUpdateSubject = new FilteredBehaviorSubject<any | null>({ filterOut: deepEqual, name: 'appUpdateSubject' }, null);

const drawerSubject = new FilteredBehaviorSubject<boolean>({ filterOut: deepEqual, name: 'drawerSubject' }, false);

export type NavbarState<T> = NavbarButtonType<T>[] | undefined;
const actionsSubject = new BehaviorSubject<NavbarState<any>>(undefined);

export type NavbarEvent<T> = {
  key: keyof T,
  element: any
}

export const NavbarEventSubject = new Subject<NavbarEvent<any>>();

export function eventMap<T>(map: { [K in keyof T]: (element?: any) => any }) {
  return (navbarEvent: NavbarEvent<T>) => map[navbarEvent.key]?.(navbarEvent.element);
}

export const useNavbarCallback = function <T>(callback?: (navbarEvent: NavbarEvent<T>) => void) {
  useEffect(() => {
    let s = callback && NavbarEventSubject.subscribe(callback);
    return () => s?.unsubscribe();
  }, [callback]);
}

export const useNavbar = function <T>(/*buttons?: NavbarState<T> | ((arg: NavbarState<T>) => NavbarState<T>)*/):

  [
    NavbarButtonType<T>[] | undefined,
    (buttons: NavbarState<T> | ((arg: NavbarState<T>) => NavbarState<T>)) => void
  ] {

  let setNavbar = useCallback((buttons: NavbarState<T> | ((arg: NavbarState<T>) => NavbarState<T>)) => {
    //console.log('useNavbar.setNavbar', buttons);
    let nextButtons = undefined;
    if (buttons) {
      if (buttons instanceof Function) {
        //console.log('Buttons Function')
        nextButtons = buttons(actionsSubject.value);
      } else {
        //console.log('Buttons Value')
        nextButtons = buttons;
      }
    }
    //console.log('Next Buttons', nextButtons);
    if (nextButtons !== actionsSubject.value) {
      //console.log('Navbar actual next', nextButtons);
      actionsSubject.next(nextButtons)
    }
  }, []);

  //if (buttons) {
  //  setNavbar(buttons);
  //}

  useEffect(() => {
    return () => actionsSubject.next([]);
  }, []);

  return [actionsSubject.value, setNavbar];
}

const installPromptSubject = new BehaviorSubject<any>(null);

const drawerWidth = 240;

export function required(...args: any[]) {
  return args.findIndex(item => item === undefined || item === null) === -1;
}

export function usePublishingParams<Params extends { [K in keyof Params]?: string } = {}>(
  publisherName?: string
) {
  let params = useParams<Params>();
  globalParams.update(params, publisherName);
  return params;
}

/*
export function useNav<T>(fn: () => NavbarButtonType<T>[] | undefined = donothing, deps?: DependencyList) {
  let [v, s] = useBehaviorSubject(actionsSubject);
  let cb = useCallback(fn, [...(deps ?? [])]);

  useEffect(() => {
    let newValue = cb();
    if (newValue !== undefined) {
      s(newValue);
    }
    return () => s([])
  },
    // eslint-disable-next-line
    [cb, s, ...(deps ?? [])]);

  return [v, s] as [NavbarButtonType<T>[], (v: NavbarButtonType<T>[]) => void];
}
*/

export const globalParams = new LiveObject({} as any, {
  ignorer: deepEqual,
  logger: () => { },//console.log,
  name: 'globalParams'
});

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      display: 'none !important',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  }
}));

interface SearchParams { [key: string]: string | undefined };

function getObjectFromLocation(location: any): SearchParams {
  let searchParamsObject = new URLSearchParams(location.search)

  let ret: SearchParams = {};
  for (const [key, value] of Array.from(searchParamsObject.entries())) {
    ret[key] = value;
  }

  return ret;
}

function useSearchParams(): SearchParams {
  let location = useLocation();

  let [searchParamsState, setSearchParamState] = useState(() => getObjectFromLocation(location));

  useEffect(() => {
    let ret = getObjectFromLocation(location);
    setSearchParamState(ret);
  }, [location]);

  return searchParamsState;
}

export function SearchParams<T>(obj: T) {
  return Object.keys(obj).map(key => `${key}=${(obj as any)[key].toString()}`).join('&')
}

export { useSearchParams, installPromptSubject, drawerSubject, useStyles, actionsSubject, AppHistory, appUpdateSubject };

