import MomentUtils from "@date-io/moment";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MonetaryValueField, { MVFProps } from "component/MonetaryValueField.co";
import React from "react";

export const MKeyboardDatePicker = React.memo((props: any) =>
    <MuiPickersUtilsProvider utils={MomentUtils} >
        <KeyboardDatePicker {...props} />
    </MuiPickersUtilsProvider>
);

export const MTextField = React.memo((props: any) => <TextField {...props} />)
export const MAutocomplete = React.memo((props: any) => <Autocomplete {...props} />)
export const MMonetaryValueField = React.memo((props: MVFProps) => <MonetaryValueField {...props} />)
