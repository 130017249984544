import * as d3 from "d3";
import { BaseType, Transition } from "d3";
import { AccountsColorizer } from "service/ux/AccountsColorizer";
import { isAccountPositiveByName } from "../Account.store";
import { Money } from "../Money.util";
import { TreeTransformer } from "./TreeTransformer";

const partition = (data: any) => {
    const root = d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a: any, b: any) => b.value - a.value);
    return d3.partition()
        .size([2 * Math.PI, root.height + 1])
        (root);
}

export const D3Sunburst = {

    render: ({ accounts, rootSvgElement, commodities, reportTx }: any) => {
        //console.log('D3Sunburst.render', accounts, reportTx);
        let dataArg = {
            data: TreeTransformer.transform(commodities, reportTx?.data)
        }

        let rect = rootSvgElement?.getBoundingClientRect();

        let data = dataArg.data ?? {}

        const shiftTop = 10;

        if (!data?.children) {
            data.children = [];
        }
        //console.log('dataArg', dataArg);
        //const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1))
        const color2 = (name: string) => {
            //console.log('color2', name);
            let retColor = AccountsColorizer.colorOf(accounts, name)
            //console.log('returning retColor', retColor);
            return retColor;
        }
        const formatData = (itemData: any) => {
            //console.log('FormatData', itemData);
            const style = isAccountPositiveByName(itemData.fqName) ? 'normal' : 'reversed';

            const symbol = commodities[itemData.commodity][0];
            const units = commodities[itemData.commodity][2];
            const td = Money.toDisplayA(itemData.aggregateValue, units, style);
            return `${symbol} ${td.integer}.${td.decimals}`
        }
        const format = (item: any) => {
            return formatData(item.data);
        }
        const width = Math.min(rect.height, rect.width) - 10;
        const radius = width / 6
        const arc = d3.arc()
            .startAngle((d: any) => d.x0)
            .endAngle((d: any) => d.x1)
            .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
            .padRadius(radius * 1.5)
            .innerRadius((d: any) => d.y0 * radius)
            .outerRadius((d: any) => Math.max(d.y0 * radius, d.y1 * radius - 1));

        const root = partition(data);
        root.each((d: any) => d.current = d);

        const svg = d3.select(rootSvgElement);
        //svg.selectAll("*").remove();

        const textFqNameTop = svg.append('text')
            .attr("dx", 10)
            .attr("dy", shiftTop)
            .text(data.fqName)

        const textValueMid = svg.append('text')
            .attr("class", "d3_star_mid_value")
            .attr("transform", `translate(${width / 2}, ${width / 2 + shiftTop})`)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .attr("dy", 20)
            .text(formatData(data));

        const textNameMid = svg.append('text')
            .attr("transform", `translate(${width / 2}, ${width / 2 + shiftTop})`)
            .attr("text-anchor", "middle")
            .attr("dominant-baseline", "middle")
            .text(data.fqName.split('/').slice(-1)[0]);

        const g = svg.append("g")
            .attr("class", "d3_star_root")
            .attr("transform", `translate(${width / 2}, ${width / 2 + shiftTop})`);

        const path = g.append("g")
            .selectAll("path")
            .data(root.descendants().slice(1))
            .join("path")
            .attr("fill", (d: any) => color2(d.data.fqName))
            //.attr("fill", (d: any) => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
            //.attr("fill-opacity", (d: any) => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
            .attr("fill-opacity", (d: any) => arcVisible(d.current) ? 1 : 0)
            .attr("d", (d: any) => arc(d.current))
            .on("mouseover", mouseover)
            .attr("class", "d3_star_slice")

        path.filter((d: any) => d.children)
            .style("cursor", "pointer")
            .on("click", clicked)

        path.append("title")
            .text(d => `${d.ancestors().map((d: any) => d.data.name).reverse().join("/")}\n    ${format(d)}`);

        const label = g.append("g")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .style("user-select", "none")
            .selectAll("text")
            .data(root.descendants().slice(1))
            .join("text")
            .attr("dy", "0.35em")
            .attr("fill-opacity", (d: any) => +labelVisible(d.current))
            .attr("transform", (d: any) => labelTransform(d.current))
            .text((d: any) => d.data.name);

        const parent = g.append("circle")
            .datum(root)
            .attr("r", radius)
            .attr("fill", "none")
            .attr("pointer-events", "all")
            .on("click", clicked)
            .on("mouseover", mouseover)

        function mouseover(p: any) {
            //console.log("On mouse over", p.data.fqName);
        }

        function clicked(p: any) {
            textFqNameTop.text(p.data.fqName);
            textValueMid.text(format(p));//.data.aggregateValue);
            textNameMid.text(p.data.fqName.split('/').slice(-1)[0])

            parent.datum(p.parent || root);

            root.each((d: any) => d.target = {
                x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                y0: Math.max(0, d.y0 - p.depth),
                y1: Math.max(0, d.y1 - p.depth)
            });

            const t = g.transition().duration(750) as unknown as Transition<BaseType, any, any, any>;

            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween("data", (d: any) => {
                    const i = d3.interpolate(d.current, d.target);
                    return (t: any) => d.current = i(t);
                })
                .filter(function (d: any) {
                    return (this as any).getAttribute("fill-opacity") || arcVisible(d.target);
                })
                //.attr("fill-opacity", (d: any) => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attr("fill-opacity", (d: any) => arcVisible(d.target) ? 1 : 0)
                .attrTween("d", (d: any) => { return () => arc(d.current) as string });

            label.filter(function (d: any) {
                return (this as any).getAttribute("fill-opacity") || labelVisible(d.target);
            }).transition(t)
                .attr("fill-opacity", (d: any) => +labelVisible(d.target))
                .attrTween("transform", (d: any) => () => labelTransform(d.current));
        }

        function arcVisible(d: any) {
            return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
        }

        function labelVisible(d: any) {
            return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
        }

        function labelTransform(d: any) {
            const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
            const y = (d.y0 + d.y1) / 2 * radius;
            return `rotate(${x - 90}) translate(${y}, 0) rotate(${x < 180 ? 0 : 180})`;
        }
    },
}