import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useCallback, useState } from "react";
import { AccountNode } from "service/Accounts.store";

export const SelectAccount = React.memo((props:
    {
        disabled?: boolean,
        name: string,
        value: string,
        accounts: AccountNode[],
        label?: string,
        onChange: (name: string, value: string) => void
    }) => {
    const { value, accounts, onChange, name, disabled, label } = props;

    const renderInput = useCallback((params) => <TextField {...params}
        disabled={disabled}
        label={label ?? 'Account'} size="small" variant="outlined" margin="dense" />, [disabled, label]);


    const [options] = useState(() => accounts.map(account => account.name))
    const getOptionLabel = useCallback(option => option, []);
    const internalOnChange = useCallback((e: any, v: any) => onChange(name, v), [onChange, name]);

    return (<Autocomplete
        disabled={disabled}
        value={value}
        options={options}
        getOptionLabel={getOptionLabel}
        className="acc"
        autoSelect
        onChange={internalOnChange}
        renderInput={renderInput}
    />);
})