import AddBoxIcon from '@material-ui/icons/AddBox';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import produce from 'immer';
import React from 'react';
import { NavbarButtonType } from './ActionBar';

export interface CrudButtonKind {
    crudNew: null;
    crudSave: null;
    crudEdit: null;
    crudCancel: null;
    crudDelete: null;
}

/*
export type CrudButton<T> = {
    key: T,
    icon: any,
    mode?: NavbarButtonTypeMode,
    text?: string
}
*/

export type CrudButtons<T> = NavbarButtonType<T>[] | undefined;

const ButtonNew: NavbarButtonType<CrudButtonKind> = { key: 'crudNew', icon: <AddBoxIcon />, mode: 'disabled' };
const ButtonSave: NavbarButtonType<CrudButtonKind> = { key: 'crudSave', icon: <SaveIcon />, mode: 'disabled' };
const ButtonEdit: NavbarButtonType<CrudButtonKind> = { key: 'crudEdit', icon: <EditIcon />, mode: 'disabled' };
const ButtonDelete: NavbarButtonType<CrudButtonKind> = { key: 'crudDelete', icon: <DeleteIcon />, mode: 'disabled' };
const ButtonCancel: NavbarButtonType<CrudButtonKind> = { key: 'crudCancel', icon: <CancelIcon />, mode: 'disabled' };

export type CrudPatch<T> = Partial<{ [K in keyof T]: Partial<NavbarButtonType<T>> }>;

export function functionalReducer<T>(state: T, action: (old: T) => T) {
    return action(state);
}

export function CrudNew<T>(old: CrudButtons<T>): CrudButtons<T> {
    return old;
}

function fillComplimentary<T extends CrudButtonKind>(patches: CrudPatch<T>): CrudPatch<T> {
    //console.log('patches1', JSON.stringify(patches))
    var complimentaryPairs: (keyof T)[][] = [['crudSave', 'crudEdit'], ['crudDelete', 'crudCancel']]

    for (let cp of complimentaryPairs) {
        for (let i = 0; i <= 1; i++) {
            //console.log('patches', cp[i], cp[1 - i]);
            if (patches[cp[i]]?.mode && patches[cp[i]]?.mode !== 'hidden') {
                patches[cp[1 - i]] = { ...patches[cp[1 - i]], mode: 'hidden' }
            }
        }
    }
    //console.log('patches2', JSON.stringify(patches))
    return patches;
}

export function navPatcher<T>(patch: CrudPatch<T>) {
    return (old: CrudButtons<T>) => produce<CrudButtons<T>>(old, (draft) => {
        //console.log('CrudUtil.editing', old);
        //let filledPatch = fillComplimentary<T>(patch);
        old?.forEach((b, i) => {
            //console.log('about to assign to', b);
            draft?.[i] && Object.assign(draft[i], patch[b.key as keyof T])
            //console.log('assignement complete');
        })
    })
}

export function patcher<T extends CrudButtonKind>(patch: CrudPatch<T>) {
    return (old: CrudButtons<T>) => produce<CrudButtons<T>>(old, (draft) => {
        //console.log('CrudUtil.editing', old);
        let filledPatch = fillComplimentary<T>(patch);
        old?.forEach((b, i) => {
            //console.log('about to assign to', b);
            draft?.[i] && Object.assign(draft[i], filledPatch[b.key as keyof T])
            //console.log('assignement complete');
        })
    })
}

export class CrudUtil {
    static editing<T extends CrudButtonKind>({ dirty, valid, hasId }: { dirty?: boolean, valid?: boolean, hasId?: any }) {
        return patcher({
            'crudNew': { mode: dirty ? 'disabled' : (hasId ? 'enabled' : 'disabled') },
            'crudSave': { mode: (dirty && valid) ? 'enabled' : 'disabled' },
            'crudCancel': { mode: dirty ? 'enabled' : 'hidden' },
            'crudDelete': { mode: dirty ? 'hidden' : ((hasId && !dirty) ? 'enabled' : 'disabled') }
        } as CrudPatch<T>)
    }

    static editRequired<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return old;
    }

    static defaultNew<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return old;
    }

    static editRequested<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return old;
    }

    static newRequested<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return old;
    }

    static allowNew<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return patcher({
            'crudNew': { mode: 'enabled' }
        } as CrudPatch<T>)(old);
    }

    static progress = {
        crudSave: CrudUtil.saveProgress,
        crudDelete: CrudUtil.deleteProgress
    } as { [k in keyof CrudButtonKind]: any };
    static success = {
        crudSave: CrudUtil.saveSuccessful,
        crudDelete: CrudUtil.deleteSuccessful
    } as { [k in keyof CrudButtonKind]: any };
    static failed = {
        crudSave: CrudUtil.saveFailed,
        crudDelete: CrudUtil.deleteFailed
    } as { [k in keyof CrudButtonKind]: any };

    static saveProgress<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return patcher({
            'crudNew': { mode: 'disabled' },
            'crudSave': { mode: 'progress' },
            'crudCancel': { mode: 'disabled' },
        } as CrudPatch<T>)(old);
    }
    static saveSuccessful<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return patcher({
            'crudNew': { mode: 'enabled' },
            'crudEdit': { mode: 'enabled' },
            'crudDelete': { mode: 'disabled' }
        } as CrudPatch<T>)(old);
    }
    static saveFailed<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return patcher({
            'crudNew': { mode: 'disabled' },
            'crudSave': { mode: 'enabled' },
            'crudCancel': { mode: 'enabled' }
        } as CrudPatch<T>)(old);
    }

    static cancelProgress<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return old;
    }
    static cancelSuccessful<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return old;
    }

    static deleteProgress<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return patcher({
            'crudNew': { mode: 'disabled' },
            'crudSave': { mode: 'disabled' },
            'crudDelete': { mode: 'progress' },
        } as CrudPatch<T>)(old);
    }
    static deleteSuccessful<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return patcher({
            'crudNew': { mode: 'enabled' },
            'crudSave': { mode: 'disabled' },
            'crudDelete': { mode: 'disabled' },
        } as CrudPatch<T>)(old);
    }
    static deleteFailed<T extends CrudButtonKind>(old?: CrudButtons<T>) {
        return patcher({
            'crudNew': { mode: 'enabled' },
            'crudSave': { mode: 'disabled' },
            'crudDelete': { mode: 'enabled' },
        } as CrudPatch<T>)(old);
    }

    static New<T extends CrudButtonKind>(old?: CrudButtons<T>): CrudButtons<T> {
        return [{ ...ButtonNew, mode: 'disabled' }, ButtonSave, { ...ButtonEdit, mode: 'hidden' }, ButtonDelete, { ...ButtonCancel, mode: 'hidden' }];
    }

    static Default<T extends CrudButtonKind>(old: CrudButtons<T>): CrudButtons<T> {
        return patcher({
            'crudNew': { mode: 'disabled' },
            'crudSave': { mode: 'disabled' },
            'crudDelete': { mode: 'disabled' }
        } as CrudPatch<T>)(old);
    }
}