import moment, { unitOfTime } from 'moment';

export class TxPagination {
  key: string;
  constructor(public date: Date, public granularity: string) {
    this.date = this.date ?? new Date();
    this.key = date + granularity;
  }

  static of(span: string | undefined): TxPagination {
    if (!span || span === 'all') {
      return new TxPagination(new Date(), 'all');
    }
    let parts = span.split('_');
    return new TxPagination(moment(parts[0]).toDate(), parts[1])
  }

  span() {
    if (this.date === null || this.granularity === null) {
      return undefined;
    }
    if (this.granularity === 'all') {
      return 'all';
    }
    let begin = moment(this.date).startOf(this.granularity as unitOfTime.Base).format('YYYY-MM-DD');
    return `${begin}_${this.granularity}`;
  }

  delta(count: number): TxPagination {
    if (this.date === null || this.granularity === null) {
      return new TxPagination(this.date, this.granularity);
    }
    let newDate = moment(this.date).startOf(this.granularity as unitOfTime.Base).add(count, this.granularity as unitOfTime.Base).toDate();
    return new TxPagination(newDate, this.granularity);
  }

  next(): TxPagination {
    return this.delta(1);
  }

  prev(): TxPagination {
    return this.delta(-1);
  }

  begin(): moment.Moment | null {
    if (!this.date || !this.granularity) {
      return null;
    }

    return moment(this.date).startOf(this.granularity as unitOfTime.Base);
  }

  end(): moment.Moment | null {
    if (!this.date || !this.granularity) {
      return null;
    }

    return moment(this.date).endOf(this.granularity as unitOfTime.Base);
  }
}
