import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { AccountNameBrowser } from 'component/AccountNameBrowser.co';
import React from 'react';
import { Globals } from 'service/Globals.store';
import { AppHistory, drawerSubject, useStyles } from 'service/Page.store';
import './Navbar.co.css';

export const Navbar = (props: any) => {
  const classes = useStyles();

  const [accounts] = Globals.useAccounts();
  const [ledger] = Globals.useLedger();
  const [account] = Globals.useAccount();

  return (
    <AppBar position='relative'>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => drawerSubject.next(true)}
          className={classes.menuButton}
        >
          <img alt="menu" src="/uc2-logo-menu.png" />
        </IconButton>

        <div className="where">
          {ledger?.data && <div className="where-ledger">{ledger?.data?.name}</div>}
          {account?.data && ledger?.data && account?.data?.name &&
            <div className="where-account">
              <AccountNameBrowser ledger={ledger?.data} accounts={accounts} name={account?.data?.name} />
            </div>
          }
        </div>
        <div className="spacer"></div>
        <div>
          {ledger?.data &&
            <IconButton aria-label="delete" color="default" onClick={() => AppHistory.push(`/ledgers/${ledger?.data?.id}/accounts`)}>
              <AccountTreeIcon fontSize="small" />
            </IconButton>
          }
        </div>
      </Toolbar>
    </AppBar>
  );
};

//Navbar.whyDidYouRender = true;
