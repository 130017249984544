import React, { useEffect, useState } from "react";
import './AccountName.co.css';

export default function AccountName(props: any) {
  const { name, ledger } = props;

  const [parts, setParts] = useState<{ name: string, fullName: string }[]>([]);

  useEffect(() => {
    if (!name) {
      return;
    }

    const parts = (name || '').split('/').slice(1).reduce(
      (acc: { name: string, fullName: string }[], value: string) => {

        let previous = acc.length === 0 ? null : acc[acc.length - 1];

        let current = {
          name: value,
          fullName: (previous?.fullName || '') + "/" + value
        }
        acc.push(current);
        return acc
      }, []);

    setParts(parts);

  }, [name, ledger])

  return (
    <span className="AccountName-root">
      {
        parts.map((part) =>
          <span key={part.fullName} aria-controls="fade-menu" aria-haspopup="true" className="AccountName-part">
            {part.name}
          </span>
        )
      }
    </span >
  );
}
