import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import { Commodity } from 'service/Data.store';
import { Money } from 'service/Money.util';

type MVFEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

export type MVFMode = 'numeric' | 'module-negative' | 'module-positive';

export type MVFProps = {
  [key: string]: any,
  commodity: Commodity,
  onChange?: (e: MVFEvent) => any,
  mode?: MVFMode,
  value: string
}

function positivize(value: string, mode: MVFMode) {
  return mode === 'numeric' ? value : value?.replace(/-/g, '');
}

export default function MonetaryValueField(props: MVFProps) {
  const { value, commodity, onChange, mode = 'numeric' } = props;

  let [settings, setSettings] = useState({ symbol: '?', stepsAsString: '0.01', numberOfDecimals: 2 });

  let [inputProps, setInputProps] = useState<any>(undefined);
  let [capitalInputProps, setCapitalInputProps] = useState({} as any);

  useEffect(() => {
    if (mode === 'numeric') {
      setInputProps({ type: 'number', step: settings.stepsAsString })
    } else {
      setInputProps({ type: 'number', step: settings.stepsAsString, min: 0 })
    }
  }, [mode, settings])

  useEffect(() => {
    setCapitalInputProps({ startAdornment: <InputAdornment position="start">{settings.symbol}</InputAdornment> })
  }, [settings]);


  useEffect(() => {
    if (!commodity) {
      return;
    }

    let numberOfDecimals = Money.unitsToNumberOfDecimals(commodity.units);
    let stepsAsString = Money.stepForNumberOfDecimals(numberOfDecimals);

    let newSettings = { symbol: commodity.symbol, stepsAsString: stepsAsString, numberOfDecimals: numberOfDecimals };

    setSettings(old => old?.symbol === newSettings.symbol ? old : newSettings);
  }, [commodity])


  const myOnChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let newValue = event?.target.value;
    if (newValue === '') {
      return onChange?.(event);
    }

    //console.log('---> Processing', event.target.value, settings);
    let newValue2 = Money.stringWithMaxNumberOfDecimals(event.target.value, settings.numberOfDecimals);
    //console.log('---> New value', newValue2)
    newValue2 = ((mode === 'module-negative') ? "-" : "") + newValue2;

    event.target.value = newValue2;

    onChange?.(event);
  }, [onChange, settings, mode]);

  const myOnKeyPress = useCallback((e: any) => {
    if ((e.charCode === 45) && (mode !== 'numeric')) {
      e.preventDefault();
      return false;
    }
    return true;
  }, [mode]);

  return (
    <>
      {inputProps && <TextField
        className="val"
        inputProps={inputProps}
        {...props}
        value={positivize(value, mode)}
        size={"small"} variant={"outlined"}
        onChange={myOnChange}
        onKeyPress={myOnKeyPress}
        InputProps={capitalInputProps} />}
    </>
  );
}

MonetaryValueField.whyDidYouRender = true