import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import AccountTree from 'component/AccountTree.co';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppHistory, eventMap, useNavbar, useNavbarCallback } from 'service/Page.store';
import { TxForex } from 'service/reports/TxForex';
import { Service } from 'service/Service.store';
import { CTxReportMap, TxReportMap } from 'service/types/Types';


type Nav = {
  navAccountNew: null,
  navAccountEdit: null,
  navAccountNewTx: null
}
export default function Accounts(props: any) {
  let { ledgerId } = props;

  const { accounts, commodities } = Service.useLedgerContext(props);
  //const { data: accounts } = Service.useAccounts(ledgerId);
  //const { data: commodities } = Service.useCommodities();

  const { data: balances } = Service.useBalancesTransitive(ledgerId);
  const { data: report } = Service.useReportLive(ledgerId);

  const [reportAdditive, setReportAdditive] = useState(undefined as TxReportMap | undefined);


  const [, setNavbar] = useNavbar<Nav>();

  useNavbarCallback(useMemo(() => eventMap<Nav>({
    navAccountNew: () => AppHistory.push(`/ledgers/${ledgerId}/accounts/edit/new`),
    navAccountEdit: () => AppHistory.push(`/ledgers/${ledgerId}/edit`),
    navAccountNewTx: () => AppHistory.push(`/ledgers/${ledgerId}/transactions/edit/new`)
  }), [ledgerId]))

  useEffect(() => setNavbar([
    { key: 'navAccountNew', icon: <AddIcon /> },
    { key: 'navAccountEdit', icon: <EditIcon /> },
    { key: 'navAccountNewTx', icon: <AddBoxIcon /> }
  ]), [setNavbar]);

  useEffect(() => {
    const work = async () => {
      if (!(accounts?.root && report?.data?.map && commodities)) {
        return;
      }

      let myReportMap = new CTxReportMap({})
      myReportMap.cloneAdditive(new CTxReportMap(report.data.map), accounts?.root);

      await TxForex.additiveMergeByAccountCurrency(accounts, myReportMap, commodities);

      let myReportAdditive = myReportMap.data;

      setReportAdditive(myReportAdditive)
    }
    work();
  }, [report, accounts, commodities]);

  const handleSelect = useCallback((event: React.ChangeEvent<{}>, nodeIds: string) => {
    let intent = (event as any)['intent'];

    if (intent !== 'expand') {
      AppHistory.push(`/ledgers/${ledgerId}/accounts/${nodeIds}/transactions`)
    }
    // The following is required because events are reused and outdated fields are propagated on next event dispatch.
    delete (event as any)['intent'];
  }, [ledgerId]);

  return (
    <>
      {reportAdditive &&
        <AccountTree reportMap={reportAdditive} balances={balances?.data} commodities={commodities} accounts={accounts} onNodeSelect={handleSelect} />}
    </>
  );
}

Accounts.whyDidYouRender = true;