import { Snackbar } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import React from "react";
import './AppSnackbar.css';

export type AppSnackbarPropsState = {
    message: string | undefined,
    severity?: AlertProps['severity']
};

export type AppSnackbarPropsCallback = {
    onClose: () => void
}

export type AppSnackbarProps = AppSnackbarPropsState & AppSnackbarPropsCallback;
export const AppSnackbar = React.memo((props: AppSnackbarProps) => {
    const { message, severity, onClose } = props;
    return <Snackbar className='AppSnackbar' open={message ? true : false} autoHideDuration={3000} onClose={onClose}>
        <Alert onClose={onClose} severity={severity ?? 'info'}>
            {message}
        </Alert>
    </Snackbar>;
});