
//{a: "/income/Freelancing", v: {USD: 0}}
//{a: "/income/Freelancing/ODesk", v: {USD: -50000}, g: "2020-07-21"}

import { AccountNode } from "service/Accounts.store";

export type TxReportMap = {
    [currency: string]: {
        [account: string]: {
            [group: string]: number
        }
    }
};

export class CTxReportMap {
    constructor(public data: TxReportMap) { }

    get({ currency, account, group }: { currency: string, account: string, group: string }) {
        return this.data?.[currency]?.[account]?.[group];
    }
    inc({ currency, account, group }: { currency: string, account: string, group: string }, value: number) {
        this.data?.[currency] ?? (this.data[currency] = {});
        this.data?.[currency]?.[account] ?? (this.data[currency][account] = {});
        this.data?.[currency]?.[account]?.[group] ?? (this.data[currency][account][group] = 0);

        this.data[currency][account][group] += value;
    }
    currencies() {
        return Object.keys(this.data);
    }
    groups() {
        let groups = new Set<string>();
        for (let entry of this.all()) {
            groups.add(entry.path.group);
        }
        return Array.from(groups.keys())
    }
    groupValues({ currency, account }: { currency: string, account: string }) {
        return Object.entries(this.data?.[currency]?.[account] ?? {}).map(item => { return { group: item[0], value: item[1] } });
    }

    *all(opts?: {
        currency?: (currency: string) => boolean,
        account?: (account: string) => boolean
    }) {
        opts = opts ?? {}
        opts.currency = opts.currency ?? ((currency: string) => true);
        opts.account = opts.account ?? ((account: string) => true);

        //console.log('all filter', opts);
        //console.log('currencies', this.currencies());
        for (let currency of this.currencies().filter(opts.currency)) {
            for (let account of Object.keys(this.data[currency]).filter(opts.account)) {
                for (let [group, value] of Object.entries(this.data?.[currency]?.[account] ?? {})) {
                    //console.log('currency', currency, 'account', account, group, value);
                    let item = { path: { currency: currency, account: account, group: group }, value: value }
                    yield item;
                }
            }
        }
    }

    clean() {
        this.currencies().forEach(key => delete this.data[key]);
    }

    clone(source: CTxReportMap) {
        this.data = JSON.parse(JSON.stringify(source.data))
    }

    cloneAdditive(source: CTxReportMap, account: AccountNode) {
        this.clone(source);
        this.makeAdditive(source, account);
    }

    makeAdditive(source: CTxReportMap, account: AccountNode) {
        //console.log('make additive', account.name);
        for (let entry of this.all({ account: (acc) => acc === account.name, currency: (item) => item !== account.commodity })) {
            //console.log('1-inc', entry);
            this.inc(entry.path, entry.value);
        }

        //console.log('children of', account.name, account?.children);
        for (let child of account?.children ?? []) {
            this.makeAdditive(source, child);

            for (let entry of this.all({ account: (acc) => acc === child.name })) {
                //console.log('2-inc', entry);
                this.inc({ ...entry.path, account: account.name }, entry.value);
            }
        }
    }
}

export type TxReport = {
    map: TxReportMap,
    properties: any
}
