import { Commodities } from "service/Data.store";
import { TxReport } from "service/types/Types";

export const TreeTransformer = {
    transform: (commodities: Commodities, report: TxReport) => {
        ////console.log('D3Report.transform', commodities, JSON.stringify(report));

        let { properties, map } = report;

        let root = properties.account;

        let tree = { children: [] } as any;

        for (let [commodity, reportPerCommodity] of Object.entries(map)) {
            for (let [accountName, reportPerAccount] of Object.entries(reportPerCommodity)) {
                //console.log('Processing', accountName, reportPerAccount, commodity);
                let ptr = tree;
                let nameOffset = accountName.substring(root.length);
                let parts = nameOffset.split('/');
                for (let i = 0; i < parts.length; i++) {
                    let child = (ptr?.children ?? []).find((item: any) => item.name === parts[i]);
                    if (!child) {
                        child = {
                            name: parts[i],
                            fqName: [root, ...parts.slice(0, i + 1)].join('/'),
                            commodity: commodity
                        }
                        ptr.children = ptr?.children ?? [];
                        ptr.children.push(child);
                    }
                    ptr = child;
                }

                ptr.fqName = accountName;
                ptr.value = reportPerAccount['v'] ?? 0;
                ptr.commodity = commodity;
            }
        };

        //console.log('tree 1', JSON.stringify(tree));

        tree = mergeParentValueAsChild(tree);
        //console.log('tree 2', JSON.stringify(tree));
        //tree = processValuesToDisplayable(commodities, tree);
        aggregateValue(tree)
        //console.log('tree 3', JSON.stringify(tree), tree);

        tree = tree.children[0];
        if (!tree?.children) {
            tree = {
                children: [tree],
                aggregateValue: tree.aggregateValue,
                commodity: tree.commodity,
                fqName: tree.fqName,
                name: tree.name
            }
        }
        ////console.log('tree ret', tree);
        return tree;
    }
}

function mergeParentValueAsChild(node: any) {
    for (let i = 0; i < node?.children?.length ?? 0; i++) {
        node.children[i] = mergeParentValueAsChild(node.children[i]);
    }
    if (node.value && node.children) {
        node.children.push({
            name: `…`, fqName: node.fqName, value: node.value, commodity: node.commodity
        })
        delete node.value;
    }
    return node;
}

function aggregateValue(node: any) {
    let value = 0;
    for (let child of node?.children ?? []) {
        value += aggregateValue(child);
    }
    if (node?.value) {
        value += parseInt(node?.value);
    }
    node.aggregateValue = value;

    return value;
}
