import React from "react";
import { required } from "service/Page.store";
import './BalanceEntry.co.css';
import { MonetaryValue } from "./MonetaryValue.co";

export const BalanceEntry = React.memo((props: any) =>{
  const { date, desc, value, commodity, style } = props;

  return (
    <div className="BalanceEntry">
      <div className={`mono be-date` + (date ? '' : ' be-hidden')}>{date ?? '0000-00-00'}</div>
      <div className={"be-desc"}>{desc}</div>
      {required(value, commodity, style) ?
        <div className={"be-val"}>
          <MonetaryValue value={value} commodity={commodity} style={style} />
        </div>
        :
        <div className={"be-val be-hidden"}>
          <MonetaryValue value={0} commodity={commodity ?? { symbol: '$', units: 100 }} style={style ?? 'normal'} />
        </div>
      }
    </div>
  );
});
