const dev = {
  api: {
    url: "http://localhost:4001",
  },
  log: console.log,
  pay: true
};

const prod = {
  api: {
    url: "https://api2.uttercash.com",
  },
  log: null,
  pay: false
};

export const Config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;
