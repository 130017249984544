import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import UpdateIcon from '@material-ui/icons/Update';
import { Alert, Color } from '@material-ui/lab';
import { ErrorBoundary } from 'component/ErrorBoundary.co';
import AppDrawer from 'component/nav/AppDrawer.co';
import Contents from 'component/nav/Content.co';
import { Navbar } from 'component/nav/Navbar.co';
import NavbarBottom from 'component/nav/NavbarBottom.co';
import { Config } from 'Config';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Router } from 'react-router-dom';
import { AppHistory, appUpdateSubject, installPromptSubject, NavbarEvent, NavbarEventSubject, useStyles } from 'service/Page.store';
import { useBehaviorSubject } from 'service/RxEffects';
import { unregister } from 'serviceWorker';
import useSWR from 'swr';
import './App.css';
import './i18n/i18n';

export const ParamsContext = createContext({
  params: {} as any,
  setParams: (arg: any) => { }
});

function App() {
  const { t } = useTranslation();

  // eslint-disable-next-line
  const { data: auth } = useSWR('/user/auth', { refreshInterval: 0 })

  const [appUpdate] = useBehaviorSubject(appUpdateSubject);

  const [snackbar, setSnackbar] = useState({ open: false, duration: 999000, severity: 'info' as Color });
  const Button_update = useCallback(() => {
    Config.log?.('Button Update controller (true) =', navigator?.serviceWorker?.controller);

    unregister();
    setTimeout(() => { console.log('reloading...'); document.location.reload(true) }, 10);
  }, []);

  const classes = useStyles();

  let installEventListener = useCallback((e: any) => {
    e.preventDefault();
    installPromptSubject.next(e);
  }, []);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', installEventListener);
    navigator?.serviceWorker?.controller?.postMessage({ type: 'SKIP_WAITING' });

    return () => {
      window.removeEventListener("beforeinstallprompt", installEventListener);
    };
  }, [installEventListener]);

  useEffect(() => {
    if (appUpdate?.update) {
      setSnackbar(s => { return { ...s, open: true } })
    }
  }, [appUpdate]);

  const setNavbarEvent = useCallback((event: NavbarEvent<any>) => {
    NavbarEventSubject.next(event);
  }, []);

  return (
    <Router history={AppHistory}>
      <ErrorBoundary>
        <div className="App-root">
          <CssBaseline />

          <AppDrawer></AppDrawer>
          <div className={classes.root}>
            <Navbar></Navbar>
            {
              snackbar.open && <Alert severity={snackbar.severity}>
                <div>
                  {t('alert_update_text')}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<UpdateIcon />}
                  onClick={Button_update}>
                  {t('button_update')}
                </Button>
              </Alert>
            }
            <Contents></Contents>
            <NavbarBottom eventPublisher={setNavbarEvent}></NavbarBottom>
          </div>
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
