import { produce } from "immer"
import moment from "moment"
import { AccountAll } from "service/Accounts.store"
import { Commodities, Commodity, ICommodity } from "service/Data.store"
import { Money } from "service/Money.util"

export type TxFormSplit = {
    key: string,
    id?: string;
    date: string;
    description: string;
    value: string;
    account: null | string;
    commodityObj: Commodity | undefined;
}

export type TxForm = {
    id?: string;
    dirty?: boolean;
    error?: boolean;
    description: string;
    splits: TxFormSplit[];
}

type TxFormAction = {
    txUndefine?: true,
    txReset?: { accounts: AccountAll, commodities: Commodities, lastFirstAccount: string | null | undefined, lastSecondAccount?: string, date?: string },
    txFromData?: { data: any, lastFirstAccount: string | null | undefined, accounts: AccountAll, commodities: Commodities },
    tx?: { diff: Partial<TxForm> },
    split?: { accounts: AccountAll, commodities: Commodities, index: number, direction?: boolean, diff: Partial<TxFormSplit> }
    splitNew?: { accounts: AccountAll, commodities: Commodities },
    splitAdd?: { split: TxFormSplit }
    splitDelete?: { index: number }
}

export enum TxType {
    incomplete = 'incomplete',
    complex = 'complex',
    unknown = 'unknown',
    expense = 'expense',
    income = 'income',
    loan = 'loan',
    transfer = 'transfer'
}

export type TxKind = {
    type: TxType;
    moneyFlowStartIndex?: number | undefined;
    assetsIndex?: number | undefined;
}

export const TxUtil = {
    canBeSimplified: (splits: TxFormSplit[]): boolean => {
        if (splits.length !== 2) {
            return false;
        }
        if (splits[0].date !== splits[1].date) {
            return false;
        }
        if (splits[0].description || splits[1].description) {
            return false;
        }
        return true;
    },

    validate: (form: TxForm): { valid: boolean } => {
        if (form.splits.find(s => !s.date || !s.account)) {
            return { valid: false }
        }
        return { valid: true }
    },

    txType: (splits: TxFormSplit[]): TxKind => {
        if (splits.length > 2) {
            return { type: TxType.complex };
        }
        if (!splits[0] || !splits[1]) {
            return { type: TxType.incomplete };
        }

        for (let i = 0; i <= 1; i++) {
            let v = splits[i].value;

            // this assumes the "natural" ordering of accounts for all the "startsWith" checks below.
            let moneyFlowStartIndex = v ? (v.startsWith("-") ? i : 1 - i) : undefined;

            if (splits[i]?.account?.startsWith('/assets') && splits[1 - i]?.account?.startsWith('/expenses')) {
                return { type: TxType.expense, moneyFlowStartIndex, assetsIndex: i }
            }
            if (splits[i]?.account?.startsWith('/income') && splits[1 - i]?.account?.startsWith('/assets')) {
                return { type: TxType.income, moneyFlowStartIndex, assetsIndex: 1 - i }
            }
            if (splits[i]?.account?.startsWith('/equity') && splits[1 - i]?.account?.startsWith('/assets')) {
                return { type: TxType.income, moneyFlowStartIndex, assetsIndex: 1 - i }
            }
            if (splits[i]?.account?.startsWith('/liabilities') && splits[1 - i]?.account?.startsWith('/assets')) {
                return { type: TxType.loan, moneyFlowStartIndex, assetsIndex: 1 - i };
            }
            if (splits[i]?.account?.startsWith('/assets') && splits[1 - i]?.account?.startsWith('/assets')) {
                return { type: TxType.transfer, moneyFlowStartIndex, assetsIndex: 1 };
            }
        }
        return { type: TxType.unknown };
    },

    reverseValue: (value: string) => {
        if (!value) {
            return "";
        }
        return value.includes('-') ? value.replace('-', '') : "-" + value
    },

    moduleValue: (value: string) => {
        if (!value) {
            return "";
        }
        return value.replace('-', '')
    },

    linkValues: (splits: TxFormSplit[], sourceIndex: number) => {
        if ((splits.length !== 2) || (sourceIndex < 0) || (sourceIndex > 1)) {
            return;
        }

        const source = splits[sourceIndex];
        const dest = splits[1 - sourceIndex];

        if (source?.commodityObj?.id !== dest?.commodityObj?.id) {
            return;
        }

        dest.value = TxUtil.reverseValue(source.value);
    }
}

const txFormReducerHandler = {
    txUndefine: (tx: TxForm | undefined, task: NonNullable<TxFormAction["txUndefine"]>) => undefined,
    txReset: (tx: TxForm | undefined, task: NonNullable<TxFormAction["txReset"]>) => ({
        description: '',
        splits: [
            splitNew(task.accounts, task.commodities, task.lastFirstAccount, task.date),
            splitNew(task.accounts, task.commodities, task.lastSecondAccount, task.date)
        ]
    }),
    txFromData: (tx: TxForm | undefined, task: NonNullable<TxFormAction["txFromData"]>) => {
        return TxEditTransformer.fromData(task.commodities, task.accounts, task.data, task.lastFirstAccount);
    },
    tx: (tx: TxForm | undefined, task: NonNullable<TxFormAction["tx"]>) => {
        if (!tx) return tx;

        return produce(tx, (draft) => { draft.dirty = true; Object.assign(draft, task.diff) })
    },
    split: (tx: TxForm | undefined, task: NonNullable<TxFormAction["split"]>) => {
        if (!tx) return tx;

        return produce(tx, (draft) => {
            draft.dirty = true;

            const me = draft.splits[task.index];

            Object.assign(me, task.diff);
            // console.log('split with diff applied', me, task.index, task.diff);

            if (task.diff.account) {
                const newCommodity = ICommodity.byAccountName(task.commodities, task.accounts, task.diff.account);
                if (newCommodity?.id !== me?.commodityObj?.id) {
                    me.commodityObj = newCommodity;
                    me.value = "";
                    TxUtil.linkValues(draft.splits, 1 - task.index);
                }
            }

            if (task.diff.value !== undefined) {
                TxUtil.linkValues(draft.splits, task.index)
            }

            //console.log('Splits=', JSON.stringify(draft.splits))
        })
    },
    splitNew: (tx: TxForm | undefined, task: NonNullable<TxFormAction["splitNew"]>) => {
        return txFormReducerHandler.splitAdd(tx, { split: splitNew(task.accounts, task.commodities) })
    },
    splitAdd: (tx: TxForm | undefined, task: NonNullable<TxFormAction["splitAdd"]>) => {
        if (!tx) return tx;

        return produce(tx, (draft) => { draft.dirty = true; draft.splits.push(task.split) })
    },
    splitDelete: (tx: TxForm | undefined, task: NonNullable<TxFormAction["splitDelete"]>) => {
        if (!tx || (tx.splits.length <= 2)) return tx;

        return produce(tx, (draft) => { draft.dirty = true; draft.splits.splice(task.index, 1) });
    }
}

function splitNew(accounts: AccountAll, commodities: Commodities, account?: string | null | undefined, date?: string) {
    return {
        key: `local-${Math.random()}`,
        description: '',
        date: date ?? moment().format('YYYY-MM-DD'),
        account: account ? account : null,
        value: '0',
        commodityObj: ICommodity.byAccountName(commodities, accounts, '/')
    }
}

export function txFormReducer(tx: TxForm | undefined, action: TxFormAction): TxForm | undefined {
    // console.log('txFormReducer', 'tx', tx, 'action', Object.keys(action))
    Object.entries(action).filter(entry => entry[1]).forEach((entry) => {
        tx = (txFormReducerHandler as any)[entry[0]]?.(tx, entry[1])
    });
    return tx;
}

export const TxEditTransformer = {
    fromData: (commodities: Commodities, accounts: AccountAll, tx: any, firstAccount: string | null | undefined) => {
        //console.log('txBuildFrom', JSON.stringify(tx));
        let error = false;
        let txForm = { id: tx.id, dirty: false, description: tx.description, splits: [] as TxFormSplit[] };

        (tx.splits ?? []).forEach((split: any) => {
            //console.log('processing split', split)
            let accountNode = accounts?.indexById?.[split.account_id];
            if (!accountNode) {
                error = true;
                return error;
            }
            let formSplit: TxFormSplit = {
                key: `remote-${split.id}`,
                id: split.id,
                date: moment(split.ts).format('YYYY-MM-DD'),
                description: split.description ?? '',
                account: accountNode?.name,
                commodityObj: ICommodity.byAccountName(commodities, accounts, accountNode?.name),
                value: Money.storableIntegerOfAccountToString(commodities, accounts, accountNode?.name, split.value) ?? ''
            }
            //console.log('form split is now', formSplit);
            if (formSplit.value === '') {
                error = true;
            } else {
                txForm.splits.push(formSplit);
            }
        })
        txForm.splits.sort((a, b) => a.account === firstAccount ? -1 : 0);
        //console.log('returning txBuildForm', JSON.stringify(txForm));
        return error ? undefined : txForm as TxForm;
    },

    toData: (commodities: Commodities, accounts: AccountAll, formTx: TxForm) => {
        const formSplitToData = (accounts: AccountAll, commodities: Commodities, split: TxFormSplit) => {
            if (!split.account) {
                throw new Error('account not specified')
            }
            // console.log('formSplitToData', split)
            let accountId = accounts?.indexByName?.[split.account].id;
            if (accountId === undefined) {
                throw new Error('Cannot compute accountId')
            }
            //console.log('about to convert value for split', split);
            let myValue = Money.accountValueToStorableInteger(commodities, accounts, split.account, split.value);
            if (myValue === undefined) {
                throw new Error('Cannot compute myValue')
            }
            //console.log('myValue', myValue);
            return {
                id: split?.id,
                account_id: accountId,
                num: '',
                date: split.date,
                description: split.description,
                value: myValue
            }
        }
        return {
            description: formTx.description,
            splits: formTx.splits.map(split => formSplitToData(accounts, commodities, split))
        }
    }
}