import { Drawer } from '@material-ui/core';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import EditIcon from '@material-ui/icons/Edit';
import ChartBars from 'component/chart/ChartBars.co';
import ChartSunburst from 'component/chart/ChartSunburst.co';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { AppHistory, eventMap, useNavbar, useNavbarCallback } from 'service/Page.store';
import { Service } from 'service/Service.store';
import './ReportsEdit.style.css';
import ReportsEditForm from './ReportsEditForm';

type Nav = { navReportsList: null, navReportsEdit: null };

export default function ReportsEdit(props: any) {
  let { ledgerId, reportId } = props;

  const { accounts, commodities } = Service.useLedgerContext(props);
  //const { data: commodities } = Service.useCommodities();
  //const { data: accounts } = Service.useAccounts(ledgerId);

  let [reportVersion, setReportVersion] = useState(moment().unix().toString());

  const { data: reportTx } = Service.useReportTx(ledgerId, reportId, reportVersion);


  const [formDrawerOpen, setFormDrawerOpen] = useState(false as boolean);

  useEffect(() => {
    if (!reportId) {
      setFormDrawerOpen(true)
    }
  }, [reportId]);

  const [, setNavbar] = useNavbar<Nav>();
  useEffect(() => setNavbar([
    { key: 'navReportsList', icon: <DonutLargeIcon /> },
    { key: 'navReportsEdit', icon: <EditIcon /> }
  ]), [setNavbar]);

  const onSaveSuccess = (res: any) => {
    let returnedId = res?.data?.id;
    //console.log('onSaveSuccess', res)
    setFormDrawerOpen(false);
    if (reportId !== returnedId) {
      AppHistory.push(`/ledgers/${ledgerId}/reports/${returnedId}`)
    }
    setReportVersion(moment().unix().toString());
  }

  const formDrawerCloseWithoutSave = () => {
    setFormDrawerOpen(false)
  }

  useNavbarCallback(useMemo(() => eventMap<Nav>({
    navReportsList: () => AppHistory.push(`/ledgers/${ledgerId}/reports`),
    navReportsEdit: () => setFormDrawerOpen(true)
  }), [ledgerId]));

  return (
    <div className="ReportsEdit-container" key={'anchor'}>
      {
        accounts && (reportTx?.data?.properties?.type === 'star') &&
        <ChartSunburst accounts={accounts} reportTx={reportTx} ledgerId={ledgerId} reportId={reportId} commodities={commodities?.data} reportVersion={reportVersion}></ChartSunburst>
      }
      {
        (reportTx?.data?.properties?.type === 'bars') &&
        <ChartBars accounts={accounts} reportTx={reportTx} ledgerId={ledgerId} reportId={reportId} commodities={commodities?.data} reportVersion={reportVersion}></ChartBars>
      }
      <Drawer className="ReportsEdit-Drawer" anchor={'bottom'} open={formDrawerOpen} onClose={formDrawerCloseWithoutSave}>
        <ReportsEditForm accounts={accounts} ledgerId={ledgerId} reportId={reportId} onSaveSuccess={onSaveSuccess}></ReportsEditForm>
      </Drawer>
    </div >
  );
}
