import * as d3 from "d3";
import React, { useEffect, useRef } from "react";
import { D3Sunburst } from "service/reports/D3Sunburst";

export default function ChartSunburst(props: any) {
    const { accounts, reportTx, reportVersion, commodities } = props;

    const ref = useRef(null);

    useEffect(() => {
        //console.log('Sunburst Visual component', accounts, reportTx, reportVersion, commodities);
        const current = ref?.current as any;

        if (!current) {
            return;
        }
        if (!commodities) {
            return;
        }
        if (reportTx?.success === false) {
            d3.selectAll('.report-canvas > .d3_message > text').text('Invalid report')
            return;
        }

        if (reportTx?.data.length === 0) {
            d3.selectAll('.report-canvas > .d3_message > text').text('No entries')
            return;
        }

        if (reportTx?.data && commodities) {
            D3Sunburst.render({ rootSvgElement: current, accounts, reportTx, reportVersion, commodities });

        } else {
            d3.selectAll('.report-canvas > .d3_message > text').text('')
        }

    }, [reportTx, reportVersion, commodities, accounts])

    return (
        <svg className="report-canvas" ref={ref}>
            <g className="d3_message">
                <text x="20" y="35"></text>
            </g>
            <defs>
                <filter id="d3_brightness">
                    <feComponentTransfer>
                        <feFuncR type="linear">
                            <animate attributeName="intercept" values="0;0.3;0" dur="5s" fill="freeze" />
                        </feFuncR>
                        <feFuncG type="linear">
                            <animate attributeName="intercept" values="0;0.3;0" dur="5s" fill="freeze" />
                        </feFuncG>
                        <feFuncB type="linear">
                            <animate attributeName="intercept" values="0;0.3;0" dur="5s" fill="freeze" />
                        </feFuncB>
                    </feComponentTransfer>
                </filter>
            </defs>
        </svg>
    );
}
