import { Button, IconButton } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { MAutocomplete, MKeyboardDatePicker, MMonetaryValueField, MTextField } from "component/ui/AppUi";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TxForm, TxFormSplit, TxType, TxUtil } from "./TxEdit.form";

export const TxEditFormSimplified = React.memo((props:
    TxForm &
    { moneyFlowStartIndex: number | undefined } &
    { accountNames: string[] } &
    { displayDisabled: boolean } &
    {
        onMoneyFlowStartIndexChange: (newMoneyFlowStartIndex: number) => void,
        onTxChange: (update: Partial<TxForm>) => void,
        onSplitDelete: (index: number) => void,
        onSplitChange: (index: number, split: Partial<TxFormSplit>) => void,
        onSplitAdd: () => void,
        onAskFormModeSwitch: () => void
    }
) => {
    const { t } = useTranslation();

    const { onMoneyFlowStartIndexChange, onTxChange, onSplitChange, onAskFormModeSwitch, moneyFlowStartIndex, accountNames, displayDisabled, splits, description } = props;

    const [valueLabels, setValueLabels] = useState(['txSplitLabel_transfer_more_assets', 'txSplitLabel_transfer_more_assets'] as string[]);

    let txChange = useCallback((e => onTxChange?.({ description: e?.target.value })), [onTxChange]);
    let dateChange = useCallback((date) => {
        let value = (date?.format('YYYY-MM-DD') || '');
        onSplitChange?.(0, { date: value })
        onSplitChange?.(1, { date: value })
    }, [onSplitChange]);

    let accountUpText = useCallback((params) => <MTextField {...params}
        disabled={displayDisabled}
        label={(moneyFlowStartIndex === 0) ? `From` : 'To'} size="small" variant="outlined" margin="dense" />, [displayDisabled, moneyFlowStartIndex]);

    let accountDownText = useCallback((params) => <MTextField {...params}
        disabled={displayDisabled}
        label={(moneyFlowStartIndex === 0) ? `To` : 'From'} size="small" variant="outlined" margin="dense" />, [displayDisabled, moneyFlowStartIndex]);

    let onAccountChange = useCallback((index, account) => {
        //console.log('onAccountChange');

        let newSplits = JSON.parse(JSON.stringify(splits)) as TxFormSplit[];
        newSplits[index].account = account;

        let txTypeOld = TxUtil.txType(splits);
        let txTypeNew = TxUtil.txType(newSplits);

        let diff: Partial<TxFormSplit> = {};
        diff.account = account;

        if (txTypeOld.type !== txTypeNew.type) {
            if ([TxType.expense, TxType.income, TxType.loan, TxType.transfer].find(item => item === txTypeNew.type)) {
                let assetPos = newSplits[0].account?.startsWith('/assets') ? 0 : 1;
                let assetSplit = newSplits[assetPos];
                let sign = '';
                switch (txTypeNew.type) {
                    case TxType.expense:
                        sign = (diff.account === assetSplit.account) ? '-' : sign;
                        diff = { ...diff, value: sign + TxUtil.moduleValue(assetSplit.value) }
                        onMoneyFlowStartIndexChange(assetPos);
                        break;
                    case TxType.income:
                        sign = (diff.account === assetSplit.account) ? sign : '-';
                        diff = { ...diff, value: sign + TxUtil.moduleValue(assetSplit.value) }
                        onMoneyFlowStartIndexChange(1 - assetPos);
                        break;
                    case TxType.loan:
                        diff = { ...diff, value: TxUtil.moduleValue(assetSplit.value) }
                        onMoneyFlowStartIndexChange(assetPos);
                        break;
                    case TxType.transfer:
                        sign = (index === moneyFlowStartIndex) ? '-' : '';

                        diff = { ...diff, value: sign + TxUtil.moduleValue(assetSplit.value) }
                        break;
                }
            }
        }
        //console.log('about to publish diff @ onAccountChange 3', JSON.stringify(diff));
        onSplitChange(index, diff);
        //console.log('onAccountChange 3');
    }, [onSplitChange, onMoneyFlowStartIndexChange, moneyFlowStartIndex, splits]);

    useEffect(() => {
        let txType = TxUtil.txType(splits);

        let newFlow = moneyFlowStartIndex ?? txType.moneyFlowStartIndex ?? 0;

        if (txType.moneyFlowStartIndex !== undefined) {
            let txTypeLabel = 'txSplitLabel_' + txType.type.toString() +
                ((txType.moneyFlowStartIndex !== txType.assetsIndex) ? '_more_assets' : '_less_assets');

            setValueLabels(old => (old[0] === txTypeLabel) ? old : [txTypeLabel, txTypeLabel])
        }

        onMoneyFlowStartIndexChange(newFlow);

    }, [onMoneyFlowStartIndexChange, moneyFlowStartIndex, splits]);

    let onAmountChange = useCallback((index, value) => {
        let newValue = (moneyFlowStartIndex === index) ? TxUtil.reverseValue(value) : value;

        //console.log('onAmountChange', index, value, newValue);

        onSplitChange?.(index, { value: newValue })
    }, [onSplitChange, moneyFlowStartIndex]);

    let accountDownCallback = useCallback((event, value) => onAccountChange(1, value), [onAccountChange]);
    let accountUpCallback = useCallback((event, value) => onAccountChange(0, value), [onAccountChange]);

    let mvfc0 = useCallback((e: any) => onAmountChange(0, e.target.value), [onAmountChange]);
    let mvfc1 = useCallback((e: any) => onAmountChange(1, e.target.value), [onAmountChange]);

    const onDirectionChangeCb = useCallback(() => {
        onSplitChange?.(0, { value: TxUtil.reverseValue(splits[0].value) });
        onSplitChange?.(1, { value: TxUtil.reverseValue(splits[1].value) });

        (moneyFlowStartIndex !== undefined) && onMoneyFlowStartIndexChange(1 - moneyFlowStartIndex);
    }, [onSplitChange, moneyFlowStartIndex, onMoneyFlowStartIndexChange, splits])

    return (
        <div className="Transaction-Simplified" >
            {
                //console.log('Transaction-Simplified: render splits', JSON.stringify(props))
            }
            <div className="Transaction-Simplified-DateDescription">
                <MKeyboardDatePicker size={"small"} inputVariant="outlined" margin="dense" className="Split-Date" disableToolbar variant="inline"
                    disabled={displayDisabled}
                    label="Date"
                    format="YYYY-MM-DD"
                    value={splits[0].date}
                    onChange={dateChange}
                />
                <MTextField className="Transaction-Description" fullWidth={true} disabled={displayDisabled}
                    size={"small"} variant={"outlined"}
                    label="Transaction description" value={description}
                    onChange={txChange} />
            </div>
            <div className="Transaction-Simplified-Flow">
                <div>
                    <MAutocomplete className="Split-Account"
                        disabled={displayDisabled}
                        value={splits[0].account}
                        options={accountNames}
                        onChange={accountUpCallback}
                        renderInput={accountUpText}
                    />
                </div>
                <div className="Transaction-Simplified-Value">
                    <IconButton aria-label="delete" onClick={onDirectionChangeCb} disabled={displayDisabled}>
                        {(moneyFlowStartIndex === 0) && <ArrowDownwardIcon fontSize="inherit" />}
                        {(moneyFlowStartIndex === 1) && <ArrowUpwardIcon fontSize="inherit" />}
                    </IconButton>
                    <div className="Transaction-Simplified-ValuesGroup">
                        <>
                            {splits[0].commodityObj &&
                                <MMonetaryValueField
                                    disabled={displayDisabled}
                                    label={t(valueLabels[0])}
                                    value={splits[0].value}
                                    commodity={splits[0].commodityObj}
                                    onChange={mvfc0}
                                    mode={'module-positive'}
                                />}
                        </>
                        <>
                            {(splits[1].commodityObj && ((splits[0].commodityObj !== splits[1].commodityObj))) &&
                                <MMonetaryValueField
                                    disabled={displayDisabled}
                                    label={t(valueLabels[1])}
                                    value={splits[1].value}
                                    commodity={splits[1].commodityObj}
                                    onChange={mvfc1}
                                    mode={'module-positive'}
                                />}
                        </>
                    </div>
                </div>
                <div>
                    <MAutocomplete className="Split-Account"
                        disabled={displayDisabled}
                        value={splits[1].account}
                        options={accountNames}
                        onChange={accountDownCallback}
                        renderInput={accountDownText}
                    />
                </div>
            </div>
            <div>
                <div className="Transaction-Actions">
                    <Button
                        disabled={displayDisabled}
                        variant="contained"
                        color="default"
                        startIcon={<UnfoldMoreIcon />}
                        onClick={onAskFormModeSwitch}
                    >Detailed</Button>
                </div>
            </div>
        </div >
    );
})

TxEditFormSimplified.whyDidYouRender = true;
