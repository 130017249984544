import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BarChartIcon from '@material-ui/icons/BarChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import React, { useEffect, useMemo } from 'react';
import { AppHistory, eventMap, useNavbar, useNavbarCallback } from 'service/Page.store';
import { Service } from 'service/Service.store';

export default function ReportsList(props: any) {
  let { ledgerId } = props;

  const { data: reports } = Service.useReports(ledgerId);

  type Nav = { navNewReport: null };
  const [, setNavbar] = useNavbar<Nav>();
  useEffect(() => setNavbar([
    { key: 'navNewReport', icon: <AddIcon /> }
  ]), [setNavbar]);

  useNavbarCallback(useMemo(() => eventMap<Nav>({
    navNewReport: () => AppHistory.push(`/ledgers/${ledgerId}/reports/new`)
  }), [ledgerId]));

  return (
    <div>
      <div>
        {reports?.data?.length === 0 && <div>No reports.</div>}
        {reports?.data && reports?.data?.length !== 0 && <List>
          {reports.data.map((report: any) => <ListItem button
            key={report.id}
            onClick={() => AppHistory.push(`/ledgers/${ledgerId}/reports/${report.id}`)}>
            <ListItemAvatar>
              <Avatar>
                {report.content.type === 'bars' && <BarChartIcon />}
                {report.content.type === 'star' && <DonutLargeIcon />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={report.content.name} secondary={report.content.type} />
          </ListItem>)
          }
        </List>
        }
      </div>
    </div>
  );
}
