import { useTheme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import MailIcon from '@material-ui/icons/Mail';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleIcon from '@material-ui/icons/People';
import { Config } from 'Config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Globals } from 'service/Globals.store';
import { AppHistory, drawerSubject, installPromptSubject, useStyles } from 'service/Page.store';
import { useBehaviorSubject } from 'service/RxEffects';
import { Service } from 'service/Service.store';
import './AppDrawer.co.css';

function DrawerContents(props: any) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: auth } = Service.useAuth();

  const [currentLedger] = Globals.useLedger();

  const [installPrompt] = useBehaviorSubject(installPromptSubject);
  const [alreadyInstalled, setAlreadyInstalled] = useState(false);

  useEffect(() => {
    drawerSubject.next(false);
    if (window.matchMedia('(display-mode: standalone)').matches || ((window.navigator as any)['standalone'] === true)) {
      setAlreadyInstalled(true);
    }
  }, []);

  function nav(uri: string) {
    drawerSubject.next(false);
    AppHistory.push(uri);
  }

  function install() {
    (installPrompt as any).prompt();
  }

  return (
    <div>
      <div className={`${classes.toolbar} AppDrawer-top`}>
        <div className="container-brand">UtterCash</div>
        <div className="build-no">{process.env.REACT_APP_BUILD_VERSION || '2020-02-02-0000-dev'}</div>
      </div>
      <Divider />
      {currentLedger?.data &&
        <List>
          <ListItem button
            key={`ledger-${currentLedger?.data?.id}-accounts`}
            onClick={() => nav(`/ledgers/${currentLedger?.data?.id}/accounts`)}>
            <ListItemIcon><AccountTreeIcon /></ListItemIcon>
            <ListItemText primary={'Accounts'} />
          </ListItem>

          <ListItem button
            key={`ledger-${currentLedger?.data?.id}-newtransaction`}
            onClick={() => nav(`/ledgers/${currentLedger?.data?.id}/transactions/edit/new`)} >
            <ListItemIcon><AddBoxIcon /></ListItemIcon>
            <ListItemText primary={'Transaction'} />
          </ListItem>

          <ListItem button
            key={`ledger-${currentLedger?.data?.id}-users`}
            onClick={() => nav(`/ledgers/${currentLedger?.data?.id}/users`)} >
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary={'Users'} />
          </ListItem>

          <ListItem button
            key={`ledger-${currentLedger?.data?.id}-reports`}
            onClick={() => nav(`/ledgers/${currentLedger?.data?.id}/reports`)} >
            <ListItemIcon><DonutLargeIcon /></ListItemIcon>
            <ListItemText primary={t('drawer_action_reports')} />
          </ListItem>

        </List >
      }
      {currentLedger?.data && <Divider />}
      <List>
        {auth?.data && <ListItem button key='ledgers' onClick={() => nav(`/ledgers/`)}>
          <ListItemIcon><MenuBookIcon /></ListItemIcon>
          <ListItemText primary={'Ledgers'} />
        </ListItem>
        }
        {auth?.data && Config.pay && <ListItem button key='payments' onClick={() => nav(`/payments`)}>
          <ListItemIcon><PaymentIcon /></ListItemIcon>
          <ListItemText primary={'Payments'} />
        </ListItem>
        }
        {auth && !auth.data &&
          <ListItem button key='signedOut' component="a" href={`${Config.api.url}/auth/google`}>
            <ListItemIcon><AccountBoxIcon /></ListItemIcon>
            <ListItemText primary={'Sign In'} />
          </ListItem>
        }
        {auth?.data &&
          <ListItem button key='signedIn' component="a" href={`${Config.api.url}/auth/logout`}>
            <ListItemIcon><MailIcon /></ListItemIcon>
            <ListItemText primary={'Sign Out'} />
          </ListItem>
        }
        {installPrompt && (!alreadyInstalled) &&
          <ListItem button key='install'>
            <ListItemIcon><GetAppIcon /></ListItemIcon>
            <ListItemText primary={'Install'} onClick={() => install()} />
          </ListItem>
        }
      </List>
      <Divider />
      <List>
        <ListItem button key='about' onClick={() => nav(`/about`)}>
          <ListItemIcon><InfoIcon /></ListItemIcon>
          <ListItemText primary={'About'} />
        </ListItem>
      </List>
    </div >);
}

export default function AppDrawer(props: any) {
  const classes = useStyles();
  const theme = useTheme();

  const [mobileOpen] = useBehaviorSubject<boolean>(drawerSubject);

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={() => drawerSubject.next(false)}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}>
          <DrawerContents />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
          <DrawerContents />
        </Drawer>
      </Hidden>
    </nav>
  );
}
