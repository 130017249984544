import { AccountAll } from "./Accounts.store";

export type Ledger = any;
export type CommoditySpec = [string, string, number];
export type Commodity = { id: string, description: string, symbol: string, units: number };

export type TCommodityStore = {
    [id: string]: Commodity
}

const CommodityStore = {} as TCommodityStore;

export const ICommodity = {
    byId(commodities: Commodities, id: string): Commodity | undefined {
        if (!CommodityStore[id]) {
            let spec = commodities?.data?.[id]
            if (!spec) {
                return undefined;
            }
            CommodityStore[id] = { id: id, symbol: spec[0], description: spec[1], units: spec[2] };
        }
        return CommodityStore[id]
    },

    byAccountName(commodities: Commodities, accounts: AccountAll, accountName: string) {
        return ICommodity.byId(commodities, accounts?.indexByName?.[accountName]?.commodity ?? '')
    }
}
export type CommoditiesMap = {
    [key: string]: CommoditySpec
};

export interface Commodities {
    data?: CommoditiesMap,
    error?: any
}
