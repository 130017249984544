import MomentUtils from '@date-io/moment';
import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ActionButton from 'component/ActionButton.co';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AccountAll } from 'service/Accounts.store';
import { AppHistory } from 'service/Page.store';
import { Reports } from 'service/Reports.store';
import { Service } from 'service/Service.store';
import './ReportsEdit.style.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    columnDate: {
      marginLeft: 'auto',
      display: 'none'
    },
  }),
);

const startDateRules = [
  { id: 'today', name: 'Today' },
  { id: 'som', name: 'Start of this month' },
  { id: 'spm', name: 'Start of prev month' },
  { id: 'soy', name: 'Start of this year' },
  { id: 'spy', name: 'Start of prev year' }
];

const endDateRules = [
  { id: 'today', name: 'Today' },
  { id: 'eom', name: 'End of this month' },
  { id: 'epm', name: 'End of prev month' },
  { id: 'eoy', name: 'End of this year' },
  { id: 'epy', name: 'End of prev year' }
];

const stepSizeList = [
  { id: 'd', name: 'Day' },
  { id: 'w', name: 'Week' },
  { id: 'm', name: 'Month' },
  { id: 'y', name: 'Year' },
];

const typeList = [
  { id: 'star', name: 'Star' },
  { id: 'bars', name: 'Bars' },
];

export default function ReportsEditForm(props: { accounts: AccountAll, ledgerId: string, reportId: string | undefined, onSaveSuccess: any }) {
  const { ledgerId, reportId, onSaveSuccess, accounts } = props;
  // console.log('props', props);
  const classes = useStyles();

  const { data: report } = Service.useReport(ledgerId, reportId);

  //const { accounts } = Service.useLedgerContext(props);
  //const { data: accounts } = Service.useAccounts(ledgerId);

  const [notification, setNotification] = useState<string | null>('');
  const [formDisabled, setFormDisabled] = useState(false);

  const [name, setName] = useState('' as string);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD') as any);
  const [endDate, setEndDate] = useState(null as any);

  const [startDateRule, setStartDateRule] = useState("");
  const [endDateRule, setEndDateRule] = useState("");

  const [stepSize, setStepSize] = useState('w' as any);
  const [type, setType] = useState('star' as any);

  const [account, setAccount] = useState('');

  const [submitMode, setSubmitMode] = useState('submit');

  const [hasStepSize, setHasStepSize] = React.useState(() => type === 'bars');

  const handleStartDateRule = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStartDateRule(event.target.value as string);
    setStartDate(null);
  };

  const handleEndDateRule = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEndDateRule(event.target.value as string);
    setEndDate(null);
  };

  const handleName = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  const handleType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value);
  };
  const handleStepSize = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStepSize(event.target.value);
  };

  const handleAccount = (e: any) => {
    //console.log('HandleAccount', e);
    setAccount(e);
  };

  const [returnedReportId, setReturnedReportId] = useState(undefined as string | undefined);

  const Button_onDelete = (event: any) => {
    if (!(ledgerId && reportId)) {
      return;
    }
    setFormDisabled(true);
    setNotification('Deleting...');

    Service.generic.delete(Reports.of(ledgerId), reportId)
      .then(res => {
        setNotification('Deleted.');
      }).catch(err => {
        setNotification('Delete failed.')
      })
  }

  const Button_onEditAgain = (event: any) => {
    if (!ledgerId) {
      return;
    }
    if (!reportId && returnedReportId) {
      AppHistory.push(`/ledgers/${ledgerId}/reports/${returnedReportId}`);
    }
    setSubmitMode('submit');
    setFormDisabled(false);
    setNotification('');
  }

  const Button_onSubmit = (event: any) => {
    if (!ledgerId) {
      return;
    }

    let payload = {
      id: reportId,
      name: name,
      startDate: startDate,
      endDate: endDate,
      startDateRule: startDateRule,
      endDateRule: endDateRule,
      stepSize: stepSize,
      type: type,
      account: account
    };

    if (!hasStepSize) {
      delete payload.stepSize;
    }

    setFormDisabled(true);
    setNotification('Saving...');

    Service.generic.createOrUpdate(Reports.of(ledgerId), payload).then(res => {
      let localReportId = res?.data?.id;
      setNotification('Saved');
      //setSubmitMode('edit_again');
      if (!reportId) {
        setReturnedReportId(localReportId);
      }
      onSaveSuccess(res);
    }).catch(err => {
      setNotification('Error');
      setSubmitMode('edit_again');
    })
  }

  useEffect(() => {
    if (!report || (report.success === false)) {
      return;
    }

    const map = {
      name: setName,
      startDate: (d: any) => {
        if (d) setStartDate(d); else setStartDate(null);
      },
      endDate: (d: any) => {
        if (d) setEndDate(d); else setEndDate(null);
      },
      startDateRule: (d: any) => setStartDateRule(d ? d : ''),
      endDateRule: (d: any) => setEndDateRule(d ? d : ''),
      type: setType,
      stepSize: setStepSize,
      account: setAccount
    };

    Object.entries(map).forEach(([key, value]) => {
      value(report.data.content[key]);
    });
  }, [report])

  useEffect(() => {
    setHasStepSize(type === 'bars');
  }, [stepSize, type]);

  // console.log('Accounts:', accounts);
  return (
    <form className="myform ReportsEditForm-container" noValidate autoComplete="off">
      <fieldset disabled={formDisabled}>
        <div>
          <TextField required label="Report name" value={name} onChange={handleName} />
        </div>
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disabled={formDisabled}
              className="dat"
              disableToolbar
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="Start date"
              value={startDate}
              onChange={date => { setStartDate(date?.format("YYYY-MM-DD") || ''); setStartDateRule(''); }}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
            />
          </MuiPickersUtilsProvider>
                &nbsp;
                <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              disabled={formDisabled}
              className="dat"
              disableToolbar
              variant="inline"
              format="YYYY-MM-DD"
              margin="normal"
              label="End date"
              value={endDate}
              onChange={date => { setEndDate(date?.format("YYYY-MM-DD") || ''); setEndDateRule(''); }}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
            />
          </MuiPickersUtilsProvider>
          {false && <FormControl disabled={formDisabled} className={classes.formControl}>
            <InputLabel id="demo-simple-select-required-label">Start Date Rule</InputLabel>
            <Select
              className={classes.selectEmpty}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={startDateRule}
              onChange={handleStartDateRule}
            >
              <MenuItem value="" key='rule-none'>
                <em>None</em>
              </MenuItem>
              {startDateRules.map(rule => <MenuItem key={rule.id} value={rule.id}>{rule.name}</MenuItem>)}
            </Select>
          </FormControl>
          }
        </div>
        <div>
          {false && <FormControl disabled={formDisabled} className={classes.formControl}>
            <InputLabel id="demo-simple-select-required-label">End Date Rule</InputLabel>
            <Select
              className={classes.selectEmpty}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={endDateRule}
              onChange={handleEndDateRule}
            >
              <MenuItem value="" key='rule-none'>
                <em>None</em>
              </MenuItem>
              {endDateRules.map(rule => <MenuItem key={rule.id} value={rule.id}>{rule.name}</MenuItem>)}
            </Select>
          </FormControl>
          }
        </div>
        <div>
          <FormControl required disabled={formDisabled} className={classes.formControl}>
            <InputLabel id="type">Type</InputLabel>
            <Select
              className={classes.selectEmpty}
              labelId="type"
              value={type}
              onChange={handleType}
            >
              {typeList.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
            </Select>
          </FormControl>
          {hasStepSize && <FormControl required disabled={formDisabled} className={classes.formControl}>
            <InputLabel id="type">Step Size</InputLabel>
            <Select
              className={classes.selectEmpty}
              labelId="type"
              value={stepSize}
              onChange={handleStepSize}
            >
              {stepSizeList.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
            </Select>
          </FormControl>}
        </div>
        <div>
          <Autocomplete
            value={account}
            options={accounts?.data?.map((item: any) => item.name) || []}
            getOptionLabel={(option: any) => { /*console.log('ac #2', option);*/ return option; }}
            className="acc"
            disabled={formDisabled}
            onChange={(e: any, v: any) => { handleAccount(v); }}
            onInputChange={(e: any, v: any, r) => { }}
            renderInput={params =>
              <TextField {...params} className="acc" label="Account" />}
          />
        </div>
        <div>
          <div className="horizontal">
            {
              (submitMode === 'submit') && <ActionButton disabled={formDisabled} onClick={Button_onSubmit}>Save</ActionButton>
            }
            {
              (submitMode === 'edit_again') && <ActionButton disabled={!formDisabled} onClick={Button_onEditAgain}>Edit again</ActionButton>
            }
            <div className="spacerHorizontal"></div>
            {
              reportId && <ActionButton disabled={formDisabled} onClick={Button_onDelete}>Delete</ActionButton>
            }
          </div>
          <span className="notification">{notification}</span>
        </div>
      </fieldset>
    </form>
  );
}
