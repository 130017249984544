import { produce } from "immer";
import { CrudButtonKind } from "./CrudButtons";

export type GenericFormState = {
    dirty?: boolean;
    disabled?: boolean;
    valid?: boolean;
    error?: any;
    awaitEdit?: boolean;
    confirmDelete?: boolean;
    progress?: boolean;
    deleted?: boolean;
}

export type GenericForm<T> = {
    state: GenericFormState;
    data: T;
};

export const GenericFormActions = {
    field: <T>(name: string, value: string) => (old: GenericForm<T>) => produce(old, (draft) => {
        draft.state.dirty = true;
        (draft.data as any)[name] = value;
        //console.log('setting draft.data[', name, ']=', value);
    }),

    //deleteSuccessful: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.deleted = true }),
    progress: <T>(old: GenericForm<T>) => produce(old, (draft) => {
        draft.state.disabled = true;
        draft.state.confirmDelete = false;
        draft.state.progress = true
    }),
    progressDone: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.progress = false }),
    confirmDeleteOpen: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.confirmDelete = true }),
    confirmDeleteClose: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.confirmDelete = false }),
    disable: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.disabled = true }),
    enable: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.disabled = false }),
    edit: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.deleted = false; draft.state.dirty = false; draft.state.disabled = false; draft.state.awaitEdit = false; }),
    //saveSuccessful: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.disabled = true; draft.state.awaitEdit = true; }),

    success: {
        crudSave: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.disabled = true; draft.state.awaitEdit = true; }),
        crudDelete: <T>(old: GenericForm<T>) => produce(old, (draft) => { draft.state.deleted = true })
    } as { [k in keyof CrudButtonKind]: any }
}