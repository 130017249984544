import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import React, { useEffect } from "react";
import { isAccountPositive } from "service/Account.store";
import { AccountAll, AccountNode, accountsStore } from "service/Accounts.store";
import { Commodities, ICommodity } from "service/Data.store";
import { TxReportMap } from "service/types/Types";
import './AccountTree.co.css';
import { MonetaryValue } from "./MonetaryValue.co";


export default function AccountTree(
  { reportMap, accounts, balances, commodities, onNodeSelect }:
    { reportMap: TxReportMap, accounts: AccountAll, balances: any, commodities: any, onNodeSelect: any }) {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string[]>([]);

  useEffect(() => {
    if (!accounts) {
      return;
    }
    //console.log('AccountTree', 'acc', accounts);
  }, [accounts]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    let target: any = event.target as any;
    //console.log('Handle toggle', target, target.getAttribute('data-role'));

    if (target.getAttribute('data-role') === 'AcountTree-label-name') {
      (event as any)['intent'] = 'select';
    } else {
      setExpanded(nodeIds);
      (event as any)['intent'] = 'expand';
    }
  };

  const renderTree = (account: AccountNode, commodities: Commodities, reportMap: TxReportMap, balances: any) => {
    if (!(account && commodities && reportMap && balances)) {
      return (<div>...</div>);
    }

    //console.log('acc', account);
    //console.log('comm', commodities);
    //console.log('map', reportMap);
    const style = isAccountPositive(accounts?.indexById?.[account.id]) ? 'normal' : 'reversed';
    const commodity = ICommodity.byId(commodities, account.commodity);
    //const value = balances?.[account.id] || 0

    const value = reportMap?.[account.commodity]?.[account.name]?.['v'] ?? 0

    return (
      <StyledTreeItem key={account.id} nodeId={account.id}
        label={
          <div className="AccountTree-label">
            {account?.color && <div style={{
              'backgroundColor': `hsl(${account?.color?.h}, ${account?.color?.s}%, ${account?.color?.l}%)`,
              width: '10px',
              height: '10px',
              minWidth: '10px',
              flexGrow: 0,
              marginRight: '2px',
              marginTop: '7px',
              border: 'solid 1px darkgray'
            }}>
            </div>
            }
            <div data-role="AcountTree-label-name">
              {accountsStore.accountBasenameString(account.name)}
            </div>
            <div>
              {commodity && <MonetaryValue style={style} commodity={commodity} value={value} />}
            </div>
          </div>
        }>
        {Array.isArray(account.children) ? account.children.map((node: AccountNode) => renderTree(node, commodities, reportMap, balances)) : null}
      </StyledTreeItem>);
  };

  useEffect(() => {
    setExpanded((accounts?.data || []).map((account: any) => account.id))
  }, [accounts]);

  return (
    <div>
      {accounts?.root && commodities &&
        <TreeView
          defaultCollapseIcon={<AccountTreeIcon />}
          defaultExpandIcon={<AccountBalanceIcon />}
          defaultEndIcon={<AccountBalanceWalletIcon />}
          onNodeSelect={onNodeSelect}
          onNodeToggle={handleToggle}
          expanded={expanded}
          className={classes.root}
          defaultExpanded={['root']}
        >
          {renderTree(accounts?.root, commodities, reportMap, balances)}
        </TreeView>
      }
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 600,
  },
});

const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      margin: '2px',
      marginRight: '6px'
    },
    content: {
      borderBottom: '1px solid lightgray',
    },
    group: {
      marginLeft: 10,
      paddingLeft: 6,
      [theme.breakpoints.up('sm')]: {
      },
    },
  }),
)((props: TreeItemProps) => <TreeItem {...props} />);
