import { Config } from 'Config';
import { useEffect } from 'react';
import { AccountAll, AccountNode } from 'service/Accounts.store';
import { Commodities } from 'service/Data.store';
import useSWR, { mutate } from 'swr';
import { preprocessTransactions } from './Account.store';
import { indexAccounts } from './Accounts.store';
import { TxForex } from './reports/TxForex';
import { TxPagination } from './TxPagination';
import { TxReport } from './types/Types';
import { AccountsColorizer } from './ux/AccountsColorizer';

export type WithSuccess = {
    success?: boolean
}

export const Api = <T extends WithSuccess, R, Ret>(promise: Promise<T>, handlers: {
    before?: () => any,
    then?: (t: T) => T | R,
    error?: (e?: T | Error) => Ret,
    finally?: () => void
}) => {
    handlers?.before?.();
    if (handlers.then) promise.then(v => (v.success ? handlers.then : handlers.error)?.(v));
    if (handlers.error) promise.catch(handlers.error);
    if (handlers.finally) promise.finally(handlers.finally);
}

export const fetcher_base = (input: string, opts?: RequestInit): Promise<Response | undefined | null> => {
    if (input.indexOf('/null') >= 0) {
        return Promise.resolve(null);
    }
    if (input.indexOf('/undefined') >= 0) {
        return Promise.resolve(undefined);
    }
    return fetch(`${Config.api.url}${input}`, {
        ...opts,
        ...(opts?.body ? { body: JSON.stringify(opts?.body) } : {}),
        credentials: "include",
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const fetcher = (input: string, opts?: RequestInit) => fetcher_base(input, opts)
    .then(async res => ({ success: res?.ok ?? false, ...(await res?.json()) }))

export const fetcherWithHashCode = (input: string, opts?: RequestInit) => fetcher_base(input, opts).then(async res => {
    //console.log('Got for', input, res);
    if (!res) {
        return { success: true };
    }
    let txt = await res?.text();
    let hash = hashCode(txt);
    let json = JSON.parse(txt);
    json.hashCode = hash;
    json.origin = input;
    return { success: true, ...json };
})

export const cfg = {
    fetcher: fetcherWithHashCode,
    compare: (v1: any, v2: any) => {
        let ret = (v1?.hashCode === v2?.hashCode);
        return ret;
    },
    //revalidateOnFocus: false
}

function hashCode(value: string) {
    var hash = 0, i, chr;
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

function query(params: any) {
    const qs = Object.keys(params ?? {})
        .map(key => `${key}=${(params as any)[key]}`)
        .join('&');
    const q = qs ? `?${qs}` : ''
    return q;
}

class ServiceClass {
    useReport(ledgerId: string | undefined, reportId: string | undefined) {
        return useSWR(`/ledgers/${ledgerId}/reports/${reportId}`, cfg);
    }

    useReportTx(ledgerId: string | undefined, reportId: string | undefined, reportVersion: string) {
        return useSWR(`/ledgers/${ledgerId}/reports/${reportId}/transactions?v=${reportVersion}`, {
            ...cfg,
            fetcher: (input: string) => cfg.fetcher(input).then(async (res) => {
                let mergedData = await TxForex.mergeByConversion(res.data, null);

                return {
                    ...res,
                    data: mergedData
                }
            })
        })
    }

    useReportLive(ledgerId: string | undefined, params?: { root: string }) {
        return useSWR<{ data: TxReport }>(`/ledgers/${ledgerId}/reports/live`.concat(query(params)), cfg);
    }

    useReports(ledgerId: string | undefined) {
        return useSWR(`/ledgers/${ledgerId}/reports`, cfg);
    }
    useAccountById(accounts: import("./Accounts.store").AccountAll | undefined, accountId: string | undefined): { data: any; } {
        if (!accounts || !accountId) {
            return { data: null };
        }
        return { data: accounts?.indexById?.[accountId] }
    }
    useTransaction(ledgerId: string | undefined, transactionId: string | undefined) {
        return useSWR(`/ledgers/${ledgerId}/transactions/${transactionId}`, cfg);
    }

    mutateTransaction(ledgerId: string | undefined, transactionId: string | undefined, object: any | undefined) {
        ledgerId && transactionId && mutate(`/ledgers/${ledgerId}/transactions/${transactionId}`, object);
    }

    generic = {
        get(uri: string) {
            return fetcher(uri);
        },
        createOrUpdate(uri: string, data: any & { id: string | undefined }) {
            if (data.id) {
                return fetcher(`${uri}/${data.id}`, { method: 'PUT', body: data as any }).then(res => {
                    let specData = { ...data, id: res.data.id };
                    mutate(`${uri}/${data.id}`, { data: { content: specData } })
                    return res;
                })
            } else {
                return fetcher(uri, { method: 'POST', body: data as any }).then(res => {
                    let specData = { ...data, id: res.data.id }
                    mutate(`${uri}/${data.id}`, { data: { content: specData } });
                    return res;
                })
            }
        },
        delete(uri: string, id: string) {
            return fetcher(`${uri}/${id}`, { method: 'DELETE' })
        }
    };

    useTransactions(ledgerId: string | undefined, accountId: string | undefined, span: string | undefined) {
        //console.log('useTransactions span=', span)
        let opts: any = {}
        if (span && span !== 'all') {
            let txp = TxPagination.of(span);
            opts.begin = txp.begin()?.format('YYYY-MM-DD');
            opts.end = txp.end()?.format('YYYY-MM-DD');
        }

        let [query, args] = ['', '']
        if (opts.begin && opts.end) {
            query = '?'
            args += `begin=${opts.begin}&end=${opts.end}`
        }

        return useSWR(`/ledgers/${ledgerId}/accounts/${accountId}/transactions${query}${args}`, {
            ...cfg,
            fetcher: (input: string) => cfg.fetcher(input).then(preprocessTransactions)
        });
    }

    useAccount(ledgerId: string | undefined, accountId: string | undefined) {
        return useSWR<{ data?: AccountNode }>(`/ledgers/${ledgerId}/accounts/${accountId}`, cfg);
    }

    useLedgers() {
        return useSWR(`/ledgers/`, cfg);
    }
    useLedger(ledgerId: string | undefined) {
        return useSWR(`/ledgers/${ledgerId}`, cfg);
    }
    useAccounts(ledgerId: string | undefined) {
        return useSWR(`/ledgers/${ledgerId}/accounts`, {
            ...cfg,
            fetcher: (input: string) => cfg.fetcher(input).then(indexAccounts).then(AccountsColorizer.colorize)
        });
    }
    useBalancesTransitive(ledgerId: string | undefined) {
        return useSWR(`/ledgers/${ledgerId}/accounts/balances?mode=transitive`, cfg)
    }
    useCommodities() {
        return useSWR<Commodities>(`/data/commodities`, cfg);
    }
    useAuth() {
        return useSWR(`/user/auth`, { ...cfg, refreshInterval: 300000 })
    }
    useUsers(ledgerId: string | undefined) {
        return useSWR(`/ledgers/${ledgerId}/users`, cfg);
    }

    useSessions() {
        return useSWR('/pay/stripe/sessions', cfg);
    }

    useLedgerContext(props: any) {
        let ret = { accounts: props.accounts as AccountAll, commodities: props.commodities as Commodities, ledger: props.ledger };
        return ret;
    }
}

const Service = new ServiceClass();

export function useEffectRequired(fn: () => any, args: any[]) {
    useEffect(() => {
        if (args.findIndex((item) => (item === undefined) || (item === null)) >= 0) {
            return;
        }
        fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, args)
}

export { Service };

