import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ActionButton from 'component/ActionButton.co';
import React, { useState } from 'react';
import { ledgerStore } from 'service/Ledger.store';
import { Service } from 'service/Service.store';
import "./LedgerUsers.style.css";

const authSources = ['google'];

export default function LedgerUsers(props: any) {
  let { ledgerId } = props;

  const [form, setForm] = useState({ authSource: authSources[0], email: '', checked: false });

  const [formDisabled, setFormDisabled] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);

  const { data: users } = Service.useUsers(ledgerId);

  const handleChange = (fieldName: string) => {
    return (event: any) => {
      setForm({ ...form, [fieldName]: event?.target.value });
    }
  };

  const Button_onSubmit = () => {
    //console.log('On submit', form)
    if (!ledgerId) {
      setNotification('Cannot submit now');
      return;
    }
    setFormDisabled(true);
    setNotification('In progress');

    ledgerStore.addUser(ledgerId, { email: form.email });
  };

  const Button_onClickDelete = (user: any) => {
    if (!ledgerId) {
      setNotification('Cannot submit now');
      return;
    }
    setNotification('In progress');
    ledgerStore.deleteUser(ledgerId, user.id).then(ret => setNotification(ret.data ? 'Done' : 'Failed'));
  };

  return (
    <div>
      <div>
        <form className="myform" noValidate autoComplete="off">
          <fieldset disabled={formDisabled}>
            <div>
              <FormControl className="Form-auth-dropdown">
                <InputLabel>Auth source</InputLabel>
                <Select value={form.authSource} onChange={handleChange('authSource')}>
                  {authSources.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                </Select>
              </FormControl>

            </div>
            <div>
              <TextField className="Form-full" label="Email" value={form.email} onChange={handleChange('email')} />
            </div>
            <div>
              <ActionButton disabled={formDisabled} onClick={Button_onSubmit}>Invite</ActionButton>
              <span className="notification">{notification}</span>
            </div>
          </fieldset>
        </form>

      </div>
      <div>
        {users?.data && <List dense>
          {users.data.map((entry: any) =>
            <ListItem className="ListItem-user" key={entry.moniker}>
              <div>
                <div>{entry.moniker}</div>
                <div>{entry.role}</div>
              </div>
              <div>
                <IconButton aria-label="delete" size="small" onClick={() => Button_onClickDelete(entry)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </div>
            </ListItem>
          )}
        </List>
        }
      </div>
    </div>
  );
}
